class LoadingItem {
    requestCounter = 0
    maxRequest = 0;
    addRequest(amount = 1) {
        this.requestCounter+=amount
        if(this.maxRequest <= this.requestCounter) {
            this.maxRequest = this.requestCounter;
        }
    } 

    finishRequest(amount = 1) {
        this.requestCounter-=amount
        if( this.requestCounter <= 0) {
            this.maxRequest = 0;
        }
    } 
}

export class OverlayLoading extends LoadingItem {

}

export class BackgroundLoading extends LoadingItem{

}