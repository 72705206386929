import { Customer } from "../Customer/Customer"

export class CustomerCategory {
    id = 0
    name = ""
    description = ""
    created = ""
    date = ""
    customers = [] as Customer[]
    images = [] as number[]
}