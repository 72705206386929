<template>
  <div>
    <h1>Passwort zurücksetzen</h1>
    <div class="form m-auto">
      <label class="form-label">Passwort</label>
      <input type="password" class="form-control" v-model="passwordData.password" />
      <label class="form-label">Passwort wiederholen</label>
      <input type="password" class="form-control" v-model="passwordData.repeat" />
      <div v-if="passwordData.error" class="text-danger">
        Die Passwörter stimmen nicht überein
      </div>
      <button v-if="!passwordData.active" class="btn btn-success mt-1" @click="resetPassword">Zurücksetzen</button>
    </div>
  </div>
</template>

<script setup lang="ts">

import {reactive} from "vue";
import {useRequestStore} from "@/components/System/Request";
import {useAlertStore} from "@/components/System/Alert/state";
import {useRoute, useRouter} from "vue-router";

const requestStore = useRequestStore()
const alertStore = useAlertStore()
const route = useRoute();

const router = useRouter();

const passwordData = reactive({
  password: "",
  repeat: "",
  error: false,
  active:false
})


function resetPassword() {
  passwordData.error = false
  if(passwordData.password !== passwordData.repeat) {
    alertStore.addAlert('Die Passwörter stimmen nicht überein', "warning")
    passwordData.error = true
    return
  }

  passwordData.active = true;
  const request =  requestStore.post('reset-password/app-reset-token', { plainPassword: passwordData.password, token: route.params.token })
  request.then((data) => {
    if(data === false) {
      alertStore.addAlert("Es gab einen Fehler. Möglicherweise ist dein Token abgelaufen", "warning", 8000)
    } else {
      router.push({name: 'login'})
    }
    passwordData.active = false
  })
}

</script>

<style scoped lang="scss">
.form {
  max-width: 400px;
}
</style>