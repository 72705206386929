<template>
  <span v-if="value" :class="getValueStyle">
    {{ getGermanNumber }}
  </span>
</template>

<script setup lang="ts">
import {computed, defineProps} from "vue"

const props = defineProps({
  value: Number
})

const getGermanNumber = computed(() => {
  if(!props.value) return 0
  if(typeof props.value == "string") {
    return parseFloat(props.value).toLocaleString("de-DE", { style: "currency", currency: "EUR" })
  }
  return props.value.toLocaleString("de-DE", { style: "currency", currency: "EUR" })
})

const getValueStyle = computed(() => {
  if(!props.value) return ""
  if(props.value < 0) {
    return "text-danger"
  }
  return  ""
})
</script>

<style scoped lang="scss">

</style>