import { defineStore } from 'pinia'
import { Farm } from './Farm';
import { useRequestStore } from '../System/Request';
import { useEmployeeStore } from '../Employee/state';
import { useLoadingStore } from '../System/Loading/state';


export const useFarmStore = defineStore('farmStore', {
    state: () => (
        { 
            activeFarm: {} as Farm,
            latestFarm: {} as Farm,
        }
    ),
    actions: {
        getFarm() {
            const requestStore =  useRequestStore();
            const request = requestStore.get('api/v2/getActiveFarm')
            request.then((data) => {
                this.activeFarm = data as Farm;
            })
            return request;
        },
        saveFarm(farm: Farm) {
            const requestStore =  useRequestStore();
            const request = requestStore.post('api/v2/updateFarm/'+farm.id, farm )
            request.then((data) => {
                this.activeFarm = data as Farm;
            })
            return request;
        },
        setActiveFarm(farm: Farm) {
            const requestStore =  useRequestStore();
            const request = requestStore.post('api/v2/setActiveFarm/'+farm.id, farm )
            request.then((data) => {
                this.latestFarm = data as Farm
                this.activeFarm = data as Farm;
                window.location.reload()
            })
            return request;
        },
        loadContentOfFarm() {
            const loadingStore = useLoadingStore();
            loadingStore.addLoading("overlay", 1)
            const promise = new Promise((resolve) => {
                const employeeState = useEmployeeStore();
                employeeState.getEmployees().then(() => {
                    loadingStore.removeLoading("overlay")
                })
                resolve(true)
            })
            return promise
        },
        setLatestFarm(farm: Farm) {
                return this.latestFarm = farm
        }
    }
})