import { defineStore } from 'pinia'
import {PaymentMethod, SearchRequest, Transaction, TransactionSearchSummary} from "./Transaction"
import { Customer, Balance } from '../Customer/Customer';
import { useRequestStore } from '../System/Request';

export const useTransactionStore = defineStore('transactionStore', {
    state: () => (
        {
            foundTransactions: [] as Transaction[],
            transactionSearchSummary: [] as TransactionSearchSummary[],
            focusCustomerTransaction: [] as Transaction[],
            paymentMethods: [] as PaymentMethod[],
            searchRequest: new SearchRequest()
        }
    ),
    actions: {
        getPaymentMethods() {
            const request = useRequestStore();
            const promise = new Promise((resolve) => {
                request.get("api/v2/transaction/getPaymentMethods").then((data) => {
                    this.paymentMethods = data as PaymentMethod[]
                    resolve(data)
                })
            })
            return promise;
        },
        getTransactions() {
            const request = useRequestStore();
            if(this.searchRequest.page == 1) {
                this.foundTransactions = [] as Transaction[]
                this.searchRequest.allItemFound = false
            }
            const promise = new Promise((resolve) => {
                request.post("api/v2/transaction/find", this.searchRequest).then((data) => {
                    const transactions = data as Transaction[]
                    if(transactions.length === 0) this.searchRequest.allItemFound = true;
                    for(const transaction of transactions) {
                        this.foundTransactions.push(transaction);
                    }
                    resolve(data)
                })
            })
            return promise;
        },
        getSummary() {
            const request = useRequestStore();
            const promise = new Promise((resolve) => {
                request.post("api/v2/transaction/getSummary", this.searchRequest).then((data) => {
                    this.transactionSearchSummary = data as TransactionSearchSummary[]
                    resolve(data)
                })
            })
            return promise
        },
        getTransactionFromCustomer(customer: Customer) {
            const request = useRequestStore();
            const promise = new Promise((resolve) => {
                request.get("api/v2/transaction/getByCustomer/"+ customer.id).then((data) => {
                    resolve(data)
                })
            })
            return promise;
        },
        addTransaction(transaction: Transaction) {
            const request = useRequestStore();
            const promise = new Promise((resolve) => {
                request.post("api/v2/transaction/addTransaction", transaction).then((data) => {
                    resolve(data)
                })
            })
            return promise;
        },
        getCustomerBalance(customer: Customer) {
            const request = useRequestStore();
            const promise = new Promise((resolve) => {
                request.get("api/v2/transaction/getCustomerBalance/"+ customer.id).then((data) => {
                    const receivedBalance = data as Balance
                    if(receivedBalance.total == null || data == false) {
                        receivedBalance.total = 0;
                    }
                    customer.balance = receivedBalance;
                    resolve(receivedBalance)
                })
            })
            return promise as Promise<Balance>;
        }
    }
})