<template>
  <div class="mt-5 card w-100" v-if="!data.isLoading">
    <div class="card-header">
      <h5>Aktuelle Bestellungen</h5>
    </div>
    <p class="text-center mt-1">Alle offenen Bestellungen die vor oder an dem <span class="pointer" @click="changeDeliveryDate"><b>{{ data.deliveryDate.format('LL') }}</b> <i class="fa-regular fa-edit"></i></span>ausgeliefert werden.</p>
    <table class="table" v-if="orderStore.summaryPostionsOrders.length > 0">
      <tbody>
    <template v-for="position of orderStore.summaryPostionsOrders" :key="position.id">
        <tr>
          <th>
            <template v-if="position.product?.images && position.product.images.length > 0">
              <img :src="fileSystemStore.getImagePath(position.product.images[0])" :alt="position.product.name" />
            </template>
          </th>
          <td> {{ position.product.name }} </td>
          <td> {{ getLocalNumber(position.totalAmount) }} stk</td>
<!--          <td class="d-none"> {{ getLocalNumber(position.totalPrice) }} €</td>-->
        </tr>
      </template>
      <tr>
        <th >Zusammenfassung</th>
        <td></td>
        <th>{{ getLocalNumber(getTotal.amount) }} stk</th>
<!--        <th>{{ getLocalNumber(getTotal.price) }} €</th>-->
      </tr>
      </tbody>
  </table>
    <div class="text-center" v-if="orderStore.summaryPostionsOrders.length == 0">
      <em>Du hast keine offenen Bestellungen, bzw. alle Bestellungen wurden erfolgreich geliefert.</em>
    </div>
  </div>
</template>

<script lang="ts" setup>

import {useOrderStore} from "@/components/Order/state";
import {computed, onMounted, reactive} from "vue";
import {useFileSystemStore} from "@/components/System/FileSystem/state";
import moment from "moment";
import {useFormModalStore} from "@/components/System/FormModal/state";

const orderStore = useOrderStore();
const fileSystemStore = useFileSystemStore();
const formModalStore = useFormModalStore();

const data = reactive({
  deliveryDate: moment().add(5, 'days'),
  isLoading: true
})

onMounted(() => {
  updateOrders()
})

function getLocalNumber(value: string|number) {
  if(typeof value == "string") {
    return parseFloat(value).toLocaleString();
  } else {
    return value.toLocaleString()
  }
}

const getTotal = computed(() => {
  const total = {
    amount: 0,
    price: 0,
    avgPrice: 0,
  }

  for (const pos of orderStore.summaryPostionsOrders) {
    total.amount += parseFloat(pos.totalAmount.toString())
    total.price += parseFloat(pos.totalPrice.toString())
  }

  return total
})

function updateOrders() {
  const promise = new Promise((resolve) => {
    orderStore.getCombinedOrders('delivery', data.deliveryDate.format('YYYY-MM-DD')).then(() => {
      data.isLoading = false;
      resolve(true)
    })
  })
  return promise
}

function changeDeliveryDate() {
  const form = formModalStore.addForm()

  form.header = "Lieferdatum anpassen"

  const deliveryDate = form.addInput('Lieferdatum', 'date', data.deliveryDate.format('YYYY-MM-DD'))
  const btn = form.addButton('Anpassen', 'primary')

  btn.then(() => {
      data.deliveryDate = moment(deliveryDate.value);
      updateOrders().then(() => {
        form.close()
      })
  })
}

</script>

<style lang="scss" scoped>
img {
  height: 80px;
}


td {
  vertical-align: middle;
}

</style>