<template>
    <div class="updateBar w-100 text-center p-2" v-if="updateExists">
      Ein neues Update ist Verfügbar!
      <button  class="btn btn-outline-primary" @click="updateApp">Jetzt updaten</button>
    </div>
</template>

<script setup>
import {ref} from "vue";


const registration = ref(null)
const updateExists = ref(false)
const refreshing = ref(false)

document.addEventListener('swUpdated', updateAvailable, { once: true })

// Prevent multiple refreshes
navigator.serviceWorker.addEventListener('controllerchange', () => {
  if (refreshing.value) return
  refreshing.value = true
  // Here the actual reload of the page occurs
  window.location.reload(true)
})



function updateAvailable(event) {
  registration.value = event.detail
  updateExists.value = true
}

function updateApp() {
  updateExists.value = false
  // Make sure we only send a 'skip waiting' message if the SW is waiting
  if (!registration.value || !registration.value.waiting) return
  // Send message to SW to skip the waiting and activate the new SW
  registration.value.waiting.postMessage({ type: 'SKIP_WAITING' })
}

// https://dev.to/drbragg/handling-service-worker-updates-in-your-vue-pwa-1pip
</script>

<style lang="scss" scoped>
  .updateBar {
    background-color: #f1f1f1;
  }
</style>