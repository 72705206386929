export class Product {
    id = 0
    name = ""
    description = ""
    shortDescription = ""
    price = 0.0
    created = ""
    updated = ""
    images = [] as Array<number>
}

export interface ProductSearchRequest {
    searchString: string
}