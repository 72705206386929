<template>
  <div v-if="runningRoute && currentRoutePoint" >
    <div class="p-2 border-bottom">
      <div class="d-lg-none">
        <h2 class="m-auto">{{currentRoutePoint.customer.name}} <span @click="openCustomerEditor" class="pointer"><i class="fa-regular fa-pen-to-square"></i></span></h2>
      </div>
      <div class="row" >
        <div class="col p-1 d-flex">
          <button class="btn  w-100 btn-outline-primary float-start" v-if="beforeRoutePoint" @click="changeCustomer(-1)"><i class="fa-regular fa-square-caret-left"></i> {{ beforeRoutePoint.customer.name }}</button>
        </div>
        <div v-if="currentRoutePoint" class="d-none d-lg-block col-6">
          <h2 class="m-auto">{{currentRoutePoint.customer.name}} <span @click="openCustomerEditor" class="pointer"><i class="fa-regular fa-pen-to-square"></i></span></h2>
        </div>
        <div class="col d-lg-none d-flex m-auto justify-content-center">
          <button class="btn btn-outline-success"  @click="openRoutePointChangeList"><i class="fa-regular fa-list"></i></button>
        </div>
        <div class="col  p-1 d-flex">
          <button class="d-none d-lg-block btn btn-outline-success me-2" @click="openRoutePointChangeList"><i class="fa-regular fa-list"></i></button>
          <button class="btn btn-outline-primary w-100 float-end" v-if="nextRoutePoint" @click="changeCustomer(+1)">{{ nextRoutePoint.customer.name }} <i class="fa-regular fa-square-caret-right"></i></button>
        </div>
      </div>
    </div>
    <div class="alert alert-warning mt-3 text-center" v-if="currentRoutePoint.deliveryOrders.length == 0">
      Der Kunde hat keine aktuellen Bestellungen, oder du hast bereits alle offenen Bestellung geliefert.
    </div>
    <div class="deliveryNav mt-2 mb-2">
      <button class="btn me-1 mb-1" :class="getBtnColorStyle('location')" @click="changeElement('location')"><i class="fa-regular fa-location-dot"></i> Anfahrt</button>
      <button class="btn me-1 mb-1" :class="getBtnColorStyle('order')" @click="changeElement('order')"><i class="fa-regular fa-box"></i> Bestellung</button>
      <button class="btn me-1 mb-1" :class="getBtnColorStyle('transaction')" @click="changeElement('transaction')"><i class="fa-regular fa-cash-register"></i> Kasse <FinanceSpan :value="currentRoutePoint.customerBalance.total" /></button>
      <button class="btn me-1 mb-1" :class="getBtnColorStyle('carStore')" @click="changeElement('carStore')">
        <span v-if="!isElementOpen('carStore')">
          <i class="fa-regular fa-truck" v-if="!isElementOpen('carStore')"></i>
          </span>
        <span v-if="isElementOpen('carStore')">
          <i class="fa-regular fa-truck-ramp" ></i>
        </span>
        <span>
          Kofferraum
        </span>
      </button>
    </div>

    <div class="contentContainer">
      <DeliveryLocation v-if="isElementOpen('location')" :customer="currentRoutePoint.customer" />
      <template v-if="isElementOpen('order')">
      <div>
        <h5>Bestellung</h5>
      </div>
      <DeliveryOrder :openOrders="currentRoutePoint.openOrders"
                     :closedOrders="currentRoutePoint.closedOrders"
                     :deliveryOrders="currentRoutePoint.deliveryOrders"
                     :customer="currentRoutePoint.customer"
                     @orderIsUpdated="updateRoutePoint()"
      />
      </template>
      <DeliveryTransaction v-if="isElementOpen('transaction')"
                           :customer="currentRoutePoint.customer"
                           :transaction-items="currentRoutePoint.customerTransactions"
                           :balance="currentRoutePoint.customerBalance.total"
                           @transactionAdded="updateRoutePoint()"
      />
      <DeliveryCarStore v-if="isElementOpen('carStore')" />
    </div>
  </div>
  <div v-if="deliveryRouteStore.activeDeliveryRoute">
    <RoutePointChangeList v-if="data.isRoutePointChangeListVisable"
                          @close="closeRoutePointChangeList"
                          @selectRoutePoint="changeRoutePoint"
                          :route-points="deliveryRouteStore.activeDeliveryRoute.runningRoute"
                          :info-text="'Wähle einen Kunden den Produkte du ausliefern möchtest'"
    />
  </div>
</template>

<script setup lang="ts">
import { useDeliveryRouteStore } from "./state";
import {computed, onMounted, reactive} from "vue"
import {RoutePoint} from "@/components/Delivery/DeliveryRoute";
import {useCustomerStore} from "@/components/Customer/state";
import DeliveryLocation from "@/components/Delivery/DeliveryElement/DeliveryLocation.vue";
import DeliveryOrder from "@/components/Delivery/DeliveryElement/DeliveryOrders.vue";
import DeliveryTransaction from "@/components/Delivery/DeliveryElement/DeliveryTransaction.vue";
import DeliveryCarStore from "@/components/Delivery/DeliveryElement/DeliveryCarStore.vue";
import FinanceSpan from "@/components/Finance/FinanceSpan.vue";
import RoutePointChangeList from "@/components/Delivery/DeliveryElement/RoutePointChangeList.vue";

type PossibleElements = "location"|"order"|"transaction"|"carStore"

const data = reactive({
    currentTargetIndex: 0,
    openElement: "location" as PossibleElements,
    isRoutePointChangeListVisable: false
})


onMounted(() => {
  updateRoutePoint()
})

const deliveryRouteStore = useDeliveryRouteStore();
const customerStore = useCustomerStore()

const runningRoute = computed(() => {
    if(!deliveryRouteStore.activeDeliveryRoute) return null
    return deliveryRouteStore.activeDeliveryRoute.runningRoute
})

const currentRoutePoint = computed(() => {
  if(!deliveryRouteStore.activeDeliveryRoute) return null;
  return deliveryRouteStore.activeDeliveryRoute.runningRoute[data.currentTargetIndex] as RoutePoint
})

const beforeRoutePoint = computed(() => {
  if(!deliveryRouteStore.activeDeliveryRoute) return null;
  if(data.currentTargetIndex <= 0) return null
  return deliveryRouteStore.activeDeliveryRoute.runningRoute[data.currentTargetIndex - 1]
})

const nextRoutePoint = computed(() => {
  if(!deliveryRouteStore.activeDeliveryRoute) return null;
  if(data.currentTargetIndex +1 >= deliveryRouteStore.activeDeliveryRoute.route.length) return null
  return deliveryRouteStore.activeDeliveryRoute.runningRoute[data.currentTargetIndex + 1]
})

function openCustomerEditor() {
  if(!currentRoutePoint.value) return
  customerStore.openCustomerEditor(currentRoutePoint.value.customer).then((customer) => {
    if(!deliveryRouteStore.activeDeliveryRoute || !currentRoutePoint.value) return
    currentRoutePoint.value.customer = customer
    updateRoutePoint()
    deliveryRouteStore.saveInStorage()
  })
}

function changeCustomer(direction: number) {
  data.currentTargetIndex += direction
  updateRoutePoint()
}

function updateRoutePoint() {
  if(!currentRoutePoint.value) return
  deliveryRouteStore.updateRoutePoint(currentRoutePoint.value.customer)
}

function getBtnColorStyle(value: PossibleElements) {
  if(isElementOpen(value)) {
    return "btn-outline-primary"
  }
  return "btn-outline-secondary"
}

function isElementOpen(element: PossibleElements) {
  if(element == data.openElement) {
    return true
  }
  return  false
}

function changeElement(value: PossibleElements) {
  data.openElement = value
}

function openRoutePointChangeList() {
  data.isRoutePointChangeListVisable = true;
}

function closeRoutePointChangeList() {
  data.isRoutePointChangeListVisable = false
}

function changeRoutePoint(routePoint: RoutePoint) {
  if(!deliveryRouteStore.activeDeliveryRoute) return
  const foundIndex = deliveryRouteStore.activeDeliveryRoute.runningRoute.findIndex(obj => obj.customer.id === routePoint.customer.id)
  changeCustomer(foundIndex)
  closeRoutePointChangeList()
}
</script>

<style lang="scss" scoped>
.productInfo {
    height: 50px;
}

.productInfo img{
    width: 50px;  /*width of parent container*/
  height: 50px; /*height of parent container*/
  object-fit: contain;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

hr {
  margin: 0px;
}
</style>