<template>
  <div class="row mt-2" v-if="customer">
    <div class="card col-4 p-3" :class="getMenuCardStyle('order')" @click="openModule('order')">
      <span v-if="openedModule=='order'">
        <i class="fa-regular fa-box-open fa-3x"></i>
      </span>
      <span v-if="openedModule!='order'">
        <i class="fa-regular fa-box fa-3x"></i>
      </span>
      <div>
        <h5>Bestellungen</h5>
      </div>
      <div v-if="customer.orders">

      </div>
    </div>
    <div class="card col-4 p-3 " :class="getMenuCardStyle('transaction')" @click="openModule('transaction')">
      <i class="fa-regular fa-cash-register fa-3x"></i>
      <div>
        <h5>Buchungen</h5>
      </div>
      <div v-if="balance">
        <FinanceSpan :value="balance.total"></FinanceSpan>
      </div>
    </div>
    <div class="card col-4 p-3" :class="getMenuCardStyle('notes')" @click="openModule('notes')">
      <div class="d-flex justify-content-center">
        <i class="fa-regular fa-comment fa-3x me-1"></i>
        <i class="fa-regular fa-location-dot fa-3x ms-1"></i>
      </div>
      <div>
        <h5>Notizen & Lieferhinweise</h5>
      </div>
    </div>
  </div>
  <div v-if="customer">
    <div class="mt-2" v-if="openedModule == 'order'">
      <h3>
        Bestellungen
      </h3>
      <DeliveryOrders  :customer="customer"
                       :closed-orders="customer.closedOrders"
                       :delivery-orders="customer.deliveryOrders"
                       :open-orders="customer.openOrders"
                       :serial-orders="customer.serialOrders"
                       @orderIsUpdated="updateCustomer"
      />
    </div>
<!--  <OrderList  :orderList="customerStore.focusCustomer.orders" :customer="customerStore.focusCustomer" :max-entries="7" />-->
  <TransactionList  v-if="openedModule == 'transaction'" :transactionItems="transactionList" @transaction-added="updateTransactionList" :customer="customerStore.focusCustomer" />
  <div v-if="openedModule == 'notes'">
    <div class="mt-2">
      <h3>Kundennotizen</h3>
      <h5>Allgemein <span class="pointer" @click="customerStore.openCustomerDescription(customer)"><i class="fa-regular fa-pen-to-square"></i></span></h5>
      <p class="notes border p-5 rounded" @click="customerStore.openCustomerDescription(customer)">{{customer.salesDescription}}</p>
      <DeliveryLocation :customer="customer"  />
    </div>
  </div>
  </div>
</template>

<script setup lang="ts">
import TransactionList from "@/components/Transaction/TransactionList.vue";
import OrderList from "@/components/Order/OrderList.vue";
import {useCustomerStore} from "@/components/Customer/state";
import {onMounted, PropType, ref, defineProps, popScopeId} from "vue";
import {Transaction} from "@/components/Transaction/Transaction";
import {useRoute} from "vue-router";
import {useTransactionStore} from "@/components/Transaction/state";
import {Balance, Customer} from "@/components/Customer/Customer";
import FinanceSpan from "@/components/Finance/FinanceSpan.vue";
import {re} from "mathjs";
import DeliveryLocation from "@/components/Delivery/DeliveryElement/DeliveryLocation.vue";
import DeliveryOrders from "@/components/Delivery/DeliveryElement/DeliveryOrders.vue";

const customerStore = useCustomerStore()
const route = useRoute()
const transactionStore = useTransactionStore()


const transactionList = ref(null as Transaction[]|null)
const balance = ref<null|Balance>(null)
const openedModule = ref<"transaction"|"order"|"notes"|null>("order")

const props = defineProps({
  customer: Object as PropType<Customer>
})

onMounted(() => {
  updateTransactionList()
})

function updateTransactionList() {
  if(!props.customer?.id) return
  transactionList.value = null;
  transactionStore.getCustomerBalance(props.customer).then((updatedBalance) => {
    balance.value = updatedBalance as Balance
  })
  transactionStore.getTransactionFromCustomer(props.customer).then((transactionItems) => {
    transactionList.value = transactionItems as Transaction[];
  })
}

function openModule(value: "transaction"|"order"|"notes") {
  if(value == openedModule.value) {
    openedModule.value = null
  } else {
    openedModule.value = value
  }
}

function getMenuCardStyle(value: "transaction"|"order"|"notes") {
  if (value === openedModule.value) {
    return "active"
  }
  return ""
}

function updateCustomer() {
  if(!props.customer) return
  customerStore.getCustomer(props.customer.id);
}

</script>

<style scoped lang="scss">
@import "@/assets/style/breakpoints";
.active {
  background-color: #dcffdc;
}

.notes {
  font-size: 1.2rem;
}

.card {
  max-width: 20rem;
  height: 7rem;
  @media only screen and (max-width: $bp-lg) {
    font-size: 0.8rem;
    max-width: 11rem;
    height: 6rem;
  }

  @media only screen and (max-width: $bp-sm) {
    font-size: 0.6rem;
    max-width: 8rem;
    height: 5rem;
  }
}


@media only screen and (max-width: $bp-lg) {
  h5 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: $bp-sm) {
  h5 {
    font-size: 0.7rem;
  }
}


</style>