import { CustomerCategory } from "../CustomerCategory/CustomerCategory"
import { Order } from "../Order/Order"

export class Customer {
    id = 0
    name = ""
    firstName = ""
    lastName = ""
    companyName = ""
    street = ""
    streetAdd = ""
    houseNo = ""
    state = "Nordrhein-Westfalen"
    postcode = ""
    town = ""
    email = ""
    country = "Germany"
    phoneNumber = ""
    mobileNumber= ""
    addressString = ""
    addressHTML = ""
    salesDescription = ""
    deliveryDescription = ""
    deliveryImages = [] as number[]
    /**
     * @param balanceTotal wird über eine Extraanfrage ermittelt api/v2/transaction/getCustomerBalance/:id
     */
    balance = null as Balance|null
    orders = [] as Order[]
    openOrders= [] as Order[]
    closedOrders= [] as Order[]
    deliveryOrders= [] as Order[]
    serialOrders= [] as Order[]
    categories = [] as CustomerCategory[]
    isSelected = false
    isLoading = false // Kommt nicht aus dem Backend, sondern steuert im Frontend das Element
    isDetailLoaded = false // Kommt nicht aus dem Backend, sondern steuert im Frontend das Element
}

export class Balance {
    total = 0;
}

/// Wird nur noch zum erstellen der Routenreihenfolge benötigt. Sollte im nächsten Konzept verschwinden!
export class CustomerRoutePoint extends Customer {
    deliveryOrders: Order[]
    isDelivered: boolean

     constructor() {
        super();
        this.deliveryOrders = [] as Order[]
        this.isDelivered = false
    }
}