<template>
  <h1>Lieferrouten bearbeiten</h1>

  <div v-if="employeeState.isAllowed('Admin')">
    <button class="btn btn-success w-100" @click="addRoute"><i class="fa-regular fa-location-plus"></i> Route hinzufügen</button>
  </div>
  <div>
        <div class="row">
            <template v-for="route of deliveryRoute.foundRouteItems" :key="route.id">
                <div class="col mt-2">
                    <DeliveryRouteCard :route="route" @is-clicked="openRoute" :enable-play-btn="true" />
                </div>
            </template>
            <div class="text-center mt-5" v-if="deliveryRoute.foundRouteItems.length == 0 && loadingStore.backgroundLoading.requestCounter == 0">
               <p><em>Leider keine Routen gefunden</em></p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

import {  useRouter } from 'vue-router';
import { useLoadingStore } from '../System/Loading/state';
import { DeliveryRoute } from './DeliveryRoute';
import DeliveryRouteCard from './DeliveryRouteCard.vue';
import { useDeliveryRouteStore } from './state';
import {onMounted} from "vue";
import {useEmployeeStore} from "@/components/Employee/state";

const router = useRouter()

const deliveryRoute = useDeliveryRouteStore();
const loadingStore = useLoadingStore();
const employeeState = useEmployeeStore()

function addRoute() {
    const route = new DeliveryRoute();
    deliveryRoute.openRouteFormEditor(route)
}

function openRoute(route: DeliveryRoute) {
  router.push({name: "deliveryRoute", params: { deliveryRouteId: route.id}})
}

onMounted(() => {
  loadingStore.addLoading('overlay')
  deliveryRoute.getRoutes("").then(() => {
    loadingStore.removeLoading('overlay')
  })
})

</script>

<style>

</style>