<template>
    <div><h1>Eure Kunden</h1></div>
    <div>
        <button @click="addCustomer" class="w-100 btn btn-success"><i class="fa-regular fa-user-plus"></i> Kunden hinzufügen</button>
        <CustomerSearch />  
    </div>
    <div v-if="customerStore.listMode == 'grid'">
      <div class="row">
            <template v-for="customer of customerStore.customerItems" :key="customer.id">
                <div class="col mt-2">
                    <CustomerCard :customer="customer" @is-clicked="openCustomer" />
                </div>
            </template>
        </div>
    </div>
  <div v-if="customerStore.listMode == 'list'">
    <template v-for="(customer, index) of customerStore.customerItems" :key="customer.id">
      <div class="mb-2">
        <CustomerListItem :customer="customer" :index="index" @is-clicked="openCustomer" @is-selected="changeSelected" />
      </div>
    </template>
  </div>
  <div class="text-center mt-5" v-if="customerStore.customerItems.length == 0 && loadingStore.backgroundLoading.requestCounter == 0">
    <p><em>Leider keine Kunden gefunden, vielleicht solltest du deine Vertriebsaktivitäten stärken ;)</em></p>
  </div>
  <div class="text-center m-2" v-if="customerStore.showFurtherCustomerBtn">
    <button ref="loadMoreCustomer" @click="customerStore.renderCustomer()" class="btn btn-primary">Weitere Kunden laden</button>
  </div>
</template>

<script lang="ts" setup>
import { Customer } from "@/components/Customer/Customer";
import CustomerCard from "@/components/Customer/CustomerCard.vue"
import CustomerSearch from "@/components/Customer/CustomerSearch.vue";
import {useCustomerStore} from "@/components/Customer/state"
import { useLoadingStore } from "@/components/System/Loading/state";
import {onMounted, ref, watch} from "vue";
import { useRouter } from "vue-router";
import CustomerListItem from "@/components/Customer/CustomerListItem.vue";
import {useElementVisibility} from "@vueuse/core";
import {useUserStore} from "@/components/System/User/state";


const customerStore = useCustomerStore();
const loadingStore = useLoadingStore();
const router = useRouter();

const loadMoreCustomer = ref(null)
const isLoadMoreCustomerVisable = useElementVisibility(loadMoreCustomer)

onMounted(() => {
  if(customerStore.backloadCustomers.length == 0) {
    customerStore.getCustomers()
  }

})

function addCustomer() {
    const customer = new Customer();

    customerStore.openCustomerEditor(customer)
}

function openCustomer(customer: Customer) {
    useUserStore().setFollowMe('customer/'+customer.id)
    router.push({
        name: "customer",
        params: {
            customerId: customer.id.toString()
        }
    })
}

function changeSelected(customer: Customer) {
  customer.isSelected = !customer.isSelected;
  customerStore.updateCustomer(customer)
}

watch(isLoadMoreCustomerVisable, (newValue) => {
  if(newValue) customerStore.renderCustomer()
})
</script>