<template>
    <div class="mt-2 mb-2">
        <div>
            <select class="form-select" @change="searchForCustomer" v-model="customerStore.searchItem.categoryId">
                <option :value="null">
                    Alle Kategorien
                </option>
                <option v-for="category of customerCategoryStore.customerCategoryItems" :key="category.id" :value="category.id">
                    {{ category.name }}
                </option>
            </select>
        </div>
      <div>
        <select class="form-select" @change="searchForCustomer" v-model="customerStore.searchItem.selected">
          <option :value="null">
            Markierte / Ausgewählte - Alle anzeigen
          </option>
          <option :value="false">
            Markierte / Ausgewählte - Ausblenden
          </option>
          <option :value="true">
            Markierte / Ausgewählte - Anzeigen
          </option>
        </select>
      </div>
        <div class="d-flex mt-1">
            <input class="form-control" @keydown.enter="searchForCustomer" v-model="customerStore.searchItem.searchString" placeholder="Suche nach einem Kunden ... " />
            <button @click="searchForCustomer" class="btn btn-outline-success ms-2">Suchen</button>
          <button class="btn btn-outline-success export-button ms-2" @click="exportCustomers">
            <span v-if="exporting" class="me-1"><i class="fa-regular fa-spinner fa-spin"></i></span>
            <span class="me-1 " v-if="!exporting"><i class="fa-regular fa-file-export"></i></span> Export
          </button>
        </div>
        <div class="ms-1 mt-2 text-muted d-flex justify-content-between">
          <div><span v-if="customerStore.customerItems.length > 0 && loadingStore.backgroundLoading.requestCounter == 0">{{customerStore.backloadCustomers.length}} Kunde(n) gefunden</span></div>
            <div>
              <template v-if="customerStore.listMode =='list'">
                <button @click="customerStore.selectAllCustomer(false)" class="btn me-1 btn-outline-dark"><i class="fa-regular fa-circle"></i></button>
                <button @click="customerStore.selectAllCustomer(true)" class="btn me-1 btn-outline-dark"><i class="fa-regular fa-circle-check"></i></button>
              </template>
              <button @click="customerStore.changeListMode('grid')" class="btn me-1" :class="getListModeStyle('grid')"><i class="fa-regular fa-grid"></i></button>
              <button @click="customerStore.changeListMode('list')"  class="btn" :class="getListModeStyle('list')"><i class="fa-regular fa-list"></i></button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useCustomerCategoryStore } from "../CustomerCategory/store";
import { useLoadingStore } from "../System/Loading/state"
import { useCustomerStore } from "./state";
import {onMounted, ref} from "vue";


const customerStore = useCustomerStore();
const loadingStore = useLoadingStore();
const customerCategoryStore = useCustomerCategoryStore();

const exporting = ref(false)

onMounted(() => {
  //if(customerStore.backloadCustomers.length == 0) {
  // ToDo: Hier muss noch eine bessere abfrage rein, sodass, die Kunden nicht jedes mal beim laden gestartet werden. Die auskommentierte Lösung klappt iwie am Handy nicht
    searchForCustomer()
  //}
})

function searchForCustomer() {
    loadingStore.addLoading("overlay")
    customerStore.getCustomers().then(() => {
        loadingStore.removeLoading("overlay")
    })
}

function getListModeStyle(type: string) {
  if(type === customerStore.listMode) return "btn-primary";
  return "btn-outline-primary"
}

async function exportCustomers() {
  exporting.value = true
  await customerStore.exportCustomer()
  exporting.value = false
}
</script>

<style lang="scss" scoped>
.export-button {
  min-width: 100px;
}
</style>