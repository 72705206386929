import { defineStore } from 'pinia'
import { Finance } from "./Finance"
import { Farm} from '../Farm/Farm';
import { useRequestStore } from '../System/Request';

export const useFinanceStore = defineStore('financeStore', {
    state: () => (
        { 
            finance: null as Finance|null
        }
    ),
    actions: {
        getFinanceOfFarm(farm: Farm) {
            const request = useRequestStore();
            const promise = new Promise((resolve) => {
                request.get("api/v2/finance/getFinance/"+ farm.id).then((data) => {
                    this.finance = data as Finance;
                    resolve(data)
                })
            })
            return promise;
        }

    }
})