<template>
    <div>
        <h1>Dein Profil</h1>
        <p>Hallo {{ userStore.me.name }}, <br><br>Hier kannst du alle Einstellung zu deinem Profil vornehmen.</p>
        <div class="profileEditAction">
            <button @click="editProfile" class="btn btn-outline-primary"><i class="fa-regular fa-pen-to-square"></i> Profil bearbeiten</button>
            <a href="/logout">
                <button class="btn btn-outline-danger ms-1"><i class="fa-regular fa-right-from-bracket"></i> Ausloggen</button>
            </a>
        </div>
        <hr>
      <UserFarmsList />
    </div>
</template>

<script lang="ts" setup>
import {Farm, useFarmStore} from "@/components/Farm";
import {useAlertStore} from "@/components/System/Alert/state";
import {useFormModalStore} from "@/components/System/FormModal/state";
import {useUserStore} from "@/components/System/User/state"
import {ChangePassword} from "@/components/System/User/User";
import {onMounted} from "vue";
import {useRouter} from "vue-router";
import UserFarmsList from "@/components/System/User/UserFarmsList.vue";

const userStore = useUserStore();
const farmStore = useFarmStore();
const formStore = useFormModalStore();
const alertStore = useAlertStore();

const router = useRouter();

onMounted(() => {
    console.log(userStore.me);
    
})

function editProfile() {
    const form = formStore.addForm();
    form.header = "Dein Profil bearbeiten";
    const email = form.addInput('Email', 'text',userStore.me.email, true);
    const firstName = form.addInput('Vorname', 'text',userStore.me.firstName);
    const lastName = form.addInput('Nachname', 'text',userStore.me.lastName);

    const pwdBtn = form.addButton("Passwort ändern", "outline-secondary");
    pwdBtn.faIcon = "fa-key"
    pwdBtn.then(() => {
        openPwdForm();
    })

    const saveBtn = form.addButton("Speichern", 'success')
    saveBtn.then(() => {
        const user = userStore.me;

        user.email = email.value as string;
        user.firstName = firstName.value as string;
        user.lastName = lastName.value as string
        form.close();
        userStore.updateUser(user)
    })
    saveBtn.faIcon = "fa-floppy-disk"
}

function openPwdForm() {
    const pwdForm = formStore.addForm();
    const oldPwd = pwdForm.addInput("Altes Passwort", "password")
    const newPwd = pwdForm.addInput("Neues Password", "password")
    const repPwd = pwdForm.addInput("Passwort wiederholden", "password")

    const saveBtn = pwdForm.addButton('Speichern', "success")
    saveBtn.faIcon = "fa-floppy-disk"

    saveBtn.then(() => {
        if(newPwd.value != repPwd.value) {
            alertStore.addAlert("Passwörter simmen nicht überein", "danger")
            return
        }
        const changePassword = {
            newPassword: newPwd.value as string,
            oldPassword: oldPwd.value as string
        } as ChangePassword

        userStore.changePassword(userStore.me, changePassword).then((result) => {
            if(result) {
                pwdForm.close();
            }
        })
    })    
}


</script>

<style lang="scss" scoped>

.basicCard {
    border-color: #3EAB38;
    border-radius: 20px;
    border-width: 2px;
    text-align: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
    transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
    cursor: pointer;
}

h4 {
    color: #3EAB38;;
    text-align: center;
    font-family: Short Stack;
    padding-top: 15px;
} 




</style>