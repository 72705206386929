<template>
<div class="row" v-if="userStore.me.id">
  <OrderAlert />
  <div class="col-12 col-lg-6">
    <div class="container-willkommen">
      <img class="img-fluid" alt="Bild 1" :src="require('@/assets/logo/eilise-logo_willkommen.svg')" />
      <!-- <img  class="maxHeight" :src="require('@/assets/logo/eilise-logo.svg')" /> -->
      <div style="margin-top: 4rem;">{{ farmStore.activeFarm.name }}
        <p>Hallo {{ userStore.me.name }}, schön dich zu sehen!</p>
        <p>Es gibt keine besonderen Neuigkeiten für dich.</p>
      </div>
    </div>

  </div>
  <div class="col-12 col-lg-6" >
    <div class="container" v-if="financeStore.finance">
        <div class="col-md-3 ml-auto">
          <img class="img-fluid" alt="Bild 1" :src="require('@/assets/images/huhn-mitarbeiter.svg')" />
        <h5 style="margin-top: 2.1rem;"> Mitarbeiter</h5>
        <h6 style="margin-top: 0.8rem;">Anzahl: {{ employeeStore.employeeItems.length }}</h6>
      </div>
      <div class="col-md-3 ml-auto">
              <img  class="img-fluid" alt="Bild 3" :src="require('@/assets/images/guthaben.svg')" />
        <h5 style="margin-top: 2.1rem;"> Guthaben Kunden</h5>
        <!-- <h6 style="margin-top: 0.8rem;" span :class="getStyleOfFinance(financeStore.finance.customerBalance)">{{ financeStore.finance.customerBalance }} €</span></h6> -->
        <span :class="getStyleOfFinance(financeStore.finance.customerBalance)">{{ financeStore.finance.customerBalance }} €</span>
      </div>
      <div class="col-md-3 ml-auto">
        <img  class="img-fluid" alt="Bild 3" :src="require('@/assets/images/umsatz.svg')" />
        <h5 style="margin-top: 2.1rem;"> Umsatz</h5>
        <!-- <h6 style="margin-top: 0.8rem;" span :class="getStyleOfFinance(financeStore.finance.income)">{{ financeStore.finance.income }} €</span></h6> -->
        <span :class="getStyleOfFinance(financeStore.finance.income)">{{ financeStore.finance.income }} €</span>
          </div>
      </div>
      <OrderSummary />
    </div>
  </div>
</template>


<!-- <template>
  <div class="header text-center maxHeight">
    <img  class="maxHeight" :src="require('@/assets/logo/eilise-logo.svg')" />
  </div>
  <h1>{{ farmStore.activeFarm.name }}</h1>
  <p>Hallo {{ userStore.me.name }},<br>schön dich zu sehen!</p>
  <p>Es gibt keine besonderen Neuigkeiten für dich.</p>
  <div class="home row" v-if="financeStore.finance">
    <div class="col card p-4 m-1">
      <div>Mitarbeiter</div>
      <div>
        Anzahl: {{ employeeStore.employeeItems.length }}
      </div>
    </div>
    <div class="col card p-4 m-1">
      <div>Guthaben aller Kunden</div>
        <div>
          <span :class="getStyleOfFinance(financeStore.finance.customerBalance)">{{ financeStore.finance.customerBalance }} €</span>
        </div>
    </div>
    <div class="col card p-4 m-1">
      <div>Umsatz</div>
        <div>
          <span :class="getStyleOfFinance(financeStore.finance.income)">{{ financeStore.finance.income }} €</span>
        </div>
    </div>
  </div>
</template> -->

<script lang="ts" setup>
import { useEmployeeStore } from "@/components/Employee/state";
import { useFarmStore } from "@/components/Farm";
import { useFinanceStore } from "@/components/Finance/state";
import { useUserStore } from "@/components/System/User/state";
import OrderSummary from "@/components/Order/OrderSummary.vue";
import OrderAlert from "@/components/Order/OrderAlert.vue";

const employeeStore = useEmployeeStore();
const financeStore = useFinanceStore();
const farmStore = useFarmStore();
const userStore = useUserStore();

function getStyleOfFinance(value: number) {
  if(value < 0) {
    return "text-danger"
  }
  return ""
}

</script>

<style lang="scss" scoped>
// .dashboardItem {
//   min-width: 150px;
// }

// .maxHeight {
//   max-height: 300px;
// }
.container-wrapper {
	margin-top: 10rem;
}
        

.container {
	margin-top: 5rem;
}

.container > div {
  width: 10rem;
  margin: auto;
}

@media only screen and (min-width: 992px) {
  .container {
    display: flex;
    justify-content: center;
  }

  .container > div {
    margin: unset;
  }
}

.container-willkommen{
  margin: auto;
  max-height: 50rem;
  width: 80%;
}

// .col-md-2 img {
//     width: 100%;
//     height: auto;
// }

</style>