<template>
  <div v-if="fileStore.shownPicture"  class="fixed-top">
    <div class="background" @click="fileStore.closePicture()">
      <div class="text-white" id="closeBtn">
        <i class="fa-regular fa-x fa-3x"></i>
      </div>
      <div class="image-container">
        <img :src="fileStore.shownPicture.path" class="shadow" alt="ShownPicture" />
      </div>
    </div>
  </div>

</template>

<script lang="ts" setup>

import {useFileSystemStore} from "@/components/System/FileSystem/state";

const fileStore = useFileSystemStore()


</script>

<style lang="scss" scoped>
.background {
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  height: 100%;
  width: 100%;
}

.image-container {
  padding: 10px;
  margin-top: 100px;
}

@media only screen and (min-width: 1600px) {
  .image-container {
    padding: unset;
    margin: auto;
    margin-top: 100px;
    width: 50%;
  }
}

#closeBtn {
  position: fixed;
  top: 10px;
  right: 20px;
}

img {
  width: 100%;
  border-radius: 5px;
}
</style>