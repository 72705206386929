<template>
    <div>
        <ProductFinder />
        <div class="row"> 
            <div class="col mt-2" v-for="product of productStore.foundProductItems" :key="product.id">
                <ProductSelectCard :product="product" @isSelect="isProductSelect" />
            </div>
        </div>
    </div>    
</template>

<script lang="ts" setup>
import { onMounted, defineEmits } from 'vue';
import ProductFinder from './ProductFinder.vue';
import ProductSelectCard from './ProductSelectCard.vue';
import { useProductStore } from './state';
import { useLoadingStore } from '../System/Loading/state';
import { Product } from './Product';


const productStore = useProductStore()
const loadingStore = useLoadingStore()

const emits = defineEmits(['isProductSelect'])

onMounted(() => {
    if(productStore.foundProductItems.length == 0) {
        loadingStore.addLoading('overlay')
        productStore.getProducts("").then(() => {
            loadingStore.removeLoading('overlay')
        })
    }
})

function isProductSelect(product: Product) {
    emits('isProductSelect', product)
}

</script>