<template>
  <div v-if="userStore.isEiliseAdmin">
    <h1>Eilise Farmen</h1>

    <div class="row">
      <template v-for="farm of eiliseFarmStore.eiliseFarmItems" :key="farm.id">
        <div class="col mt-2">
          <EiliseFarmAdminCard :farm="farm" />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {useUserStore} from "@/components/System/User/state";
import {useEiliseFarmStore} from "@/components/EiliseFarms/EiliseFarmState";
import EiliseFarmAdminCard from "@/components/EiliseFarms/EiliseFarmAdminCard.vue";
import {onMounted} from "vue";
const userStore = useUserStore();

const eiliseFarmStore = useEiliseFarmStore();

onMounted(() => {
  eiliseFarmStore.getAllFarms()
})

</script>