<template>
  <button class="btn btn-outline-secondary ms-md-1 flex-fill" @click="openFilterMenu">
  <i class="fa-regular fa-filter"></i> Filtern
  </button>
  <ProductSelectModal v-if="showProductSelector" @isClose="showProductSelector = false" @isProductSelect="filterProduct" />
  <CustomerCategorySelectModal v-if="showCustomerCategorySelector" @isClose="showCustomerCategorySelector = false" @isCategorySelect="filterCustomerCategory" />
</template>
<script setup lang="ts">

import {Product} from "@/components/Product/Product";
import {useFormModalStore} from "@/components/System/FormModal/state";
import ProductSelectModal from "@/components/Product/ProductSelectModal.vue";
import {ref, defineEmits} from "vue";
import {useOrderStore} from "@/components/Order/state";
import CustomerCategorySelectModal from "@/components/CustomerCategory/CustomerCategorySelectModal.vue";
import {CustomerCategory} from "@/components/CustomerCategory/CustomerCategory";

const emits = defineEmits(['filterIsChange'])

const orderStore = useOrderStore()

const showProductSelector = ref(false);
const showCustomerCategorySelector = ref(false)

const formModalStore = useFormModalStore()

function openFilterMenu() {
  const filterMenu = formModalStore.addForm('none');
  filterMenu.header = "Wähle einen Filter"
  filterMenu.addDescription("Wähle wonach du filtern möchtest:")
  const productFilter = filterMenu.addButton("Produkt", "outline-secondary")
  productFilter.then(() => {
    showProductSelector.value = true
    filterMenu.close()
  })

  const customerCategoryFilter = filterMenu.addButton("Kundenkategorie", "outline-secondary")
  customerCategoryFilter.then(() => {
    showCustomerCategorySelector.value = true
    filterMenu.close();

  })
}

function filterProduct(product: Product) {
  showProductSelector.value = false
  if(!orderStore.orderFilter) return
  orderStore.orderFilter.product = product
  emits('filterIsChange')
}

function filterCustomerCategory(category: CustomerCategory) {
  showCustomerCategorySelector.value = false
  if(!orderStore.orderFilter) return
  orderStore.orderFilter.customerCategory = category
  emits('filterIsChange')
}
</script>