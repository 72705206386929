import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import EmployeeMembersVue from '@/views/EmployeeMembers.vue'
import ProfileView from '@/views/ProfileView.vue'
import FarmEditView from '@/views/FarmEditView.vue'
import CustomerList from '@/views/CustomerList.vue'
import CustomerView from '@/components/Customer/CustomerView.vue'
import ProductList from '@/views/ProductList.vue'
import ProductView from '@/views/ProductView.vue'
import LoginView from '@/views/LoginView.vue'
import OrderViewVue from '@/components/Order/OrderView.vue'
import DeliveryEditor from '@/components/Delivery/DeliveryEditor.vue'
import DeliveryRouteList from '@/components/Delivery/DeliveryRouteList.vue'
import DeliveryModeView from '@/components/Delivery/DeliveryModeView.vue'
import CustomerCategoryList from '@/components/CustomerCategory/CustomerCategoryList.vue'
import CustomerCategoryView from '@/components/CustomerCategory/CustomerCategoryView.vue'
import DeliveryTableModeView from "@/components/Delivery/DeliveryTableModeView.vue";
import DeliveryActiveModeView from "@/components/Delivery/DeliveryActiveModeView.vue";
import EiliseFarmList from "@/components/EiliseFarms/EiliseFarmAdminList.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import EiliseUserList from "@/components/EiliseUser/EiliseUserList.vue";
import OrderListView from "@/components/Order/OrderListView.vue";
import DeviceSettingView from "@/components/System/DeviceSetting/DeviceSettingView.vue";
import RedirectDetected from "@/views/RedirectDetected.vue";
import TransactionListView from "@/components/Transaction/TransactionListView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: HomeView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/employees',
    name: 'employees',
    component: EmployeeMembersVue
  },
  {
    path: '/products',
    name: 'products',
    component: ProductList
  },
  {
    path: '/product/:productId',
    name: 'product',
    component: ProductView
  },
  {
    path: '/customerList',
    name: 'customerList',
    component: CustomerList
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/deviceSetting',
    name: 'deviceSetting',
    component: DeviceSettingView
  },
  {
    path: '/deliveryRouteList',
    name: 'deliveryRouteList',
    component: DeliveryRouteList
  },
  {
    path: '/deliveryRoute/:deliveryRouteId',
    name: 'deliveryRoute',
    component: DeliveryEditor
  },
  {
    path: '/deliveryMode',
    name: 'deliveryMode',
    component: DeliveryModeView
  },
  {
    path: '/deliveryActiveMode',
    name: 'deliveryActiveMode',
    component: DeliveryActiveModeView
  },
  {
    path: '/deliveryTableMode',
    name: 'deliveryTableMode',
    component: DeliveryTableModeView
  },
  {
    path: '/customerCategoryList',
    name: 'customerCategoryList',
    component: CustomerCategoryList
  },
  {
    path: '/customerCategory/:customerCategoryId',
    name: 'customerCategory',
    component: CustomerCategoryView
  },
  {
    path: '/farmEdit',
    name: 'farmEdit',
    component: FarmEditView
  },
  {
    path: '/customer/:customerId',
    name: 'customer',
    component: CustomerView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/order/:orderId',
    name: 'order',
    component: OrderViewVue
  },
  {
    path: '/orderList/',
    name: 'orderList',
    component: OrderListView
  },
  {
    path: '/resetPassword/:token',
    name: 'resetPassword',
    component: ResetPassword
  },
  {
    path: '/admin/eiliseFarm',
    name: 'adminEiliseFarm',
    component: EiliseFarmList
  },
  {
    path: '/admin/eiliseUser',
    name: 'adminEiliseUser',
    component: EiliseUserList
  },
  {
    path: '/redirectDetected',
    name: 'redirectDetected',
    component: RedirectDetected
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: TransactionListView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
