import { defineStore } from 'pinia'

import { useRequestStore } from '@/components/System/Request';
import { useFormModalStore } from "@/components/System/FormModal/state"
import { CustomerCategory } from './CustomerCategory';

export const useCustomerCategoryStore = defineStore('customerCategoryStore', {
    state: () => (
        { 
            customerCategoryItems: [] as CustomerCategory[],
            focusCategory: null as CustomerCategory|null
        }
    ),
    actions: {
        getAllCategories() {
            const promise = new Promise((resolve) => {
                const request = useRequestStore();
                request.get('api/v2/customerCategory/getAll').then((data) => {
                    this.customerCategoryItems = data as CustomerCategory[]
                    resolve(this.customerCategoryItems)
                })
            })
            return promise
        },
        getCategory(categoryId: number) {
            const promise = new Promise((resolve) => {
                const request = useRequestStore();
                request.get('api/v2/customerCategory/getCategory/'+categoryId.toString()).then((data) => {
                    this.focusCategory = data as CustomerCategory;
                    resolve(this.focusCategory)
                })
            })
            return promise
        },
        addCategory(category: CustomerCategory) {
            const promise = new Promise((resolve) => {
                const request = useRequestStore();
                request.post('api/v2/customerCategory/addCategory', category).then((data) => {
                    const customerCategory = data as CustomerCategory;
                    resolve(customerCategory)
                    this.customerCategoryItems.push(customerCategory)
                })
            })
            return promise
        },
        updateCategory(category: CustomerCategory) {
            const promise = new Promise((resolve) => {
                const request = useRequestStore();
                request.post('api/v2/customerCategory/updateCategory/'+ category.id, category).then((data) => {
                    const newData = data as CustomerCategory
                    const index = this.customerCategoryItems.findIndex(obj => obj.id == newData.id);
                    this.customerCategoryItems[index] = newData;

                    resolve(newData as CustomerCategory)
                })
            })
            return promise  
        },
        openEditCategoryForm(category = null as CustomerCategory|null) {
            const formModalStore = useFormModalStore()
            const form = formModalStore.addForm();

            if(category) {
                form.header = category.name +" bearbeiten";
            } else {
                form.header = "Neue Kategorie"
                category = new CustomerCategory();
            }

            const name = form.addInput('Name', 'text', category.name, true);
            const description = form.addInput("Beschreibung", 'textarea', category.description)

            form.addButton("Speichern", "success").then(() => {
                if(!category) return
                category.name = name.value as string
                category.description = description.value as string

                if(category.id === 0) {
                    this.addCategory(category);
                } else {
                    this.updateCategory(category);
                }
                form.close()
            })
        },
        openCategorySelector(ignoreCategories = [] as CustomerCategory[]) {
            const promise = new Promise((resolve) => {
                const formModalStore = useFormModalStore();
                const form = formModalStore.addForm("none");
    
                form.header = "Kategorie auswählen";
    
                for(const category of this.customerCategoryItems) {
                    let ignore = false
                    for(const ignoreCategory of ignoreCategories) {
                        if(ignoreCategory.id == category.id) {
                            ignore = true
                        }
                    }
                    if(ignore) continue
                    const btn = form.addButton(category.name, "primary")
                    btn.then(() => {
                        form.close();
                        resolve(category);
                    })
                }

                form.addButton("Abbruch", 'outline-secondary').then(() => {
                    form.close()
                    resolve(false)
                })
            })
            return promise
   
            
        }
    }
})