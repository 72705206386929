import { defineStore } from 'pinia'
import { reactive } from 'vue';

import { useRequestStore } from '@/components/System/Request';
import { Employee } from './Employee';
import { useFarmStore } from '../Farm';

const ROLES: {[key: string]: number} = {'Admin': 1000, 'Employee': 700, 'Mitarbeiter': 700, 'Member': 500}

function getRoleNumber(role: string) {
    return ROLES[role]
}

export const useEmployeeStore = defineStore('employeeStore', {
    state: () => (
        { 
            employeeItems: [] as Employee[],
            me: {} as Employee
        }
    ),
    actions: {
        getMe() {
            const requestStore = useRequestStore();
            const promise = new Promise((resovle) => {
                requestStore.get('api/v2/member/getMe').then((data) => {
                    this.me = data as Employee;
                    resovle(data)
                })
            })
            return promise;
        },
        getEmployees() {
            const farmState = useFarmStore();
            const requestStore = useRequestStore();
            const promise = new Promise((resovle) => {
                requestStore.get('api/v2/member/getEmployees/' + farmState.activeFarm.id).then((data) => {
                    this.employeeItems = reactive(data as Employee[]);
                    resovle(data)
                })
            })
            return promise;
        },
        updateEmployee(employee: Employee) {
            const requestStore = useRequestStore();
            const request = requestStore.post('api/v2/member/updateFarmMember/'+ employee.id, employee)

            request.then((data) => {
                const updatedEmployee = data as Employee;
                const index = this.employeeItems.findIndex(obj => obj.id == updatedEmployee.id)
                this.employeeItems[index] = updatedEmployee;
            })

            return request;
        },
        inviteEmployee(employee: Employee) {
            const requestStore = useRequestStore();
            const farmState = useFarmStore();
            const request = requestStore.post('api/v2/member/inviteFarmMember/'+farmState.activeFarm.id, employee)
            request.then((data) => {
                this.employeeItems.push(data as Employee);
            })
            return request;
        },
        removeEmployee(employee: Employee) {
            const requestStore = useRequestStore();
            const request = requestStore.delete('api/v2/member/removeFarmMember/'+employee.id)
            request.then(() => {
                const index = this.employeeItems.findIndex(obj => obj.id == employee.id)
                this.employeeItems.splice(index,1);

            })
            return request
        },
        isAllowed(role: string) {            
            if(this.me.role == undefined) {
                return false
            }
            for(const foundRole of this.me.role) {
                if(getRoleNumber(foundRole) >= getRoleNumber(role)) {
                    return true;
                }
            }
            return false
        }
    }
})