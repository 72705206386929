<template>
  <div class="row mt-2 align-items-center">
    <div class="form-group col-4">
      <label for="fromDate">Von</label>
      <input type="date" class="form-control" id="fromDate" placeholder="Von" v-model="transactionStore.searchRequest.fromDate">
    </div>
    <div class="form-group col-4">
      <label for="toDate">Bis</label>
      <input type="date" class="form-control" id="toDate" placeholder="Bis" v-model="transactionStore.searchRequest.toDate">
    </div>
    <div class="form-group col-4">
      <button @click="searchAction" class="btn btn-primary align-middle">Suchen</button>
    </div>
  </div>


</template>

<script setup lang="ts">

import {useTransactionStore} from "@/components/Transaction/state";
import {onMounted} from "vue";

const transactionStore = useTransactionStore()

onMounted(() => {
  searchAction()
})

function searchAction() {
  transactionStore.searchRequest.page = 1
  transactionStore.getTransactions()
  transactionStore.getSummary()
}


</script>

<style scoped lang="scss">

</style>