<template>
    <nav  class="navbar navbar-expand-lg bg-primary text-light">
        <div class="container-fluid " v-if="userStore.isLoggedIn" >
            <span class="navbar-brand"><i class="fa-regular fa-egg fa-2x align-middle"></i><span class="d-none d-sm-inline"> {{ farmStore.activeFarm.name }}</span></span>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll"
                aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
                <div class="collapse navbar-collapse" id="navbarScroll">
                    <template v-if="!deliveryRouteStore.activeDeliveryRoute && userStore.isLoggedIn && !isAkquiseModeOn">
                        <ul class="navbar-nav">
                             <li class="nav-item">
                                <span class="nav-link" :class="isActive('home')" @click="open('home')">Home</span>
                            </li>
                            <li class="nav-item" @click="open('products')">
                                <span class="nav-link" :class="isActive('products')">Produkte</span>
                            </li>
                            <li class="nav-item dropdown">
                                <span class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    Kunden
                                </span>
                                <ul class="dropdown-menu">
                                    <li><span class="dropdown-item" :class="isActive('customerList')" @click="open('customerList')">Kundenliste</span></li>
                                    <li><span class="dropdown-item" :class="isActive('customerCategoryList')" @click="open('customerCategoryList')">Kundenkategorien</span></li>
                                    <li><span class="dropdown-item" v-if="!isAkquiseModeOn" @click="startAkquiseModus">Akquise modus starten</span></li>
                                </ul>
                            </li>
                            <li class="nav-item dropdown">
                                <span class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown">Lieferung</span>
                                <ul class="dropdown-menu">
                                    <li><span class="dropdown-item" :class="isActive('deliveryRouteList')" @click="open('deliveryRouteList')">Lieferrouten</span></li>
                                    <li><span class="dropdown-item" :class="isActive('orderList')" @click="open('orderList')">Bestellungen</span></li>

                                </ul>
                            </li>
                          <li class="nav-item dropdown">
                            <span class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown">Buchhaltung</span>
                            <ul class="dropdown-menu">
                              <li><span class="dropdown-item" :class="isActive('transactions')" @click="open('transactions')">Buchungen</span></li>
                            </ul>
                          </li>
                            <li class="nav-item dropdown">
                                <span class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    Einstellung
                                </span>
                                <ul class="dropdown-menu">
                                    <li><span class="dropdown-item" :class="isActive('profile')" @click="open('profile')">Dein Profil</span></li>
                                  <li><span class="dropdown-item" :class="isActive('deviceSetting')" @click="open('deviceSetting')">Geräteeinstellung</span></li>
                                    <li>
                                        <hr class="dropdown-divider">
                                    </li>
                                    <li><span class="dropdown-item" :class="isActive('farmEdit')" @click="open('farmEdit')">Der Hof</span></li>
                                    <li><span class="dropdown-item" :class="isActive('employees')" @click="open('employees')">Mitarbeiter</span></li>
                                    <li>
                                        <hr class="dropdown-divider">
                                    </li>
                                    <li><span class="dropdown-item" :class="isActive('products')" @click="open('products')">Produkte</span></li>
                                    <li><span class="dropdown-item" :class="isActive('customerList')" @click="open('customerList')">Kunden</span></li>
                                    <li>
                                        <hr class="dropdown-divider">
                                    </li>
                                    <template v-if="userStore.isEiliseAdmin">
                                      <li><span class="dropdown-item" :class="isActive('adminEiliseFarm')" @click="open('adminEiliseFarm')">EiLiSe Kunden</span></li>
                                      <li><span class="dropdown-item" :class="isActive('adminEiliseUser')" @click="open('adminEiliseUser')">EiLiSe User</span></li>

                                      <li>
                                        <hr class="dropdown-divider">
                                      </li>
                                    </template>
                                    <li>
                                        <span class="dropdown-item text-danger" @click="logout">Logout</span>
                                    </li>
                                    <li>
                                      <hr class="dropdown-divider">
                                    </li>
                                    <li class="text-muted text-center"><em>App: {{packageVersion}}</em></li>
                                </ul>
                            </li>
                          <li class="nav-item dropdown">
                            <span class="nav-link"
                               @click="logout"
                               >
                              <i class="fa-regular fa-right-from-bracket"></i> Logout
                            </span>
                          </li>
                        </ul>
                    </template>
                  <template v-if="isAkquiseModeOn">
                    <ul class="navbar-nav my-2 my-lg-0" >
                      <li class="nav-item">
                        <span class="nav-link" @click="stopAkquiseModus">Akquise modus stoppen</span>
                      </li>
                    </ul>
                  </template>
                    <template v-if="deliveryRouteStore.activeDeliveryRoute">
                        <ul class="navbar-nav my-2 my-lg-0" >
                          <li class="nav-item">
                            <span class="nav-link" :class="isActive('deliveryMode')" @click="open('deliveryActiveMode')">Standardmodus</span>
                          </li>
                            <li class="nav-item">
                              <span class="nav-link" :class="isActive('deliveryTableMode')" @click="open('deliveryTableMode')">Tabellenmodus</span>
                            </li>
                            <li class="nav-item">
                                <span class="nav-link" :class="isActive('home')" @click="stopDeliveryMode" >Auslieferung beenden</span>
                            </li>

                        </ul>
                    </template>
    <!--                 <form class="d-flex" role="search">
                        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                        <button class="btn btn-outline-success" type="submit">Search</button>
                    </form> -->
                </div>
        </div>
      <div v-else>
        <button @click="open('login')" class="btn btn-outline-dark ms-2">Login</button>
      </div>
</nav>

</template>

<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router"
import { useFarmStore } from "../Farm";
import { useDeliveryRouteStore } from "../Delivery/state";
import { useUserStore } from "./User/state";
import { useLoadingStore } from "./Loading/state";
import {computed, nextTick, onMounted, ref} from "vue";
import { version } from "@/../package.json"
import {useEmployeeStore} from "@/components/Employee/state";

const router = useRouter()
const route = useRoute()
const userStore = useUserStore();
const loadingStore = useLoadingStore();
const employeeStore = useEmployeeStore()
const deliveryRouteStore = useDeliveryRouteStore();

const farmStore = useFarmStore();
const isAkquiseModeOn = ref(false)

function isActive(routeName: string) {
    if(route.name === routeName) {
        return "active";
    }
    return ""
} 

function open(routeName: string) {
    const navBarScroll = document.getElementById('navbarScroll');
    navBarScroll?.classList.remove('show');
    router.push({name: routeName})
}

function stopDeliveryMode() {
    deliveryRouteStore.stopDeliveryMode();
    open('home')
}

function logout() {
    loadingStore.addLoading('overlay');
    userStore.logout().then(() => {
        loadingStore.removeLoading('overlay')
        router.push('login');
        
    })
}

const packageVersion = computed(() => {
  return version
})

onMounted(() => {
    // canInstall.value((event) => {
  //   // Prevent Chrome 67 and earlier from automatically showing the prompt:
  //   event.preventDefault();
  //   // Stash the event so it can be triggered later:
  //   deferredPrompt.value = event;
  // })
})

function startAkquiseModus() {
  isAkquiseModeOn.value = true
  router.push("/redirectDetected")
  // Ziehe einmal den get FollowMe damit altlasten entfernt werden. Denn der letzte FollowMe könnte alt sein
  userStore.getFollowMe().then(() => {
    askForFollowMe()
  })

  //
}

function stopAkquiseModus() {
  isAkquiseModeOn.value = false
  router.push("/")
}

function askForFollowMe() {
  if(!isAkquiseModeOn.value) return
  userStore.getFollowMe().then((data) => {
    if(!isAkquiseModeOn.value) return
    if(typeof data === "string") {
      router.push("/redirectDetected")
      setTimeout(() => {
        router.push("/"+data);
      }, 100)
    }
    setTimeout(() => {
      askForFollowMe()
    }, 2500)
  })
}

</script>

<style lang="scss" scoped>
@import '@/assets/style/breakpoints';
.navbar {
    background-color: #3EAB38;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	}

.navbar-nav {
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @media (min-width: $bp-lg) {
    margin-right: 10rem;
  }
}

.navbar-nav .nav-link {
    color: white ;
}

.navbar-nav .nav-link .active {
  color: black !important;
}

.navbar-brand:hover, .navbar-brand:focus {
    color: white; 
}

.navbar-brand {
    color: white;
}

.nav-item {
  cursor: pointer;
}

// .nav-link {
//     color: white;
// }
</style>