<template>
  <div class="row">
    <div class="col mt-2" v-for="category of customerCategoryStore.customerCategoryItems" :key="category.id">
      <CustomerCategorySelectCard :customerCategory="category" @isSelect="isCategorySelect" />
    </div>
  </div>
</template>
<script setup lang="ts">
import CustomerCategorySelectCard from "@/components/CustomerCategory/CustomerCategorySelectCard.vue";
import {useCustomerCategoryStore} from "@/components/CustomerCategory/store";
import {defineEmits} from 'vue'
import {CustomerCategory} from "@/components/CustomerCategory/CustomerCategory";

const customerCategoryStore = useCustomerCategoryStore();

const emits = defineEmits(['isCategorySelect'])

function isCategorySelect(customerCategory: CustomerCategory) {
  emits('isCategorySelect', customerCategory)
}
</script>