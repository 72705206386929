<template>
    <div>
        <div class="customerShadow headerElement">
            <h1>{{ productStore.focusProduct.name }}</h1>
        </div>
        <div class="fastActionBar customerShadow">
            <div @click.stop>
                <div class="actionBar d-flex justify-content-center">
                    <a  
                        href="#"
                        @click="openProductEditor"
                        v-if="employeeState.isAllowed('Admin')">
                        <div class="callIcon">
                            <i class="fa-regular fa-gear"></i>
                        </div>
                    </a>

                </div>
                </div>
        </div>
        <ImageListViewer 
            :file-ids="productStore.focusProduct.images"
            :module-name="'product'"
            :entry-id="productStore.focusProduct.id"
            @start-upload="startUploadEvent"
            @finish-upload="finishUploadEvent"
            @start-remove="startRemoveEvent"
            @finish-remove="finishRemoveEvent"
            @sort-changed="saveImageIds"
             />
    </div>  
</template>

<script lang="ts" setup>
import { useEmployeeStore } from '@/components/Employee/state';
import { useProductStore } from '@/components/Product/state';
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import ImageListViewer from '@/components/System/FileSystem/ImageListViewer.vue';
import { useLoadingStore } from '@/components/System/Loading/state';

const productStore = useProductStore();
const loadingStore = useLoadingStore();
const employeeState = useEmployeeStore();

const route = useRoute();

onMounted(() => {   
    if(productStore.focusProduct.id == undefined) {       
        productStore.getProduct(parseInt(route.params.productId as string));
    }
})

function openProductEditor() {
    productStore.openProductEditor(productStore.focusProduct)
}

function startUploadEvent() {
    loadingStore.addLoading('overlay')
}

function finishUploadEvent() {
    productStore.getProduct(parseInt(route.params.productId as string)).then(() => {
        loadingStore.removeLoading('overlay')
    })
}

function startRemoveEvent() {
    loadingStore.addLoading('overlay')  
}

function finishRemoveEvent() {
    productStore.getProduct(parseInt(route.params.productId as string)).then(() => {
        console.log("PRODUKT NEU GELADEN");
        
        loadingStore.removeLoading('overlay')
    })
}

function saveImageIds(fileIds: Array<number>) {
    productStore.focusProduct.images = fileIds
    productStore.updateProduct(productStore.focusProduct)
}
</script>

<style lang="scss" scoped>

</style>