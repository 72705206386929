<template>
  <div class="card" v-if="user">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="icon">
          <span @click.stop @click="editUser" class="text-primary">
            <i class="fa-regular fa-pen-to-square fa-xl"></i>
          </span>
      </div>
    </div>
    <div class="nameHeader m-auto">
      <h5>{{ user.name }}</h5>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {PropType, defineProps} from "vue";
import {User} from "@/components/System/User/User";

const props = defineProps({
  user: Object as PropType<User>
})

function editUser() {
  //
}

</script>