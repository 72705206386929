<template>
  <FormModalHolder />
  <LoadingInfoTemplate />
  <AlertHolder />
  <NavBar />
  <UtilityBar />
  <ImageModal />
  <AppUpdate />
  <div class="px-2 px-lg-5 bg-white mb-5" v-if="employeeStore.me">
    <template v-if="employeeStore.me.hasInvitationAccepted && userStore.isLoggedIn">
    <router-view/>
    </template>
    <template v-if="!employeeStore.me.hasInvitationAccepted">
      <InvitationTemplate />
    </template>
    <template v-if="!userStore.isLoggedIn">
      <LoginView />
    </template>
    </div>
  <div class="px-2 px-lg-5 bg-white mb-5" v-if="router.currentRoute.value.name == 'resetPassword'">
    <ResetPassword  />
  </div>
  <UserObserver />
</template>

<script setup lang="ts">
import NavBar from "./components/System/NavBar.vue"
import FormModalHolder from "./components/System/FormModal/FormModalHolder.vue"
import LoadingInfoTemplate from "./components/System/Loading/LoadingInfoTemplate.vue"
import AppUpdate from "@/mixins/AppUpdate.vue"
import ImageModal from "@/components/System/FileSystem/ImageModal.vue"
import { useUserStore } from "./components/System/User/state";
import  AlertHolder  from "./components/System/Alert/AlertHolder.vue";
import { useLoadingStore } from "./components/System/Loading/state";
import {  useRouter } from "vue-router";
import UserObserver from "@/components/System/UserObserver.vue";
import UtilityBar from "@/components/System/UtilityBar.vue";
import {useDeviceSettingStore} from "@/components/System/DeviceSetting/deviceSettingStore";
import {useEmployeeStore} from "@/components/Employee/state";
import InvitationTemplate from "@/components/Employee/InvitationTemplate.vue";
import LoginView from "@/views/LoginView.vue";
import ResetPassword from "@/views/ResetPassword.vue";

//const requestStore = useRequestStore();
const loadingStore = useLoadingStore();
const router = useRouter();
const employeeStore = useEmployeeStore()

const userStore = useUserStore()
const deviceSettingStore = useDeviceSettingStore();

deviceSettingStore.init()
</script>

<style lang="scss">
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  font-size: 16px;
} */

@import "./assets/style/style.scss";

.container {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
</style>
