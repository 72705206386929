<template>
  <div v-if="data.product" @click="isClicked" class="card">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="icon" v-if="employeeState.isAllowed('Admin')">
        <span @click.stop @click="openEditProduct" class="text-primary">
          <i class="fa-regular fa-pen-to-square fa-xl"></i>
        </span>
      </div>
    </div>
    <template v-if="product?.images && product.images.length > 0">
      <img :src="fileSystemStore.getImagePath(product.images[0])" class="card-img-top" :alt="product.name"/>
    </template> 
    <div class="card-body text-center">
      <h5 class="text-center" v-if="data.product.name">{{ data.product.name }}</h5>
      <p class="card-text">{{ data.product.description }}</p>
    </div>                 
  </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive, defineProps, defineEmits, PropType } from 'vue';
import { Product } from './Product';
import { useEmployeeStore } from '../Employee/state';
import { useProductStore } from './state';
import { useFileSystemStore } from '../System/FileSystem/state';
import { useRouter } from 'vue-router';

const fileSystemStore = useFileSystemStore();
const employeeState = useEmployeeStore();
const productStore = useProductStore()
const router = useRouter();

const emits = defineEmits(['isClicked'])

const data = reactive({
    product: {} as Product
})

const props = defineProps({ 
    product: Object as PropType<Product>
})

onMounted(() => {
    cardUpdate()
})

function cardUpdate(updatedData: Product|null = null) {   
    if(updatedData) {
        data.product = updatedData;
    } else {
        data.product = props.product as Product;
    }
}


function isClicked() {
    emits('isClicked', data.product)
}

function openEditProduct() {
    if(!props.product) return
    productStore.openProductEditor(props.product).then((productData) => {
        cardUpdate(productData as Product)
    })
}

function openProduct() {
    if(!props.product) return
    productStore.setFocusProduct(props.product)
    router.push({name: "product", params: { productId: props.product.id }})
}

</script>

<style lang="scss" scoped>


</style>