<template>
    <div>
        <h1>Kundenkategorien</h1>
        <div> 
            <button @click="addCategory" class="btn btn-success w-100">Kategorie hinzufügen</button>
        </div>
    </div>
    <div>
        <div class="row">
            <template v-for="customerCategory of customerCategoryStore.customerCategoryItems" :key="customerCategory.id">
                <div class="col mt-2"> 
                <CustomerCategoryCard :customerCategory="customerCategory" @isClicked="openCategory" />
                </div>
            </template>
            <div class="text-center mt-5" v-if="customerCategoryStore.customerCategoryItems.length == 0 && loadingStore.backgroundLoading.requestCounter == 0">
               <p><em>Leider keine Kategorie gefunden. Lege doch gleich eine an.</em></p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useCustomerCategoryStore } from "./store";
import CustomerCategoryCard from "./CustomerCategoryCard.vue"

import { useLoadingStore } from "../System/Loading/state";
import { CustomerCategory } from "./CustomerCategory";
import { useRouter } from "vue-router";

const customerCategoryStore = useCustomerCategoryStore();
const loadingStore = useLoadingStore()
const router = useRouter();

function openCategory(category: CustomerCategory) {
    router.push({name: "customerCategory", params: { customerCategoryId:  category.id.toString()} })
}

function addCategory() {
    customerCategoryStore.openEditCategoryForm()
}
</script>