import { Farm } from "../Farm"
import { User } from "../System/User/User"

export class Employee {
    id =0
    member = {} as User
    description = ""
    hasInvitationAccepted = false
    role = [] as UserRoles[]
    farm = {} as Farm

}

export type UserRoles = "Admin"|"Mitarbeiter"|"Kunde"
