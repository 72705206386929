import { defineStore } from 'pinia'
import { FormModal } from "./FormModal"
import { reactive } from 'vue';

export const useFormModalStore = defineStore('formModalStore', {
    state: () => (
        { 
            formModalItems: [] as FormModal[]
        }
    ),
    actions: {
        addForm(preset: "none"|"default" = "default") {
            const form = reactive(new FormModal());

            if(preset === "default") {
                form.addButton("Abbruch","outline-secondary",true).then(() => {
                    this.closeForm(form);
                })

            }

            this.formModalItems.push(form)

            return form;
        },
        closeForm(form: FormModal) {
            const index = this.formModalItems.findIndex(obj => obj.id === form.id);
            this.formModalItems.splice(index,1)
        }
    }
})