import { defineStore } from 'pinia'
import { reactive } from 'vue';
import { BackgroundLoading, OverlayLoading } from './LoadingInterface';

export const useLoadingStore = defineStore('loadingStore', {
    state: () => (
        { 
            overlayLoading: {} as OverlayLoading,
            backgroundLoading: {} as BackgroundLoading
        }
    ),
    actions: {
        init() {
            return new Promise((resolve) => {
                this.overlayLoading = reactive(new OverlayLoading());
                this.backgroundLoading = reactive(new BackgroundLoading());
                resolve(true)
            })

        },
        addLoading(type = "background" as "overlay"|"background", amount = 1) {
            if(type == "background") {
                this.backgroundLoading.addRequest(amount);
            } else {
                this.overlayLoading.addRequest(amount);
            }
        },
        removeLoading(type = "background" as "overlay"|"background", amount = 1) {
            if(type == "background") {
                this.backgroundLoading.finishRequest(amount);
            } else {
                this.overlayLoading.finishRequest(amount);
            }
        }
    }
})