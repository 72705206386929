import { defineStore } from 'pinia'
import { User, ChangePassword } from "./User"
import { useRequestStore } from '../Request';
import { useLoadingStore } from '../Loading/state';
import { useFarmStore } from '@/components/Farm';
import { useEmployeeStore } from '@/components/Employee/state';
import { useFinanceStore } from '@/components/Finance/state';
import { useTransactionStore } from '@/components/Transaction/state';
import { useDeliveryRouteStore } from '@/components/Delivery/state';
import { useCustomerStore } from '@/components/Customer/state';
import { useCustomerCategoryStore } from '@/components/CustomerCategory/store';
import {useDeviceSettingStore} from "@/components/System/DeviceSetting/deviceSettingStore";

export const useUserStore = defineStore('userStore', {
    state: () => (
        { 
            me: {} as User,
            isLoggedIn: false
        }
    ),
    actions: {
        login(loginData: {email: string, password: string}) {
            const requestStore = useRequestStore();
            const promise = new Promise((resovle) => {
                const formData = new FormData()

                formData.append("email", loginData.email)
                formData.append("password", loginData.password)

                requestStore.login(loginData).then((data) => {                    
                    if(data) {
                        this.getMe().then(() => {
                            this.startApplication().then(() => {
                                resovle(true)
                            })
                        })
                    } else {
                        resovle(false)
                    }
                })
            })
            return promise;
        },
        logout() {
            const requestStore = useRequestStore();
            const promise = new Promise((resovle) => {
                requestStore.get('logout').then(() => {
                    this.isLoggedIn = false
                    resovle(true)
                })
            })
            return promise
        },
        getMe() {
            const requestStore = useRequestStore();
            const promise = new Promise((resovle) => {
                requestStore.get('api/v2/user/getMe').then((data) => {
                    if(data === false) {
                        resovle(false)
                        return false
                    }
                    this.isLoggedIn = true
                    this.me = data as User;
                    resovle(data)
                })
            })
            return promise;
        },
        updateUser(user: User) {
            const requestStore = useRequestStore();
            const promise = new Promise((resovle) => {
                requestStore.post('api/v2/user/update/'+ user.id ,user ).then((data) => {
                    this.me = data as User;
                    resovle(data)
                })
            })
            return promise;
        },
        changePassword(user: User, password: ChangePassword) {
            const requestStore = useRequestStore();
            const promise = new Promise((reslove) => {
                requestStore.post("api/v2/user/changePassword/"+ user.id, password).then((data) => {
                    reslove(data)
                })
            })
            return promise
        },
        setFollowMe(value: string) {
            const requestStore = useRequestStore();
            return requestStore.post('api/v2/user/setFollowMe', {link: value})
        },
        getFollowMe() {
            const requestStore = useRequestStore();
            return requestStore.get('api/v2/user/askForFollowMe')
        },
        delayedRemoveLoading() {
            const loadingStore = useLoadingStore();
            loadingStore.removeLoading('overlay');
        },
        async startApplication() {
            const farmStore = useFarmStore();
            const employeeState  = useEmployeeStore();
            const financeStore = useFinanceStore();
            const transactionStore = useTransactionStore();
            const customerCategoryStore = useCustomerCategoryStore();

            const promise = new Promise((resolve) => {
                const loadingStore = useLoadingStore();
                loadingStore.addLoading('overlay',7);
                this.getMe().then(() => {
                    this.delayedRemoveLoading()
                    employeeState.getMe().then(() => {
                        this.delayedRemoveLoading()
                        transactionStore.getPaymentMethods().then(() => {
                            this.delayedRemoveLoading()
                        })
                        farmStore.getFarm().then(() => {
                            this.delayedRemoveLoading()
                            financeStore.getFinanceOfFarm(farmStore.activeFarm).then(() => {
                                this.delayedRemoveLoading()
                            }).then(() => {
                                farmStore.loadContentOfFarm().then(() => {
                                    this.delayedRemoveLoading()
                                    customerCategoryStore.getAllCategories().then(() => {
                                        this.delayedRemoveLoading()
                                        resolve(true)
                                    })
                                })

                            })
                        })
                    })
                  })

            })
            return promise
        }
    },
    getters: {
        // Prüft ob der Nutzer Eilise einstellung vornehmen darf
        isEiliseAdmin(state) {
            if(!state.me.roles) return false
            if(state.me.roles.findIndex(obj => obj === "ROLE_ADMIN") >= 0) {
                return true
            }
            return false
        },
    }
})