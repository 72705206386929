<template>
  <div class="text-center container">
    <h1>Geräteeinstellung</h1>
    <p>Hier kannst du einstellung vornehmen die Gerätespezifisch sind. Sie werden lediglich lokal gespeichert.</p>
    <div class="row">
      <div class="col">
    <div class="card">
      <div class="card-header">
        <h5>Datenvolumen reduzieren</h5>
      </div>
      <div class="card-body">
        <p>
          Aktiviere diesen Modus, wenn du nur ein geringes Datenvolumen zur Verfügung hast.<br>
          Dadurch werden nur die nötigsten Daten geladen.
          <br><em>z. B. weniger gleichzeitig geladene Bestellungen.</em>
        </p>
        <div class="text-black">
          <div class="d-flex justify-content-center align-items-center">
            <div class="me-1 text-muted fa-sm">Deaktiviert</div>
            <div @click="changeDateSaveMode" class="pointer">
              <span v-if="deviceSettingStore.isDataSaveMode" class="">
                <i class="fa-regular fa-toggle-on fa-2x"></i>
              </span>
              <span v-if="!deviceSettingStore.isDataSaveMode">
                <i class="fa-regular fa-toggle-off fa-2x"></i>
              </span>
            </div>
            <div class="ms-1 text-muted fa-sm">Aktiviert</div>
          </div>
        </div>
      </div>
    </div>
      </div>

      <div class="col">
    <div class="card mt-1">
      <div class="card-header">
        <h5>Lieferverzugswarnung</h5>
      </div>
      <div class="card-body">
        <p>
          Möchtest du auf der Startseite auffällig informiert werden, wenn du noch Lieferung im Lieferverzug hast?<br>
          Zudem kannst du angeben ab wie viel Tage lieferverzug dich die App warnen soll.
        </p>
        <div class="text-black text-start">
          <div class="d-flex justify-content-center align-items-center">
            <div class="me-1 text-muted fa-sm">Deaktiviert</div>
            <div @click="toggleDeliveryDelayWarning" class="pointer">
              <span v-if="deviceSettingStore.getDeliveryDelayWarning !== false" class="">
                <i class="fa-regular fa-toggle-on fa-2x"></i>
              </span>
              <span v-if="deviceSettingStore.getDeliveryDelayWarning === false">
                <i class="fa-regular fa-toggle-off fa-2x"></i>
              </span>
            </div>
            <div class="ms-1 text-muted fa-sm">Aktiviert</div>
          </div>
          <div class="input-group mt-2" v-if="deviceSettingStore.getDeliveryDelayWarning !== false">
            <span class="input-group-text" id="basic-addon3">Warnung ab</span>
            <input type="number" id="deliverDelayWarningDays"  class="form-control" placeholder="1" @change="saveSetting" v-model="deviceSettingStore.setting.deliveryDelayWarning">
            <span class="input-group-text" id="basic-addon3">Tage</span>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  </div>
</template>

<script lang="ts" setup>

import {useDeviceSettingStore} from "@/components/System/DeviceSetting/deviceSettingStore";

const deviceSettingStore = useDeviceSettingStore();

function changeDateSaveMode() {
  deviceSettingStore.setting.dataSaveMode = !deviceSettingStore.setting.dataSaveMode;
  saveSetting()
}

function toggleDeliveryDelayWarning() {
  if(deviceSettingStore.setting.deliveryDelayWarning === false) {
    deviceSettingStore.setting.deliveryDelayWarning = 3;
  } else {
    deviceSettingStore.setting.deliveryDelayWarning = false;
  }
  saveSetting()
}

function saveSetting() {
  deviceSettingStore.saveSetting()
}

</script>