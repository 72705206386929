<template>
    <div class="background p-1" @click="closeForm">
        <div @click.stop class="formContainer m-auto mt-5">
            <div class="header text-center p-2">
                <h3 class="m-0">{{ data.formModal.header }}</h3>
                <div class="xClose" v-if="data.formModal.allowToClose" @click="closeForm">
                    <i class="fa-regular fa-xmark"></i>
                </div>
            </div>
            <div class="inputFields">
                <template v-for="description of data.formModal.descriptionFields" :key="description.id">
                    <div :class="'p-1 text-'+description.type">
                        <label v-if="description.label" class="form-label">{{ description.label }}</label>
                        <p v-html="description.value"></p>
                    </div>
                </template>
                <template v-for="input of data.formModal.inputFields" :key="input.id">
                    <div v-if="input.type == 'text'">
                        <label class="form-label" :class="labelStyle(input)">{{ input.label }}</label>
                        <input class="form-control" :class="inputStyle(input)" :type="input.type" v-model="input.value" @keydown.enter="runLastBtnEvent" />
                        <div v-if="input.isMissing" class="alertText">Bitte fülle das Feld {{ input.label }} aus</div>
                      <div v-if="input.errorMessage" class="alertText">{{input.errorMessage }}</div>
                    </div>
                    <div v-if="input.type == 'number'">
                        <label class="form-label" :class="labelStyle(input)">{{ input.label }}</label>
                      <div class="d-flex">
                        <input class="form-control w-100" :class="inputStyle(input)" @click="autoSelect" :type="input.type" v-model="input.value" @keydown.enter="runLastBtnEvent" />
                        <template v-for="button of input.buttons" :key="button.id">
                          <button class="btn" :class="'btn-'+button.style" @click="buttonEvent(button)">{{ button.label }}</button>
                        </template>
                      </div>
                      <div v-if="input.isMissing" class="alertText">Bitte fülle das Feld {{ input.label }} aus</div>
                      <div v-if="input.errorMessage" class="alertText">{{input.errorMessage }}</div>
                    </div>
                    <div v-if="input.type == 'password'">
                        <label class="form-label" :class="labelStyle(input)">{{ input.label }}</label>
                        <input class="form-control" :class="inputStyle(input)" :type="input.type" v-model="input.value" @keydown.enter="runLastBtnEvent" />
                        <div v-if="input.isMissing" class="alertText">Bitte fülle das Feld {{ input.label }} aus</div>
                      <div v-if="input.errorMessage" class="alertText">{{input.errorMessage }}</div>
                    </div>
                    <div v-if="input.type == 'date'">
                        <label class="form-label" :class="labelStyle(input)">{{ input.label }}</label>
                        <input class="form-control" :class="inputStyle(input)" :type="input.type" v-model="input.value" @keydown.enter="runLastBtnEvent" />
                        <div v-if="input.isMissing" class="alertText">Bitte fülle das Feld {{ input.label }} aus</div>
                        <div v-if="input.errorMessage" class="alertText">{{input.errorMessage }}</div>
                    </div>
                    <div v-if="input.type == 'select' && input.optionValues && input.optionValues.length > 0">
                        <label class="form-label" :class="labelStyle(input)">{{ input.label }}</label>
                        <select v-if="isStringOrNumber(input.optionValues)" class="form-select" :class="labelStyle(input)" v-model="input.value">
                            <option v-for="(option, index) of input.optionValues" :key="index">
                                {{ option }}
                            </option>
                        </select>
                        <select v-if="isSelectOption(input.optionValues)" class="form-select" :class="labelStyle(input)" v-model="input.value">
                            <option :value="null">
                            </option>
                            <option v-for="(option, index) of getInputValuesAsSelectOption(input.optionValues)" :key="index" :value="option.id">
                                {{ option.name }}
                            </option>
                        </select>
                        <div v-if="input.isMissing" class="alertText">Bitte fülle das Feld {{ input.label }} aus</div>
                        <div v-if="input.errorMessage" class="alertText">{{input.errorMessage }}</div>
                    </div>
                    <div v-if="input.type == 'textarea'">
                        <label class="form-label" :class="labelStyle(input)">{{ input.label }}</label>
                        <textarea class="form-control"  :class="labelStyle(input)" v-model="input.value"></textarea>
                        <div v-if="input.isMissing" class="alertText">Bitte fülle das Feld {{ input.label }} aus</div>
                    </div>
                </template>
            </div>
          <div class="image Container" v-for="imageContainer of data.formModal.imageContainers" :key="imageContainer.id">
            <ImageListViewer :entry-id="imageContainer.entryId"
                             :file-ids="imageContainer.fileIds"
                             :module-name="imageContainer.moduleName"
                             @finish-upload="fileIdsUpdate($event, imageContainer)"
                             @finishRemove="fileIdsUpdate($event, imageContainer)"
                             @sortChanged="fileIdsUpdate($event, imageContainer, true)"
            />
          </div>
            <div class="actionBar">
                <template v-for="btn of data.formModal.buttons" :key="btn.id">
                    <button @click="buttonEvent(btn)" class="w-100 mt-2 btn " :class="'btn-'+btn.style">
                        <span v-if="btn.faIcon"><i :class="'fa-regular '+btn.faIcon"></i></span> {{ btn.label }} <span v-if="btn.isLoading"><i class="fa-regular fa-spinner fa-spin"></i></span>
                    </button>
                </template>
            </div>
        </div>


    </div>
</template>

<script setup lang="ts">
import { defineProps, onMounted, reactive } from 'vue';
import {FormButton, FormImageContainer, FormInputField, FormModal, SelectOption} from './FormModal';
import { useFormModalStore } from './state';
import ImageListViewer from "@/components/System/FileSystem/ImageListViewer.vue";

const data = reactive({
    formModal: {} as FormModal
})

const formStore = useFormModalStore();

const props = defineProps({
    formModal: Object
})

onMounted(() => {
    data.formModal = props.formModal as FormModal;
})

function isInputMissing() {
    let result = false
    for(const input of data.formModal.inputFields) {
        if(input.isMandatory && (input.value === "" || input.value == null || input.value === "Invalid date" || input.value === 0) ) {
            input.isMissing = true;
            result = true;
        } else {
            input.isMissing = false;
        }
    }
    return result
}

function buttonEvent(btn: FormButton) {
    if(!btn.ignoreMandatory && isInputMissing()) {
        return;
    }
    if(btn.isLoading) {
      return;
    }
    btn.isLoading = true

    btn.event();
}

const inputStyle = (input: FormInputField) => {
    if(input.isMissing || input.errorMessage) {
        return "alert-input"
    }
    return ""
}

const labelStyle = (input: FormInputField) => {
    if(input.isMissing) {
        return "alert-label"
    }
    return ""
   
}

function runLastBtnEvent () {
    if(data.formModal.buttons.length > 0) {
        const lastBtn = data.formModal.buttons[data.formModal.buttons.length-1]
        lastBtn.event()
    }
}

function closeForm() {
  if(!data.formModal.allowToClose) return
    formStore.closeForm(data.formModal)
}

function isStringOrNumber(value: number[]|string[]|SelectOption[]) {
    if(typeof value[0] == "string" || typeof value[0] == "number") {
        return true
    }
    return false
}

function isSelectOption(value: number[]|string[]|SelectOption[]) {
    if(typeof value == "object" && value[0] instanceof SelectOption) {

        return true
    }
    return false
}

function getInputValuesAsSelectOption(value: number[]|string[]|SelectOption[]): SelectOption[] {
    if(typeof value == "object" && value[0] instanceof SelectOption) {
        return value as SelectOption[]
    }
    return []
}

function fileIdsUpdate(fileIds: number[], imageContainer: FormImageContainer, save = false) {
    imageContainer.fileIds = fileIds
    if(save) {
      imageContainer.isChanged(fileIds)
    }
}

function autoSelect(event: any) {
  event.target.select()
}

</script>

<style lang="scss" scoped>
.background {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.3);
    z-index: 500;
    overflow: scroll;
}

.xClose {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    line-height: 0px;
}

.formContainer {
    max-width: 600px;
    background-color: rgb(244, 238, 238);
    border-radius: 15px;
    box-shadow: 8px 8px 20px 0px rgba(0,0,0,0.3);
}

.header {
    position: relative;
    background-color: #6c757d;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: rgb(244, 238, 238);;
}

.inputFields {
    padding: 5px;
}
.form-label {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

.actionBar {
    padding: 5px;
    padding-bottom: 10px;
}

.alertText  {
    color: red;
    font-size: 0.9rem;
    margin-left: 2px;
    font-weight: 300;
}

.alert-input {
    border: 1px solid #ff1515;
}

.alert-label {
    color: #ff1515;
}

p {
  margin: 0px;
}
</style>