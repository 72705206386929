<template>
    <div class="backgroundLoading" v-if="loadingStore.backgroundLoading.requestCounter > 0">
        <i class="fa-regular fa-spinner fa-spin fa-3x"></i>
    </div>
    <div class="overlayLoading" v-if="loadingStore.overlayLoading.requestCounter > 0">
        <div class="loadingContent">
            <div class="loading-logo">
                <div class="logo w-100">
                    <img :src="require('@/assets/logo/eilise-logo.svg')" />
                </div>
                <div class="spinner-container" :class="isSpinnerTop"> 
                    <i class="fa-regular fa-spinner fa-spin fa-3x text-primary"></i>
                </div>

            </div>
            <div class="progress mt-5" v-if="loadingStore.overlayLoading.maxRequest > 1">
                <div class="progress-bar bg-primary" role="progressbar" :style="'width: '+loadingPercentValue+'%'" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>

    </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useLoadingStore } from "./state";

const loadingStore = useLoadingStore();

const spinnerPosition = ref(false);

function runSpinnerPosition() {
    spinnerPosition.value = !spinnerPosition.value;
    setTimeout(() => {
        runSpinnerPosition();
    }, 1000)
}

runSpinnerPosition()

const isSpinnerTop = computed(() => {
    if(spinnerPosition.value) {
        return "spinner-container-top"
    }
    return ""
})

const loadingPercentValue = computed(() => {
    return (loadingStore.overlayLoading.maxRequest-loadingStore.overlayLoading.requestCounter) / loadingStore.overlayLoading.maxRequest * 100
})




</script>

<style lang="scss" scoped>
.backgroundLoading {
    position: fixed;
    left: 10px;
    bottom: 10px;
}

.overlayLoading {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.8);
    z-index: 1000;
    padding-top: 150px;
    color: white;
    text-align: center;
}

.loadingContent {
    width: 320px;
    margin: auto;
}

.loading-logo {
    position: relative;
}

img {
  filter: drop-shadow(2px 3px 5px #3caa3679)
}

.spinner-container {
    transition:all 1s ease;
    position: absolute;
    left: 33px;
    top: 70px;
}

.spinner-container-top {
    top: 40px;
}
</style>