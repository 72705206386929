<template>
    <div @dragenter.prevent="toggleActive" 
            @dragleave.prevent="toggleActive" 
            @dragover.prevent
            @drop.prevent="toggleActive"
            :class="{'active-dropzone': active}"
            class="dropzone" >
        <span>Dateien hier abglegen</span>
        <span class="text-muted">oder</span>
        <label for="dropzoneFile">Wähle eine Datei</label>
        <input type="file" id="dropzoneFile" class="dropzoneFile" multiple="true" />
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const active = ref(false)

const toggleActive = () => {
    active.value = !active.value;
}
</script>

<style lang="scss" scoped>
.dropzone {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
    border: 2px dashed #4d8643;
    background-color: #fff;
    transition: 0.3s ease all;
}

.active-dropzone {
    color: #fff;
    border-color: #fff;
    background-color: #4d8643;

    label {
        background-color: #fff;
        color: #4d8643;
    }
}

label {
    padding: 8px 12px;
    color: #fff;
    background-color: #4d8643;
    transition: .3s ease all;
}

input {
    display: none;
}

</style>