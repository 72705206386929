import {StyleColorTypeOutline} from "@/components/System/StyleColorType"
import {v4} from "uuid"
import { useFormModalStore } from "./state";

type FormInputTypes = "text"|"number"|"select"|"textarea"|"password"|"date"

export class FormModal {
    id = v4();
    header = ""
    buttons = [] as FormButton[]
    inputFields = [] as FormInputField[]
    imageContainers = [] as FormImageContainer[]
    descriptionFields = [] as FormDescriptionField[]
    allowToClose = true

    addButton(label: string,
                style: StyleColorTypeOutline = "outline-secondary",
                ignoreMandatory = false) {
        const btn = new FormButton();
        btn.label = label;
        btn.style = style
        btn.ignoreMandatory = ignoreMandatory

        this.buttons.push(btn);

        return btn;
    }

    addInput(label: string, 
            type: FormInputTypes = "text",
            preValue = "" as string|number|null,
            isMandatory = false,
            optionValues = null as string[]|number[]|SelectOption[]|null
            ) {
        const input = new FormInputField();
        input.label = label;
        input.type = type;
        input.value = preValue;
        input.optionValues = optionValues;
        input.isMandatory = isMandatory;
        this.inputFields.push(input);

        return input;
    }

    addDescription(description: string, label = "") {
        const descriptionItem = new FormDescriptionField();
        descriptionItem.label = label;
        descriptionItem.value = description;

        this.descriptionFields.push(descriptionItem);

        return descriptionItem;
    }

    addImageContainer(moduleName: string, entryId: number, existsFileIds: number[]) {
        const imageContainer = new FormImageContainer();
        imageContainer.entryId = entryId
        imageContainer.moduleName = moduleName
        imageContainer.fileIds = existsFileIds

        this.imageContainers.push(imageContainer);

        return imageContainer
    }

    close() {
        useFormModalStore().closeForm(this)
    }
}

export class FormButton {
    id = v4();
    label = "Abbrechen"
    isLoading = false;
    event = () => {
        //
    }
    faIcon = ""
    ignoreMandatory = false
    style: StyleColorTypeOutline = "outline-secondary"
    then(event = function() {
        //
    }) {
        this.event = event
    }
}

export class FormInputField {
    id = v4();
    type = "" as FormInputTypes
    label = ""
    value = "" as string|number|null
    optionValues = [] as string[]|number[]|SelectOption[]|null
    isMandatory = false
    isMissing = false
    errorMessage = ""
    buttons = [] as FormButton[]

    addButton(label: string,
              style: StyleColorTypeOutline = "outline-secondary",
              ignoreMandatory = false) {
        const btn = new FormButton();
        btn.label = label;
        btn.style = style
        btn.ignoreMandatory = ignoreMandatory

        this.buttons.push(btn);
        return btn;
    }

    setError(messageText: string) {
        this.errorMessage = messageText
    }

    clickEvent =  function() {
        //
    }
}

export class FormDescriptionField {
    id = v4();
    type = "" as FormInputTypes
    label = ""
    value = "" as string
    clickEvent =  function() {
        //
    }
}

export class SelectOption {
    id = 0
    name = ""
}

export class FormImageContainer {
    id= v4();
    entryId = 0
    moduleName = ""
    fileIds = [] as number[]

    isChanged = function(fileIds: number[]) {
        //
        fileIds
    }

    then(event = (fileIds: number[]) => {
        //
        fileIds
    }) {
        this.isChanged = event
    }

}