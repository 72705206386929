import { defineStore } from 'pinia'
import {Order, OrderFilter, OrderPosition, OrderPositionSummary, PossibleOrderType} from './Order';
import { useRequestStore } from '../System/Request';
import { useFormModalStore } from '../System/FormModal/state';
import moment from "moment/moment";
import {FormInputField, SelectOption} from "@/components/System/FormModal/FormModal";
import {Customer} from "@/components/Customer/Customer";
import {useLoadingStore} from "@/components/System/Loading/state";
import axios from "axios";
import settings from '@/components/System/FrontendSettings';

function addItemToList(list: Order[], newItems: Order[]|null) {
    if(!newItems) return list
    for(const newItem of newItems) {
        const foundIndex = list.findIndex(obj => obj.id === newItem.id);
        if(foundIndex <0) {
            list.push(newItem);
        }
    }
    return list
}

export const useOrderStore = defineStore('orderStore', {
    state: () => (
        { 
            focusOrder: null as Order|null,
            openOrderItems: [] as Order[],
            deliverOrderItems: [] as Order[],
            closedOrderItems: [] as Order[],
            orderFilter: null as OrderFilter|null,
            summaryPostionsOrders: [] as OrderPositionSummary[],
            lastSelectedWishDelivery: moment().format('YYYY-MM-DD'),
            lastSelectedStartDelivery: moment().format('YYYY-MM-DD'),
            lastSelectedEndDelivery: moment().add(24, 'month').format('YYYY-MM-DD')
        }
    ),
    actions: {
        addOrder(order: Order) {
            const requestStore = useRequestStore()
            const promise = new Promise<Order>((resolve) => {
                requestStore.post('api/v2/order/addOrder', order).then((data) => {
                    this.focusOrder = data as Order;
                    resolve(data as Order)
                })
            })
            return promise
        },
        updateOrder(order: Order) {
            const requestStore = useRequestStore()
            const promise = new Promise<Order>((resolve) => {
                requestStore.post('api/v2/order/updateOrder/'+ order.id, order).then((data) => {
                    resolve(data as Order)
                })
            })
            return promise
        },
        deleteOrder(order: Order) {
            const requestStore = useRequestStore()
            const promise = new Promise((resolve) => {
                requestStore.delete('api/v2/order/deleteOrder/'+ order.id).then((data) => {
                    resolve(data)
                })
            })
            return promise
        },
        getOrder(orderId: string) {
            const requestStore = useRequestStore()
            const promise = new Promise((resolve) => {
                requestStore.get('api/v2/order/getOrder/'+ orderId).then((data) => {
                    this.focusOrder = data as Order
                    resolve(data)
                })
            })
            return promise
        },
        getOrders(orderStatus: "delivery"|"open"|"closed", page = 1) {
            const requestStore = useRequestStore()
            const promise = new Promise((resolve) => {
                if(this.orderFilter === null) {
                    this.orderFilter = new OrderFilter();
                }
                requestStore.post('api/v2/order/getOrders/'+orderStatus+'/'+page, this.orderFilter).then((data) => {
                    if(orderStatus === "delivery") {
                        this.deliverOrderItems = addItemToList(this.deliverOrderItems, data as Order[])
                    }
                    if(orderStatus === "closed") {
                        this.closedOrderItems = addItemToList(this.closedOrderItems, data as Order[])
                    }
                    if(orderStatus === "open") {
                        this.openOrderItems = addItemToList(this.openOrderItems, data as Order[])
                    }
                    resolve(data)
                })
            })
            return promise
        },
        async exportOrder(orderStatus: PossibleOrderType = "delivery") {
            if(this.orderFilter === null) {
                this.orderFilter = new OrderFilter();
            }
            try {
                const response = await axios.post(settings.getRequestUrl('api/v2/order/export/'+orderStatus), this.orderFilter,{
                    responseType: 'blob'
                });
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', moment().format("YYYYMMDDHmm")+'-'+orderStatus+'.xlsx'); // Setzen Sie hier den gewünschten Dateinamen
                link.click();
                // Weiterer Code zum Verarbeiten der Antwort
            } catch (error) {
                console.error('Download fehlgeschlagen:', error);
            }
        },
        clearCache(orderStatus: "delivery"|"open"|"closed") {
            if(orderStatus === "delivery") {
                this.deliverOrderItems = []
            }
            if(orderStatus === "closed") {
                this.closedOrderItems = []
            }
            if(orderStatus === "open") {
                this.openOrderItems = []
            }
        },
        getCombinedOrders(orderStatus: "delivery"|"open"|"closed", deliveryData: string, saveInStore = true) {
            const requestStore = useRequestStore()
            const orderFilter = new OrderFilter()
            orderFilter.dateTime = deliveryData
            const promise = new Promise<OrderPositionSummary[]>((resolve) => {
                requestStore.post('api/v2/order/getCombinedOrders/'+orderStatus, orderFilter).then((data) => {
                    if(data && saveInStore) this.summaryPostionsOrders = data as OrderPositionSummary[]
                    if(!data) return
                    resolve(data as OrderPositionSummary[])
                })
            })
            return promise
        },
        /**
         * Gibt die Serienbestellung der jeweiligen Kindbestellung wieder
         */
        getSerialOrder(orderId: string|number) {
            const requestStore = useRequestStore()
            const promise = new Promise((resolve) => {
                requestStore. get('api/v2/order/getSerialOrder/'+ orderId).then((data) => {
                    this.focusOrder = data as Order
                    resolve(data)
                })
            })
            return promise
        },
        addPosition(position: OrderPosition, order = null as null|Order) {
            let usedOrder = order
            if(!usedOrder) {
                usedOrder = this.focusOrder
            }
            const promise = new Promise((resolve, reject) => {
                if(!usedOrder) {
                    reject("Keine focus Order gefunden");
                    return;
                }
                if(!usedOrder.orderPositions) {
                    usedOrder.orderPositions = [] as OrderPosition[]
                }
                usedOrder.orderPositions.push(position);

                this.updateOrder(usedOrder).then((order) => {

                    this.focusOrder = order as Order;
                    usedOrder = order as Order;
                    resolve(order)
                })
            })
            return promise as Promise<Order>
        },
        updateOrderPosition(orderPosition: OrderPosition, order = null as null|Order) {
            const promise = new Promise((resolve, reject) => {
                let usedOrder = order;
                if(!usedOrder) {
                    usedOrder = this.focusOrder
                }
                if(!usedOrder) {
                    reject("Weder eine Focus Order noch eine, über die Funktion, erhalten Order gefunden")
                    return;
                }

                const  index = usedOrder.orderPositions.findIndex(obj => obj.id == orderPosition.id)
                usedOrder.orderPositions[index] = orderPosition;
                this.updateOrder(usedOrder).then(() => {
                    resolve(usedOrder);
                })
            })
            return promise as Promise<Order>;
        },
        async removeOrderPosition(orderPosition: OrderPosition, order = null as null|Order) {
            const promise = new Promise<boolean>((resolve) => {
                let usedOrder = order;
                if(!usedOrder) {
                    usedOrder = this.focusOrder
                }
                if(!usedOrder) {
                    resolve(false)
                    return;
                }

                const requestStore = useRequestStore()
                requestStore.delete('api/v2/order/removeOrderPosition/'+ orderPosition.id).then(() => {
                    resolve(true)
                })

                const index = usedOrder.orderPositions.findIndex(obj => obj.id === orderPosition.id)
                usedOrder.orderPositions.splice(index, 1);
            })
            return promise as Promise<boolean>
        },
        checkAllIsDelivered(order = null as null|Order) {
            const promise = new Promise((resolve, reject) => {
                let usedOrder = order;
                if(!usedOrder) {
                    usedOrder = this.focusOrder
                }
                if(!usedOrder) {
                    reject("Weder eine Focus Order noch eine, über die Funktion, erhalten Order gefunden")
                    return;
                }
                const formModalStore = useFormModalStore();
                const form = formModalStore.addForm();
                const today = moment().format('YYYY-MM-DD');
                const deliveryDate = form.addInput('Lieferdatum', "date", today);
                form.addDescription("Wurden alle offenen Produkte geliefert?");

                for(const position of usedOrder.orderPositions) {
                    form.addDescription(position.amount + " x " +  position.product.name);
                }

                form.header = "Alles geliefert?"

                const confirmBtn = form.addButton('Ja, alles geliefert', 'outline-success');
                confirmBtn.faIcon = "fa-truck-ramp-couch"

                confirmBtn.then(() => {
                    if(!usedOrder) return
                    for(const order of usedOrder?.orderPositions) {
                        if(!order.deliveryDate) {
                            order.deliveryDate = deliveryDate.value as string;
                        }
                        this.updateOrder(usedOrder).then(() => {
                            resolve(usedOrder)
                        })
                    }
                    form.close();

                })
            })
            return promise as Promise<Order>
        },
        getDeliveryDateByOrder(order: Order) {
            let lastDeliveryDate = null
            for(const orderPosition of order.orderPositions) {
                if(!orderPosition.deliveryDate) {
                    return null
                }
                if(!lastDeliveryDate) {
                    lastDeliveryDate = orderPosition.deliveryDate
                    continue;
                }

                if(moment(lastDeliveryDate).diff(moment(orderPosition.deliveryDate)) < 0) {
                    lastDeliveryDate = orderPosition.deliveryDate;
                }
            }
            return lastDeliveryDate
        },
        openNewOrderEditor(customer: Customer) {
            const promise = new Promise<Order>((resolve, reject) => {
                const order = new Order()
                const formModalStore = useFormModalStore();
                const newOrderForm = formModalStore.addForm();
                newOrderForm.header = "Bestellung hinzufügen"

                if(customer) {
                    order.customer = customer;
                }

                const deliveryDate = newOrderForm.addInput("Wunschlieferdatum", 'date', this.lastSelectedWishDelivery, true)

                const btnSave = newOrderForm.addButton('Bestellung hinzufügen', 'success');

                btnSave.then(() => {
                    order.wishDeliveryDate = deliveryDate.value as string
                    this.lastSelectedWishDelivery = deliveryDate.value as string
                    order.isSerialChild = false
                    order.isSerialOrder = false
                    this.addOrder(order).then((responseOrder) => {
                        newOrderForm.close();
                        const newOrder = responseOrder as Order
                        resolve(newOrder)
                    }).catch(() => {
                        newOrderForm.close();
                        reject(false)
                    })
                })
            })
            return promise
        },
        openOrderEditor(order: Order) {
            const formModalStore = useFormModalStore();
            const promise = new Promise<Order|false>((resolve) => {
                const orderForm = formModalStore.addForm()

                orderForm.header = "Bestellung bearbeiten"

                let wishDeliveryDate = {} as FormInputField
                let firstDeliveryDate = {} as FormInputField
                let lastDeliveryDate = {} as FormInputField

                let deliveryDelay = {} as FormInputField

                if(!order.isSerialOrder) {
                    wishDeliveryDate = orderForm.addInput("Wunschlieferdatum", 'date', moment(order.wishDeliveryDate).format('Y-MM-DD'), true)
                } else {
                    firstDeliveryDate = orderForm.addInput("Erste Lieferung", 'date', moment(order.serialStartDate).format('Y-MM-DD'), true)
                    lastDeliveryDate = orderForm.addInput("Letzte Lieferung", 'date', moment(order.serialEndDate).format('Y-MM-DD'), true)

                    const intervalOptions = [] as SelectOption[];
                    const oneWeek = new SelectOption();
                    oneWeek.id = 1;
                    oneWeek.name = "Einmal pro Woche";
                    intervalOptions.push(oneWeek)

                    const twoWeek = new SelectOption();
                    twoWeek.id = 2;
                    twoWeek.name = "Alle zwei Wochen";
                    intervalOptions.push(twoWeek)

                    const threeWeek = new SelectOption();
                    threeWeek.id = 3;
                    threeWeek.name = "Alle drei Wochen";
                    intervalOptions.push(threeWeek)

                    const fourWeek = new SelectOption();
                    fourWeek.id = 4;
                    fourWeek.name = "Alle vier Wochen";
                    intervalOptions.push(fourWeek)

                    const fiveWeek = new SelectOption();
                    fiveWeek.id = 5;
                    fiveWeek.name = "Alle fünf Wochen";
                    intervalOptions.push(fiveWeek)

                    const sixWeek = new SelectOption();
                    sixWeek.id = 6;
                    sixWeek.name = "Alle sechs Wochen";
                    intervalOptions.push(sixWeek)

                    deliveryDelay = orderForm.addInput('Interval', "select", order.weekDelay, true, intervalOptions)
                }

                const deleteBtn = orderForm.addButton("Bestellung löschen", "outline-danger")

                deleteBtn.then(() => {
                    const deleteForm = formModalStore.addForm();
                    deleteForm.header = "Bestellung löschen"
                    deleteForm.addDescription("Bist du sicher, dass du die Bestellung unwiederruflich löschen möchtest?")

                    const confDeleteBtn = deleteForm.addButton("Ja, löschen", "danger");

                    confDeleteBtn.then(() => {
                        this.deleteOrder(order).then(() => {
                            orderForm.close()
                            deleteForm.close()
                            resolve(false)
                        })

                    })
                    orderForm.close();
                })

                const saveBtn = orderForm.addButton("Speichern", "success");

                saveBtn.then(() => {
                    if(!order) return
                    if(!order.isSerialOrder) {
                        order.wishDeliveryDate = wishDeliveryDate.value as string
                    } else {
                        order.serialStartDate = firstDeliveryDate.value as string
                        order.serialEndDate = lastDeliveryDate.value as string
                        order.weekDelay = deliveryDelay.value as number
                    }

                    if(moment(order.serialEndDate).diff(moment().add(3,'years')) > 0) {
                        lastDeliveryDate.setError("Maximal in 3 Jahre")
                        saveBtn.isLoading = false
                        return
                    }

                    this.updateOrder(order).then((updatedOrder) => {
                        resolve(updatedOrder as Order)
                        orderForm.close();
                    })

                })
            })
            return promise
        },
        openNewSerialOrderEditor(customer: Customer) {
            const promise = new Promise<Order>((resolve) => {
                const formModalStore = useFormModalStore();
                const loadingStore = useLoadingStore()

                const order = new Order()
                const newOrderForm = formModalStore.addForm();

                if(customer) {
                    order.customer = customer;
                }

                newOrderForm.header = "Serienbestellung hinzufügen"

                const firstDeliveryDate = newOrderForm.addInput("Erste Lieferung", 'date', this.lastSelectedStartDelivery, true)
                const lastDeliveryDate = newOrderForm.addInput("Letzte Lieferung", 'date', this.lastSelectedEndDelivery, true)

                const intervalOptions = [] as SelectOption[];
                const oneWeek = new SelectOption();
                oneWeek.id = 1;
                oneWeek.name = "Einmal pro Woche";
                intervalOptions.push(oneWeek)

                const twoWeek = new SelectOption();
                twoWeek.id = 2;
                twoWeek.name = "Alle zwei Wochen";
                intervalOptions.push(twoWeek)

                const threeWeek = new SelectOption();
                threeWeek.id = 3;
                threeWeek.name = "Alle drei Wochen";
                intervalOptions.push(threeWeek)

                const fourWeek = new SelectOption();
                fourWeek.id = 4;
                fourWeek.name = "Alle vier Wochen";
                intervalOptions.push(fourWeek)

                const fiveWeek = new SelectOption();
                fiveWeek.id = 5;
                fiveWeek.name = "Alle fünf Wochen";
                intervalOptions.push(fiveWeek)

                const sixWeek = new SelectOption();
                sixWeek.id = 6;
                sixWeek.name = "Alle sechs Wochen";
                intervalOptions.push(sixWeek)

                const deliveryDelay = newOrderForm.addInput('Interval', "select", null, true, intervalOptions)

                const btnSave = newOrderForm.addButton('Bestellung hinzufügen', 'success');

                btnSave.then(() => {
                    loadingStore.addLoading('overlay')
                    this.lastSelectedStartDelivery = firstDeliveryDate.value as string;
                    this.lastSelectedEndDelivery = lastDeliveryDate.value as string
                    order.wishDeliveryDate = firstDeliveryDate.value as string;
                    order.serialStartDate = firstDeliveryDate.value as string;
                    order.serialEndDate = lastDeliveryDate.value as string;
                    order.isSerialOrder = true;
                    order.weekDelay = deliveryDelay.value as number;

                    if(moment(order.serialEndDate).diff(moment().add(3,'years')) > 0) {
                        loadingStore.removeLoading('overlay')
                        lastDeliveryDate.setError("Maximal in 3 Jahre")
                        btnSave.isLoading = false
                        return
                    }

                    this.addOrder(order).then((data) => {
                        loadingStore.removeLoading('overlay')
                        resolve(data as Order);

                    })
                    newOrderForm.close();
                })
            })
            return promise
        },
        openSerialEditorWarning() {
            const promis = new Promise((resolve) => {
                const formModalStore = useFormModalStore()
                const form = formModalStore.addForm()
                form.header = "Serienbearbeitung!"

                form.addDescription("Achtung, du bist dabei eine Serienbestellung anzupassen. Alle individuellen Änderungen die für einzelne Bestellungen getätigt wurden, gehen dabei verloren.")
                form.addDescription("Bitte prüfe vorab, ob zukünftige einzelne Bestellung auf Basis dieses Serienauftrags vom vorherigen Standard abweichen.")
                form.addDescription("PS. In Zukunft wird Eilise diese Überprüfung für dich übernehmen")

                const cfmBtn = form.addButton('Ja, ich weiß was ich tue', 'outline-primary')
                cfmBtn.then(() => {
                    form.close()
                    resolve(true)
                })

            })
            return promis
        }
    }

})