import { Customer } from "../Customer/Customer";
import { Employee } from "../Employee/Employee";
import { Order, OrderPosition } from "../Order/Order";
import { Product } from "../Product/Product";
import moment from "moment";

export class Transaction {
    id = 0;
    description = ""
    created = ""
    updated = ""
    product = {} as Product
    amount = 0.0
    price = 0.0
    total = 0.0
    orderList = {} as Order
    orderPosition = {} as OrderPosition
    customer = {} as Customer
    creator = {} as Employee
    paymentMethod = {} as PaymentMethod
}

export class PaymentMethod {
    id =  1
    name = "Bar"
    faIcon = "money"
}

export class TransactionSearchSummary {
    total = ""
    paymentMethodId = 0
}

export class SearchRequest {
    searchString = ""
    page = 1;
    allItemFound = false
    toDate = moment().format('YYYY-MM-DD')
    fromDate = moment().format('YYYY')+"-01-01"
}