<template>
    <div class="card" @mouseleave="setVisableContactDetails(false)">
        <a href="#" @click="openEditUser" v-if="employeeState.isAllowed('Admin')">
                <div class="icon">
                    <i class="fa-regular fa-pen-to-square fa-xl"></i>
                </div>
            </a>
        <div class="d-flex flex-column align-items-center" v-if="data.farmMember.member"> 
                       <div class="bild mb-3">
            <img class="bild-circle" src="@/assets/placeholder/silhouette.png" />
            </div>
            <div class="nameHeader m-auto">
                <h5 class="">{{ data.farmMember.member.name }}</h5>
            </div>
        </div>
        
        <div class="info">
            <p v-if="!data.farmMember.hasInvitationAccepted" class="text-info">Einladung wurde noch nicht angenommen.</p>
            <p>{{ data.farmMember.description }}</p>
        </div>
        <div class="actionBar d-flex justify-content-center">
            <a href="tel:+4922664788352" 
                @click="setVisableContactDetails(true, 'tel:+4922664788352')"
                @mouseover="setVisableContactDetails(true, 'tel:+4922664788352')"
                >
                <div class="callIcon">
                    <i class="fa-regular fa-phone"></i>
                </div>
            </a>
            <a href="tel:+491627700498" 
                 @click="setVisableContactDetails(true, 'tel:+491627700498')"
                @mouseover="setVisableContactDetails(true, 'tel:+491627700498')"
                >
                <div class="callIcon">
                    <i class="fa-regular fa-mobile-button"></i>
                </div>
            </a>
            <a href="mailto:d.grabasch@gmx.de"                  
                @click="setVisableContactDetails(true, 'd.grabasch@gmx.de')"
                @mouseover="setVisableContactDetails(true, 'd.grabasch@gmx.de')">
                <div class="callIcon">
                    <i class="fa-regular fa-envelope"></i>
                </div>
            </a>
            
        </div>
        <div v-if="contactDetail.isVisable" class="contactDetails">
            {{contactDetail.info}}
        </div>
    </div>
</template>

<script lang="ts" setup>
import { reactive, defineProps, onMounted, onUpdated } from "vue";
import { useFormModalStore } from "../System/FormModal/state";
import { Employee, UserRoles } from "./Employee"
import { useEmployeeStore } from "./state";

const props = defineProps({
    farmMember: Object
})

const data = reactive({
    farmMember: {} as Employee
})

const employeeState = useEmployeeStore();

onMounted(() => {
    data.farmMember = props.farmMember as Employee
})

onUpdated(() => {
    data.farmMember = props.farmMember as Employee
})

const formModalStore = useFormModalStore();

const contactDetail = reactive({
    isVisable: false,
    info: ""
})

function setVisableContactDetails(status: boolean, infoText = "") {
    if(status) {
        contactDetail.info = infoText;
        contactDetail.isVisable = true;
    } else {
        contactDetail.isVisable = false
    }
}

function openEditUser() {
    const user = data.farmMember
    const form = formModalStore.addForm();
    form.header = user.member.name + " bearbeiten"

    const email = form.addInput("Email Adresse", "text", user.member.email, true);
    const firstName = form.addInput("Vorname", "text", user.member.firstName);
    const lastName = form.addInput("Nachname", "text", user.member.lastName);

    const description = form.addInput("Beschreibung", "textarea", user.description);
    const role = form.addInput("Rolle", "select", user.role[0], true, ['Admin', 'Mitarbeiter', 'Kunde']);

    form.addButton("Aus deiner Farm entfernen", "outline-danger",true).then(() => {
        const confirmDeleteForm = formModalStore.addForm();
        confirmDeleteForm.header = user.member.name + " entfernen";
        confirmDeleteForm.addDescription("Bist du sicher, dass du " + user.member.name +" wirklich entfernen möchtest? Der Nutzer hat keinen Zugriff mehr auf deine Farm.<br><br>Einträge die mit dem Nutzer in Verbindung stehen werden unter umständen anonymisiert.")

        confirmDeleteForm.addButton("Ja, löschen", "danger").then(() => {
            employeeState.removeEmployee(data.farmMember)
            confirmDeleteForm.close()
            form.close();
        })
    })

    if(!user.hasInvitationAccepted) {
        form.addButton("Einladung erneut senden", "outline-success").then(() => {
            form.close();
        })
    }

    form.addButton("Speichern", "success").then(() => {

        user.member.email = email.value as string;
        user.member.firstName = firstName.value as string;
        user.member.lastName = lastName.value as string;

        user.role = [];
        user.role.push(role.value as UserRoles);
        user.description = description.value as string

        employeeState.updateEmployee(user);
        form.close();
    })


}

</script>

<style lang="scss" scoped>
@import "./../../assets/style/variables.scss";
@import "./../../assets/style/employee-card.scss";

</style>