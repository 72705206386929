<template>
    <div v-if="customerCategoryStore.focusCategory">
        <div class="customerShadow headerElement">
            <h1>{{ customerCategoryStore.focusCategory.name }}</h1>
        </div>
        <div class="fastActionBar customerShadow">
            <div @click.stop>
                <div class="actionBar d-flex justify-content-center">
                    <a  
                        href="#"
                        @click="openCategoryEditor"
                        v-if="employeeState.isAllowed('Admin')">
                        <div class="callIcon">
                            <i class="fa-regular fa-gear"></i>
                        </div>
                    </a>

                </div>
                </div>
        </div>
        <ImageListViewer 
            :file-ids="customerCategoryStore.focusCategory.images"
            :module-name="'customerCategory'"
            :entry-id="customerCategoryStore.focusCategory.id"
            @start-upload="startUploadEvent"
            @finish-upload="finishUploadEvent"
            @start-remove="startRemoveEvent"
            @finish-remove="finishRemoveEvent"
            @sort-changed="saveImageIds"
             />
    </div>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { useCustomerCategoryStore } from "./store";
import ImageListViewer from "@/components/System/FileSystem/ImageListViewer.vue"
import { useLoadingStore } from "../System/Loading/state";
import { useEmployeeStore } from "../Employee/state";

const customerCategoryStore = useCustomerCategoryStore();
const loadingStore = useLoadingStore()
const employeeState = useEmployeeStore();
const route = useRoute();

onMounted(() => {
    customerCategoryStore.focusCategory = null

    customerCategoryStore.getCategory(parseInt(route.params.customerCategoryId as string))
})

function openCategoryEditor() {
    customerCategoryStore.openEditCategoryForm(customerCategoryStore.focusCategory)
}

function startUploadEvent() {
    loadingStore.addLoading('overlay')
}

function finishUploadEvent() {
    customerCategoryStore.getCategory(parseInt(route.params.customerCategoryId as string)).then(() => {
        loadingStore.removeLoading('overlay')
    })
}

function startRemoveEvent() {
    loadingStore.addLoading('overlay')  
}

function finishRemoveEvent() {
        customerCategoryStore.getCategory(parseInt(route.params.customerCategoryId as string)).then(() => {
        loadingStore.removeLoading('overlay')
    })
}

</script>