<template>
    <div v-if="customerStore.focusCustomer">
        <div class="customerShadow headerElement">
            <h1>{{ customerStore.focusCustomer.companyName }}</h1>
            <h2>{{ customerStore.focusCustomer.name }}</h2>
            <div v-if="customerStore.focusCustomer.categories">
                <div  class="d-flex mb-2"> 
                    <template v-for="category of customerStore.focusCustomer.categories" :key="category.id">
                        <div class="d-flex">
                            <div v-if="category.images && category.images.length > 0" class="smallImage m-auto ms-2">
                                <img :src="fileStore.getImagePath(category.images[0])" />
                            </div>
                            <div class="ms-2 m-auto">
                                {{ category.name }} <span class="pointer text-danger" @click="removeCategory(category)"><i class="fa-regular fa-x"></i></span>
                            </div>
                        </div>
                    </template>
                </div>
                <button class="btn btn-sm w-100 btn-outline-success" @click="addCategory">Kategorie verknüpfen</button>
            </div>
        </div>
        <div class="fastActionBar customerShadow">
            <div @mouseleave="setVisableContactDetails(false)" @click.stop>
                <div class="actionBar d-flex justify-content-center fa-3x">
                    <a  v-if="customerStore.focusCustomer.phoneNumber"
                        :href="'tel:'+customerStore.focusCustomer.phoneNumber" 
                        @click="setVisableContactDetails(true, customerStore.focusCustomer.phoneNumber, true)"
                        @mouseover="setVisableContactDetails(true, customerStore.focusCustomer.phoneNumber)"
                        class="m-1"
                        >
                        <div class="callIcon">
                            <i class="fa-regular fa-phone"></i>
                        </div>
                    </a>
                    <a  v-if="customerStore.focusCustomer.mobileNumber"
                        :href="'tel:'+customerStore.focusCustomer.mobileNumber" 
                        @click="setVisableContactDetails(true, customerStore.focusCustomer.mobileNumber, true)"
                        @mouseover="setVisableContactDetails(true, customerStore.focusCustomer.mobileNumber)"
                        class="m-1"
                        >
                        <div class="callIcon">
                            <i class="fa-regular fa-mobile-button"></i>
                        </div>
                    </a>
                    <a  v-if="customerStore.focusCustomer.email"
                        :href="'mailto:'+customerStore.focusCustomer.email"    
                        @click="setVisableContactDetails(true, customerStore.focusCustomer.email)"
                        @mouseover="setVisableContactDetails(true, customerStore.focusCustomer.email)"
                        class="m-1"
                        >
                        <div class="callIcon">
                            <i class="fa-regular fa-envelope"></i>
                        </div>
                    </a>
                    <a  v-if="customerStore.focusCustomer.addressString"
                        target="_blank"
                        :href="'http://maps.google.com/?q=' + customerStore.focusCustomer.addressString"    
                        @click="setVisableContactDetails(true, customerStore.focusCustomer.email)"
                        @mouseover="setVisableContactDetails(true, customerStore.focusCustomer.addressHTML)"
                        class="m-1">
                        <div class="callIcon">
                            <i class="fa-regular fa-location-dot"></i>
                        </div>
                    </a>
                    <a  
                        href="#" 
                        @mouseover="setVisableContactDetails(false, customerStore.focusCustomer.phoneNumber)"
                        @click="openEditCustomer" v-if="employeeState.isAllowed('Admin')"
                        class="m-1"
                        >
                        <div class="callIcon">
                            <i class="fa-regular fa-gear"></i>
                        </div>
                    </a>
                    <a
                        @mouseover="setVisableContactDetails(true, customerStore.focusCustomer.salesDescription)"
                        @click="customerStore.openCustomerDescription(customerStore.focusCustomer)" v-if="employeeState.isAllowed('Mitarbeiter')">
                      <div class="callIcon">
                    <span v-if="!customerStore.focusCustomer.salesDescription">
                      <i class="fa-regular fa-comment"></i>
                    </span>
                        <span v-if="customerStore.focusCustomer.salesDescription">
                        <i class="fa-regular fa-comment-lines"></i>
                    </span>
                      </div>
                    </a>
                <div class="ms-1 pointer" @click="changeSelected">
                  <span v-if="customerStore.focusCustomer.isSelected" class="text-primary">
                    <i class="fa-regular fa-toggle-on "></i>
                  </span>
                      <span v-else class="text-muted">
                    <i class="fa-regular fa-toggle-off"></i>
                  </span>
                </div>
                </div>
                    <div v-if="contactDetail.isVisable" class="contactDetails" v-html="contactDetail.info">

                    </div>
                </div>
        </div>
        <CustomerDetails :customer="customerStore.focusCustomer" />

    </div>  
</template>

<script lang="ts" setup>
import { useCustomerStore } from '@/components/Customer/state';
import { useEmployeeStore } from '@/components/Employee/state';
import { useFormModalStore } from '@/components/System/FormModal/state';
import {reactive, computed, onMounted, watch} from 'vue';
import { useRoute } from 'vue-router';
import { useCustomerCategoryStore } from '@/components/CustomerCategory/store';
import { CustomerCategory } from '@/components/CustomerCategory/CustomerCategory';
import { useFileSystemStore } from '@/components/System/FileSystem/state';
import CustomerDetails from "@/components/Customer/CustomerDetails.vue";
import {useUserStore} from "@/components/System/User/state";
import {Customer} from "@/components/Customer/Customer";

const customerStore = useCustomerStore();
const employeeState = useEmployeeStore();
const formModalStore = useFormModalStore();

const customerCategoryStore = useCustomerCategoryStore();
const fileStore = useFileSystemStore();
const route = useRoute();


const contactDetail = reactive({
    isVisable: false,
    info: ""
})

onMounted(() => {
  customerStore.focusCustomer = null
/*  transactionList.value = null;*/
  customerStore.getCustomer(parseInt(route.params.customerId as string)).then(() => {
    // updateTransactionList();
  })
})

function setVisableContactDetails(status: boolean, infoText = "", setFollowMe = false) {
  if(!customerStore.focusCustomer) return
  if(setFollowMe) useUserStore().setFollowMe('customer/'+customerStore.focusCustomer.id)
    if(status) {
        contactDetail.info = infoText;
        contactDetail.isVisable = true;
    } else {
        contactDetail.isVisable = false
    }
}

function openEditCustomer() {
    if(!customerStore.focusCustomer) return
    customerStore.openCustomerEditor(customerStore.focusCustomer)
}

const getBalanceStyle = computed(() => {
    if(!customerStore.focusCustomer || !customerStore.focusCustomer.balance) return ""
    if(customerStore.focusCustomer.balance.total < 0) {
        return "text-danger";
    }
    return ""
})

function addCategory() {
  if(!customerStore.focusCustomer) return
    customerCategoryStore.openCategorySelector(customerStore.focusCustomer.categories).then((data) => {
      if(!customerStore.focusCustomer) return
        if(data) {
            if(!customerStore.focusCustomer.categories) {
                customerStore.focusCustomer.categories = [] as CustomerCategory[]
            }
            customerStore.focusCustomer.categories.push(data as CustomerCategory)
        }
        customerStore.updateCustomer(customerStore.focusCustomer)
    })
}

function removeCategory(category: CustomerCategory) {
    const form = formModalStore.addForm();
    form.header = "Kategorie entfernen";

    form.addDescription("Bist du sicher, dass du die Kategorie " + category.name + " von Kunden entfernen möchtest? Du kannst die Kategorie später wieder hinzufügen")

    const btn = form.addButton("Ja, sicher", "outline-danger")
    btn.then(() => {
        form.close();
        if(!customerStore.focusCustomer) return
        const index = customerStore.focusCustomer.categories.findIndex(obj => obj.id == category.id)
        customerStore.focusCustomer.categories.splice(index,1)
        customerStore.updateCustomer(customerStore.focusCustomer)
    })
}

function changeSelected() {
  if(!customerStore.focusCustomer) return
  customerStore.focusCustomer.isSelected = !customerStore.focusCustomer.isSelected;
  customerStore.updateCustomer(customerStore.focusCustomer)
}

</script>

<style lang="scss" scoped>

.customerShadow {
    -webkit-box-shadow: 2px 1px 21px -5px rgba(0,0,0,0.2); 
    box-shadow: 2px 1px 21px -5px rgba(0,0,0,0.2);
}

.headerElement {
    border-radius: 10px;
    background-color: rgb(243, 243, 243);
    padding: 4px;
    margin-bottom: 10px;
}

.fastActionBar {
    background-color: rgb(231, 230, 230);
    border-radius: 10px;
}

.orderItem {
    border-radius: 10px;
    background-color: rgb(243, 243, 243);
    padding: 4px;
    margin-bottom: 10px;
}

.balanceContainer {
    font-size: 1.2rem;
}

.smallImage {
    max-width: 50px;
    border-radius: 5px;
}
.smallImage img {
    width: 100%;
     border-radius: 5px;
}
</style>