<template>
  <div>
  <h5>Kasse | Transaktionen</h5>
    <div>
      <p>
      Guthaben von {{ customer.name }} beträgt <b><FinanceSpan :value="balance" /></b>
      </p>
    </div>

    <TransactionList
        :transaction-items="transactionItems" @transactionAdded="transactionAdded"
        :description-pre-value="'Bezahlung bei Auslieferung'"
      :customer="customer" />
  </div>
</template>

<script lang="ts" setup>

import {PropType, defineProps, defineEmits, computed} from "vue";
import {Customer} from "@/components/Customer/Customer";
import {Transaction} from "@/components/Transaction/Transaction";
import TransactionList from "@/components/Transaction/TransactionList.vue";
import FinanceSpan from "@/components/Finance/FinanceSpan.vue";

const props = defineProps({
  customer: Object as PropType<Customer>,
  transactionItems: Object as PropType<Transaction[]>,
  balance: Number
})

const emits = defineEmits(['transactionAdded'])

function transactionAdded(transaction: Transaction) {
  emits("transactionAdded", transaction)
}

</script>