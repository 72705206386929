<template>
  <div class="background" @click="closeModal">
    <div class="container m-auto mt-5" @click.stop>
      <div class="header text-center p-2 mb-3" v-if="possibleToClose">
        <div class="xClose"  @click="closeModal">
          <i class="fa-regular fa-xmark"></i>
        </div>
      </div>
      <div>
        <CustomerCategorySelectList @isCategorySelect="isCategorySelect" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineEmits, defineProps } from "vue"
import CustomerCategorySelectList from "@/components/CustomerCategory/CustomerCategorySelectList.vue";
import {CustomerCategory} from "@/components/CustomerCategory/CustomerCategory";

const props = defineProps({
  possibleToClose: {
    type: Boolean,
    default: true
  }
})

const emits = defineEmits(['isCategorySelect', 'isClose'])

function isCategorySelect(category: CustomerCategory) {
  emits('isCategorySelect', category)
}

function closeModal() {
  if(!props.possibleToClose) {
    return
  }
  emits('isClose')
}
</script>

<style lang="scss" scoped>

.background {
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.3);
  z-index: 500;
  overflow: scroll;
}

.container {
  position: relative;
  margin-top: 15px;
  background-color: rgb(255,255,255);
  padding: 10px;
  border-radius: 10px;
}

.xClose {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  line-height: 0px;
}
</style>