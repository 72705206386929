<template>
  <div class="m-auto mt-5 text-center fa-2x">
    <div class="mb-5">
      Du kannst jetzt mit einem weiteren Gerät Kunden anrufen. Dieses Gerät folgt deine Kundenanrufe.<br>
    </div>
    <div>
      <i class="fa-regular fa-spinner fa-spin fa-2x text-primary"></i>
    </div>
    <div class="mt-5">
      Warte auf Anforderung deines zweiten Gerätes ...
    </div>
  </div>
</template>
<script setup lang="ts">

</script>