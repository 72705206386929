import { defineStore } from 'pinia'

import { useRequestStore } from '@/components/System/Request';
import { useFormModalStore } from "@/components/System/FormModal/state"
import { Customer } from './Customer';
import { CustomerCategory } from '../CustomerCategory/CustomerCategory';
import {reactive} from "vue";
import {useDeviceSettingStore} from "@/components/System/DeviceSetting/deviceSettingStore";
import {OrderFilter, PossibleOrderType} from "@/components/Order/Order";
import axios from "axios";
import settings from "@/components/System/FrontendSettings";
import moment from "moment";


interface CustomerSearchRequest {
    searchString: string,
    categoryId: CustomerCategory|null
    selected: boolean|null
}


export const useCustomerStore = defineStore('customerStore', {
    state: () => (
        {
            backloadCustomers: [] as Customer[],
            showFurtherCustomerBtn: true,
            renderAmount: 0,
            customerItems: [] as Customer[],
            focusCustomer: {} as Customer|null,
            searchItem: {
                searchString: "",
                categoryId: null,
                selected: null,
            } as CustomerSearchRequest,
            listMode: "grid" as "grid"|"list"
        }
    ),
    actions: {
        getAllCustomers() {
            this.searchItem.searchString = ""
            this.getCustomers();
        },
        changeListMode(value: "grid"|"list") {
            this.renderAmount = 0;
            this.customerItems = []
            this.listMode = value
            this.renderCustomer()
        },
        getCustomers(searchString = null as string | null){
            if (searchString) {
                this.searchItem.searchString = searchString
            }

            const requestStore = useRequestStore();

            this.renderAmount = 0;
            const promise = new Promise((resovle) => {
                requestStore.post('api/v2/customer/find', this.searchItem).then((data) => {
                    this.backloadCustomers = data as Customer[]
                    this.customerItems = []
                    if(!useDeviceSettingStore().setting.dataSaveMode) {
                        this.getPreLoadCustomer(0,10);
                    }
                    this.renderCustomer();

                    resovle(data);
                })
            })
            return promise;
        },
        renderCustomer() {
            const startIndex = this.renderAmount
            this.showFurtherCustomerBtn = true
            this.renderAmount += 30;
            if(this.backloadCustomers.length == 0)  {
                return;
            }
            for (let i = startIndex; i < this.renderAmount; i++) {
                if(this.backloadCustomers[i]) {
                    const customer = this.backloadCustomers[i]
                    this.customerItems.push(customer)
                } else {
                    this.showFurtherCustomerBtn = false
                    return;
                }
            }
        },
        async exportCustomer() {
            try {
                const response = await axios.post(settings.getRequestUrl('api/v2/customer/export'), this.searchItem,{
                    responseType: 'blob'
                });
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', moment().format("YYYYMMDDHmm")+'-customer.xlsx'); // Setzen Sie hier den gewünschten Dateinamen
                link.click();
                // Weiterer Code zum Verarbeiten der Antwort
            } catch (error) {
                console.error('Download fehlgeschlagen:', error);
            }
        },
        addCustomer(customer: Customer) {
            const requestStore = useRequestStore();
            const promise = new Promise((resovle) => {
                requestStore.post('api/v2/customer/addCustomer', customer).then((data) => {
                    this.customerItems.push(data as Customer)
                    resovle(data)
                })
            })
            return promise;
        },
        getCustomer(customerId: number, storeAsFocusCustomer = true) {
            const requestStore = useRequestStore();
            const customer = this.customerItems.find(obj => obj.id == customerId)
            if(customer) {
                customer.isLoading = true
                this.storeUpdate(customer)
            }

            const promise = new Promise((resovle) => {
                requestStore.get('api/v2/customer/getCustomer/' + customerId).then((data) => {
                    const customer = data as Customer
                    customer.isDetailLoaded = true
                    customer.isLoading = false
                    if (storeAsFocusCustomer) {
                        this.setFocusCustomer(data as Customer)
                    }
                    this.storeUpdate(data as Customer)
                    resovle(data as Customer)
                })
            })
            return promise
        },
        async selectAllCustomer(value: boolean) {
            const promise = new Promise((resovle) => {
            const requestStore = useRequestStore()
            const form = useFormModalStore().addForm();
            form.header = "Schnellauswahl";
            let direction = "abwählen";
            if(value) direction = "auswählen";
            form.addDescription("Bist du sicher, dass du alle Kunden " + direction + " möchtest?")
            form.addDescription("Alle deiner Kunden dieser Farm, werden geändert.")

            const btn = form.addButton("Ja, bitte alle " + direction);
            btn.then(() => {
                    requestStore.get('api/v2/customer/selectAll/' + value).then(async  () => {
                        await this.getCustomers();
                        form.close()
                        resovle(true)
                    })
                })
            })
            return promise
        },
        /**
         * Ermöglicht das Vorladen von Kunden details. Auf basis der vorabgeladenen Kunden
         * @param index
         * @param amount
         */
        getPreLoadCustomer(index: number, amount = 10) {
            const customerIds = [] as number[]
            for ( let i=index; i<=(amount+index); i++ ) {
                if(this.backloadCustomers[i]) {
                    const customer = this.backloadCustomers[i];
                    if(!customer.isDetailLoaded) {

                        customerIds.push(customer.id)
                        customer.isLoading = true;
                        this.storeUpdate(customer)
                    }
                }
            }
            if(customerIds.length == 0) return
            const requestStore = useRequestStore();
            const promise = new Promise((resovle) => {
                requestStore.post('api/v2/customer/getCustomersDetailByIds', {customerIds: customerIds}).then((data) => {
                    for(const customer of data as Customer[]) {
                        customer.isDetailLoaded = true;
                        customer.isLoading = false
                        this.storeUpdate(customer as Customer)
                    }
                    resovle(data as Customer[])
                })
            })
            return promise
        },
        updateCustomer(customer: Customer) {
            const requestStore = useRequestStore();
            const promise = new Promise((resovle) => {
                const request = requestStore.post('api/v2/customer/updateCustomer/' + customer.id, customer)
                request.then(() => {
                    const index = this.customerItems.findIndex(obj => obj.id == customer.id)
                    this.customerItems[index] = customer
                    resovle(customer)
                })
            })
            return promise as Promise<Customer>;
        },
        deleteCustomer(customer: Customer) {
            const requestStore = useRequestStore();
            const promise = new Promise((resovle) => {
                const request = requestStore.delete('api/v2/customer/deleteCustomer/' + customer.id)
                const index = this.customerItems.findIndex(obj => obj.id == customer.id);
                this.customerItems.splice(index, 1);
                request.then(() => {
                    resovle(true)
                })
            })
            return promise;
        },
        storeUpdate(customer: Customer) {
          const index = this.customerItems.findIndex(obj => obj.id == customer.id)
          this.customerItems[index] = customer;
            const backindex = this.backloadCustomers.findIndex(obj => obj.id == customer.id)
            this.backloadCustomers[backindex] = customer;
        },
        setFocusCustomer(customer: Customer) {
            this.focusCustomer = customer
        },
        openCustomerEditor(customer: Customer) {
            const formModalHolder = useFormModalStore();
            const promise = new Promise((resolve) => {
                const form = formModalHolder.addForm();
                if (customer.id == 0) {
                    form.header = "Kunden hinzufügen"
                } else {
                    form.header = customer.name;
                }

                const companyName = form.addInput("Firmename", "text", customer.companyName)
                const firstName = form.addInput("Vorname", "text", customer.firstName)
                const lastName = form.addInput("Nachname", "text", customer.lastName)
                const email = form.addInput("Email", "text", customer.email)
                const phoneNumber = form.addInput("Telefonnummer", "text", customer.phoneNumber)
                const mobileNumber = form.addInput("Handynummer", "text", customer.mobileNumber)
                const street = form.addInput("Straße", "text", customer.street)
                const houseNo = form.addInput("Hausnummer", "text", customer.houseNo)
                const streetAdd = form.addInput("Straßen zusatz", "text", customer.streetAdd)
                const postcode = form.addInput("PLZ", "text", customer.postcode)
                const town = form.addInput("Stadt", "text", customer.town)
                const state = form.addInput("Bundesland", "select", customer.state, false, [
                    "Baden-Württemberg",
                    "Bayern",
                    "Berlin",
                    "Brandenburg",
                    "Bremen",
                    "Hamburg",
                    "Hessen",
                    "Mecklenburg-Vorpommern",
                    "Niedersachsen",
                    "Nordrhein-Westfalen",
                    "Reihnald-Pfalz",
                    "Saarland",
                    "Sachsen-Anhalt",
                    "Sachsen",
                    "Schleswig-Holstein",
                    "Thüringen"
                ])
                /*
                const country = form.addInput("Land", "select", data.customer.country, false, [
                    'Germany'
                ])
                */
                if (customer.id != 0) {
                    const deleteBtn = form.addButton("Löschen", "outline-danger");
                    deleteBtn.faIcon = "fa-trash"
                    deleteBtn.then(() => {
                        const deleteConfirmForm = formModalHolder.addForm();
                        deleteConfirmForm.addDescription("Bist du sicher, dass du den Kunden " + customer.name + " löschen?");
                        deleteConfirmForm.header = customer.name + " löschen?"
                        const confirmedBtn = deleteConfirmForm.addButton("Ja, löschen", 'danger');
                        confirmedBtn.then(() => {
                            form.close();
                            deleteConfirmForm.close();
                            this.deleteCustomer(customer);
                        })
                    })
                }


                const saveBtn = form.addButton("Speichern", "success")
                saveBtn.then(() => {
                    customer.companyName = companyName.value as string
                    customer.firstName = firstName.value as string
                    customer.lastName = lastName.value as string
                    customer.email = email.value as string
                    customer.phoneNumber = phoneNumber.value as string
                    customer.mobileNumber = mobileNumber.value as string
                    customer.street = street.value as string
                    customer.houseNo = houseNo.value as string
                    customer.streetAdd = streetAdd.value as string
                    customer.state = state.value as string
                    customer.postcode = postcode.value as string
                    customer.town = town.value as string
                    if (customer.id != 0) {
                        this.updateCustomer(customer).then(() => {

                            this.getCustomer(customer.id).then((dataResponse) => {
                                form.close()
                                resolve(dataResponse)
                            })
                        })
                    } else {
                        this.addCustomer(customer).then((dataResponse) => {
                            form.close()
                            resolve(dataResponse)
                        })
                    }


                })
                saveBtn.faIcon = "fa-floppy-disk"
            })

            return promise as Promise<Customer>
        },
        openCustomerDescription(customer: Customer) {
            const formModalStore = useFormModalStore();
            const promise = new Promise((resolve) => {
                const form = formModalStore.addForm();
                form.header = "Kundennotiz"

                form.addDescription("Hier kannst du Gesprächsnotizen oder Hinweise zum Kunden notieren.")
                const description = form.addInput("Deine Notiz", "textarea", customer.salesDescription);

                const deliveryNote = form.addButton("Lieferhinweise", "outline-info");
                deliveryNote.then(() => {
                    this.openDeliveryNotes(customer)
                    form.close()
                })

                const btnDelete = form.addButton("Notiz leeren", "outline-danger")

                btnDelete.then(() => {
                    const noteDelete = formModalStore.addForm();
                    noteDelete.header = "Notiz leeren?"
                    noteDelete.addDescription("Bist du sicher, dass du die Notiz unwiederruflich leeren möchtest.")
                    noteDelete.addButton("Ja, leeren", "danger").then(() => {
                        customer.salesDescription = "";
                        this.updateCustomer(customer).then((data) => {
                            resolve(data)
                        })
                        noteDelete.close();
                        form.close()
                    })

                })

                const btn = form.addButton("Speichern", "success")
                btn.then(() => {
                    customer.salesDescription = description.value as string;
                    this.updateCustomer(customer).then((data) => {
                        resolve(data)
                    })
                    form.close()
                })
            })
            return promise as Promise<Customer>
        },
        openDeliveryNotes(customer: Customer) {
            const formModalStore = useFormModalStore();
            const promise = new Promise((resolve) => {
                const form= formModalStore.addForm();
                form.header = "Lieferhinweise";
                form.addDescription("Hier kannst du Lieferhinweise hinzufügen. Möchte der Kunde vielleicht, dass die Ware vor die Garage gelegt wird, kannst du das hier notieren und mit Fotos die Information einfacher teilen")

                const description = form.addInput("Hinweise für die Auslieferung", "textarea", customer.deliveryDescription)

                const imageElement = form.addImageContainer("customerDelivery", customer.id, customer.deliveryImages)
                imageElement.then(() => {
                    customer.deliveryImages = imageElement.fileIds
                    this.updateCustomer(customer)
                })

                form.addButton("Speichern", "success").then(() => {
                    customer.deliveryDescription = description.value as string
                    customer.deliveryImages = imageElement.fileIds
                    this.updateCustomer(customer)
                    resolve(customer)
                    form.close()
                })

            })

            return promise as Promise<Customer>
        }
    }
})