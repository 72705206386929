<template>
    <div class="card"  @click="isClicked" v-if="customer">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <!-- <div class="icon" v-if="employeeState.isAllowed('Admin')">
              <span @click="openEditProduct" class="text-primary">
                <i class="fa-regular fa-pen-to-square fa-xl"></i>
              </span>
            </div> -->
            <div class="icon" v-if="employeeState.isAllowed('Admin')">
              <span @click.stop @click="openEditCustomer" class="text-primary">
                <i class="fa-regular fa-pen-to-square fa-xl"></i>
              </span>
            </div>
          </div>  
          <template v-if="customer?.deliveryImages && customer.deliveryImages.length > 0">
            <img :src="fileSystemStore.getImagePath(customer.deliveryImages[0])" class="bild-circle" :alt="customer.name"/>
          </template> 

        <div class="nameHeader m-auto">
            <h4 class="" v-if="customer.companyName">{{ customer.companyName }}</h4>
            <h5 class="" v-if="customer.name">{{ customer.name }}</h5>
          <div v-if="customer.categories" class="categoryContainer" :class="getContainerClasses">
            <template v-for="category of customer.categories" :key="category.id">
              <div v-if="category.images && category.images.length > 0">
                <img :src="fileSystemStore.getImagePath(category.images[0])" :alt="category.name" />
              </div>
              <div v-else>
                <span class="p-2 fa-lg">{{ category.name }}</span>
              </div>

            </template>
          </div>
            <div >
                <div  class="d-flex mb-2"> 

                </div>
            </div>
        </div>
        <!-- <hr /> -->
        <div @mouseleave="setVisableContactDetails(false)" @click.stop>
        <div v-if="!minimalizationMode" class="actionBar d-flex justify-content-center">
            <a  v-if="customer.phoneNumber"
                :href="'tel:'+customer.phoneNumber" 
                @click="setVisableContactDetails(true, customer.phoneNumber, true)"
                @mouseover="setVisableContactDetails(true, customer.phoneNumber)"
                >
                <div class="callIcon">
                    <i class="fa-regular fa-phone"></i>
                </div>
            </a>
            <a  v-if="customer.mobileNumber"
                :href="'tel:'+customer.mobileNumber" 
                 @click="setVisableContactDetails(true, customer.mobileNumber, true)"
                @mouseover="setVisableContactDetails(true, customer.mobileNumber)"
                >
                <div class="callIcon">
                    <i class="fa-regular fa-mobile-button"></i>
                </div>
            </a>
            <a  v-if="customer.email"
                :href="'mailto:'+customer.email"    
                @click="setVisableContactDetails(true, customer.email)"
                @mouseover="setVisableContactDetails(true, customer.email)">
                <div class="callIcon">
                    <i class="fa-regular fa-envelope"></i>
                </div>
            </a>
            <a  v-if="customer.addressString"
                target="_blank"
                :href="'http://maps.google.com/?q=' + customer.addressString"    
                @click="setVisableContactDetails(true, customer.email)"
                @mouseover="setVisableContactDetails(true, customer.addressHTML)">
                <div class="callIcon">
                    <i class="fa-regular fa-location-dot"></i>
                </div>
            </a>
            <a
                @mouseover="setVisableContactDetails(false, customer.phoneNumber)"
                @click="openEditCustomer" v-if="employeeState.isAllowed('Admin')">
                <div class="callIcon">
                    <i class="fa-regular fa-gear"></i>
                </div>
            </a>
            <a
                @mouseover="setVisableContactDetails(true, customer.salesDescription)"
                @click="openCustomerDescription" v-if="employeeState.isAllowed('Mitarbeiter')">
              <div class="callIcon">
                  <span v-if="!customer.salesDescription">
                    <i class="fa-regular fa-comment"></i>
                  </span>
                  <span v-if="customer.salesDescription">
                      <i class="fa-regular fa-comment-lines"></i>
                  </span>
              </div>
            </a>

        </div>
            <div v-if="contactDetail.isVisable" class="contactDetails" v-html="contactDetail.info">

            </div>
            <div @click.stop class="m-2 ">
                <!-- <button @click="openCustomer" class="btn btn-outline-primary w-100">Kunden öffnen</button> -->
                <div v-if="showMover" class="d-flex"> 
                    <button  @click="move('up')" class="btn btn-outline-secondary w-100 mt-1 me-1"><i class="fa-regular fa-arrow-up"></i> Hoch</button>
                    <button  @click="move('down')" class="btn btn-outline-secondary w-100 mt-1 ms-1"><i class="fa-regular fa-arrow-down"></i> Runter</button>
                </div>
                <button v-if="showSelector" @click="isSelect" class="btn btn-outline-primary w-100 mt-1">Auswählen</button>
                <button v-if="showRemover" @click="isRemove" class="btn btn-outline-danger w-100 mt-1">Entfernen</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {onMounted, reactive, defineProps, defineEmits, PropType, computed} from 'vue';
import { Customer } from './Customer';
import { useEmployeeStore } from '../Employee/state';
import { useCustomerStore } from './state';
import { useRouter } from 'vue-router';
import { useFileSystemStore } from '../System/FileSystem/state';
import {useUserStore} from "@/components/System/User/state";

const employeeState = useEmployeeStore();
const customerStore = useCustomerStore()
const router = useRouter();
const fileSystemStore = useFileSystemStore();
const emits = defineEmits(['isClicked', 'isSelect', 'isRemove', 'moveDown', 'moveUp'])

const contactDetail = reactive({
    isVisable: false,
    info: ""
})

const props = defineProps({ 
    customer: Object as PropType<Customer>,
        showSelector: {
        default: false,
        type: Boolean
    },
    showRemover: {
        default: false,
        type: Boolean
    },
    showMover: {
        default: false,
        type: Boolean
    },
    minimalizationMode: {
        default: false,
        type: Boolean
    }
})

function setVisableContactDetails(status: boolean, infoText = "", setFollowMe = false) {
  if(!props.customer) return
    if(setFollowMe) useUserStore().setFollowMe('customer/'+props.customer.id)
    if(status) {
        contactDetail.info = infoText;
        contactDetail.isVisable = true;
    } else {
        contactDetail.isVisable = false
    }
}

function isClicked() {
    emits('isClicked', props.customer)
}

function isSelect() {
    emits('isSelect', props.customer)
}

function isRemove() {
    emits('isRemove', props.customer)
}

function move(direction: "up"|"down") {
    if(direction == "down") {
        emits("moveDown", props.customer);
    } else {
        emits("moveUp", props.customer);
    }
}

function openEditCustomer() {
    if(!props.customer) return
    customerStore.openCustomerEditor(props.customer).then(() => {
      //
    })
}

function openCustomer() {
    if(!props.customer) return
    router.push({name: "customer", params: { customerId: props.customer.id }})
}

function openCustomerDescription() {
  if(!props.customer) return
  customerStore.openCustomerDescription(props.customer)
}

/*
function getContainerClasses(value: string) {
    if(props.minimalizationMode === true) {
      return ""
    }
    return " categoryContainerPadding"
})
 */

const getContainerClasses = computed(() => {
    if(props.minimalizationMode === true) {
      return ""
    }
    return " categoryContainerPadding"
})

</script>

<style lang="scss" scoped>
@import "./../../assets/style/variables.scss";
@import "./../../assets/style/customer-card.scss";

.categoryContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.categoryContainer img {
  width: 4rem;
}

.categoryContainerPadding {
  padding-bottom: 50px;
}

</style>