<template>
  <div v-if="me.member" class="text-center">
    <h1>Hallo {{me.member.name}},</h1>

    <p>Du bist der Farm <b>{{ farmStore.activeFarm.name }}</b> noch nicht aktiv beigetreten.</p>

    <div>
      <button class="btn btn-outline-success me-1" @click="answer(true)">Einladung annehmen</button>
      <button class="btn btn-outline-danger ms-1" @click="answer(false)">Einladung ablehnen</button>
    </div>

    <UserFarmsList />
  </div>
</template>

<script setup lang="ts">
import {useEmployeeStore} from "@/components/Employee/state";
import {computed} from "vue";
import {useFarmStore} from "@/components/Farm";
import UserFarmsList from "@/components/System/User/UserFarmsList.vue";
import {useFormModalStore} from "@/components/System/FormModal/state";

const employeeStore = useEmployeeStore()
const farmStore = useFarmStore()

const me = computed(() => {
  return employeeStore.me
})

function answer(accept: boolean) {
  const form = useFormModalStore().addForm()
  form.header = "Antwort auf die Einladung"
  let message = "Bist du sicher, dass du die Einladung für die Farm <b>" + farmStore.activeFarm.name + "</b> ablehnen möchtest? <br>Es wäre sehr schade. :(";
  if(accept) {
    message = "Super, dass du der Farm <b>" + farmStore.activeFarm.name + "</b> beitreten möchtest";
  }
  form.addDescription(message)
  if(accept) {
    const acceptBtn = form.addButton('Einladung annehmen','outline-success')

    acceptBtn.then(() => {
      employeeStore.me.hasInvitationAccepted = true
      employeeStore.updateEmployee(employeeStore.me).then(() => {
        form.close()
      })
    })
  } else {
    const declineBtn = form.addButton('Einladung ablehnen','outline-danger')
    declineBtn.then(() => {
      employeeStore.removeEmployee(employeeStore.me).then(() => {
        form.close()
      })
    })
  }


}
</script>

<style scoped lang="scss">

</style>