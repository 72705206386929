<template>
  <div v-if="showResetter && filter" class="mt-2">
    <h5>Filter</h5>
    <div @click="removeProductFilter" class="btn btn-outline-secondary btn-sm me-1" v-if="filter.product">
      <span class="pointer" >
        <i class="fa-regular fa-x fa-sm"></i>
      </span>
      Produkt: {{ filter.product.name }}
    </div>
    <div @click="removeCustomerCategoryFilter" class="btn btn-outline-secondary btn-sm me-1" v-if="filter.customerCategory">
      <span class="pointer" >
        <i class="fa-regular fa-x fa-sm"></i>
      </span>
      Kundenkategorie: {{ filter.customerCategory.name }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import {useOrderStore} from "@/components/Order/state";
import {computed, defineEmits} from "vue";

const orderStore = useOrderStore();
const emits = defineEmits(['resetFilter'])

const showResetter = computed(() => {
  if(filter.value == null) return
  if(filter.value.product != null) return true
  if(filter.value?.customerCategory != null) return true
  return false
})

const filter = computed(() => {
  return orderStore.orderFilter
})

function resetFilter() {
  emits("resetFilter")
}

function removeProductFilter() {
  if(orderStore.orderFilter == null) return
  orderStore.orderFilter.product = null
  resetFilter()
}

function removeCustomerCategoryFilter() {
  if(orderStore.orderFilter == null) return
  orderStore.orderFilter.customerCategory = null
  resetFilter()
}
</script>