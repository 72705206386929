<template>
<div></div>
</template>

<script setup lang="ts">
import {computed, onMounted, ref, watch} from "vue";
import * as moment from "moment/moment";
import {useLoadingStore} from "@/components/System/Loading/state";
import {useUserStore} from "@/components/System/User/state";
import {useRouter} from "vue-router";
import {useDeliveryRouteStore} from "@/components/Delivery/state";
import {Farm, useFarmStore} from "@/components/Farm";
import {useFormModalStore} from "@/components/System/FormModal/state";
import {User} from "@/components/System/User/User";

const loadingStore = useLoadingStore()
const userStore = useUserStore();
const farmStore = useFarmStore();
const router = useRouter()
const formStore = useFormModalStore();
const deliveryRouteStore = useDeliveryRouteStore()

function delayedRemoveLoading() {
  setTimeout(() => {
    loadingStore.removeLoading("overlay")
  }, 500);
}

const isObserverRun = ref(false)
const isDetected = ref(false)


onMounted(() => {
  loadingStore.init()
  loadingStore.addLoading("overlay", 1)
  moment.locale('de');
  userStore.getMe().then((data) => {
    delayedRemoveLoading()
    if(data === false && router.currentRoute.value.name != "resetPassword") {
      router.push({name: 'login'})
    } else {
      userStore.startApplication().then(() => {
        deliveryRouteStore.init();
      })
    }
    //delayedRemoveLoading()
    if(router.currentRoute.value.name != "resetPassword" && !isObserverRun.value) {
      isObserverRun.value = true
      setTimeout(checkUser, 20000)
    }

  })
})

function checkUser() {
  userStore.getMe().then((data) => {
    setTimeout(checkUser, 60000)
    const resData = data as User|false
    if(resData === false && router.currentRoute.value.name != "login" && !isDetected.value) {
      openNewLogin()
      isDetected.value = true
    }
    if(resData === false || !resData.activeFarm) return
    if(resData.activeFarm.id !== farmStore.activeFarm.id && !isDetected.value) {
      changeActiveFarm(resData.activeFarm, farmStore.activeFarm)
      isDetected.value = true
    }
  })
}


function openNewLogin() {
  const form = formStore.addForm("none");
  form.header = "Abgemeldet"
  form.addDescription("Du wurdest abgemeldet. Möchtest du dich neu anmelden?")
  const btn = form.addButton("Neu anmelden")
  btn.then(() => {
    isDetected.value = false
    form.close()
    if(router.currentRoute.value.name != "resetPassword") {
      router.push({name: 'login'})
    }

  })
}


function changeActiveFarm(farmOne: Farm, farmTwo: Farm) {
    const form = formStore.addForm("none")
    form.header = "Andere Aktive Farm"
    form.addDescription("Eine andere aktive Farm wurde erkannt. Möglicherweise, hast du dich mit einem anderen Gerät angemeldet und hast dort die aktive Farm geändert.")
  form.addDescription("Mit welcher Farm möchtest du fortfahren?")
    const btnOne = form.addButton(farmOne.name)
    btnOne.then(() => {
      isDetected.value = false
      farmStore.setActiveFarm(farmOne);
    })
    const btnTwo = form.addButton(farmTwo.name)
  btnTwo.then(() => {
    isDetected.value = false
    farmStore.setActiveFarm(farmTwo);
  })
}

</script>


<style scoped lang="scss">

</style>