<template>
    <div class="background">
        <div class=" m-auto">
            <template v-for="alert of alertStore.alertItems" :key="alert.id">
                <div class="alert mb-2" :class="'alert-'+alert.type">
                    {{ alert.text }}
                </div>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useAlertStore } from "./state"

const alertStore = useAlertStore();

</script>

<style lang="scss" scoped>
.background {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    width: 100%;
    z-index: 1500;
}
</style>