<template>
    <div>
        <h1>Der Hof</h1>
        <p>Alle Einstellung zu deiner Farm</p>
        <div>
            <label class="form-label">Dein Hof/Farmname</label>
            <input class="form-control"  v-model="data.editFarm.name" />
            <div class="d-flex justify-content-end mt-2">
                <button @click="saveFarm" class="btn btn-success">Speichern</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Farm, useFarmStore } from "@/components/Farm";
import { onMounted, reactive } from "vue";

const farmStore = useFarmStore();

const data = reactive({
    editFarm: {} as Farm
})

onMounted(() => {
    data.editFarm = farmStore.activeFarm;
    farmStore.getFarm().then(() => {
        data.editFarm = farmStore.activeFarm;
    })

}) 

function saveFarm() {
    farmStore.saveFarm(data.editFarm);
}

</script>