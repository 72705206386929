<template>
    <div class="card" v-if="route" @click="isClicked" >
        <div class="nameHeader m-auto">
            <h5 >{{ route.name }}</h5>
        </div>
        <div> 
            {{ route.description }}
        </div>
        <div @click.stop>
            <div class="actionBar d-flex justify-content-center">
                <a
                    @click="openEditor" v-if="employeeState.isAllowed('Admin')">
                    <div class="callIcon">
                      <i class="fa-regular fa-pen-to-square fa-2x"></i>
                    </div>
                </a>
                <a
                    @click="startDeliveryMode" v-if="employeeState.isAllowed('Mitarbeiter') && enablePlayBtn">
                    <div class="callIcon ms-4">
                        <i class="fa-regular fa-play fa-2x"></i>
                    </div>
                </a>
            </div>
        </div>
      <div>
        <div>
          <button @click="openRoute" v-if="employeeState.isAllowed('Admin')" class="button">Route bearbeiten</button>
        </div>
      </div>
    </div>
</template>

<script setup lang="ts">
import { PropType, defineProps, defineEmits } from "vue";
import { DeliveryRoute } from "./DeliveryRoute";
import { useEmployeeStore } from "../Employee/state";
import { useDeliveryRouteStore } from "./state";
import { useFormModalStore } from "../System/FormModal/state";
import {useRouter} from "vue-router";
import moment from "moment";
import {useLoadingStore} from "@/components/System/Loading/state";
import {SelectOption} from "@/components/System/FormModal/FormModal";

const employeeState = useEmployeeStore();
const deliveryRouteStore = useDeliveryRouteStore();
const formModalStore = useFormModalStore();
const loadingStore = useLoadingStore()
const router = useRouter()

const props = defineProps({
    route: Object as PropType<DeliveryRoute>,
    enablePlayBtn: {
      type: Boolean,
      default: false
    }
})

const emits = defineEmits(['isClicked'])

const openEditor = () => {
    if(props.route) deliveryRouteStore.openRouteFormEditor(props.route)
}

const startDeliveryMode = () => {
    if(!props.route) return
    const form = formModalStore.addForm();
    form.header = props.route.name + " - Lieferung starten";
    form.addDescription("Bist du sicher, dass du die Lieferung starten möchtest? Dein Gerät wird anschließend in den Auslieferungsmodus versetzt.");
    //const deliveryDate = form.addInput("Auslieferungstag", "date", moment().format("YYYY-MM-DD"), true)

    const options = [] as SelectOption[];
    const bestOption = new SelectOption()
  bestOption.id = 1
  bestOption.name = "Optimiert";
  options.push(bestOption)
    const tableOption = new SelectOption()
  tableOption.id = 2
    tableOption.name = "Tabellenmodus";
    options.push(tableOption)

    const template = form.addInput("Ansichtsmodus", "select", "1",true, options)

    const btn = form.addButton("Ja, starten", "outline-success")
    btn.then(() => {
      loadingStore.addLoading('overlay')
      if(!props.route) return

      let choiceTemplate = null as "table"|null
      if(template.value == 2) {
        choiceTemplate = "table"
      }

      deliveryRouteStore.startDeliveryMode(props.route, moment().format("YYYY-MM-DD"), choiceTemplate).then(() => {
        loadingStore.removeLoading('overlay')
        if(choiceTemplate == "table") {
          router.push('deliveryTableMode')
        } else {
          router.push('deliveryActiveMode')
        }

      })
      form.close()
    })
     
}

const isClicked = () => {
    emits("isClicked", props.route)
}

function openRoute() {
  if(!props.route) return
  router.push({name: "deliveryRoute", params: { deliveryRouteId: props.route.id}})
}

</script>

<style lang="scss" scoped>
@import "./../../assets/style/variables.scss";
@import "./../../assets/style/card.scss";

h5 {
    color: #3EAB38;
    margin-top: 10px;
}


.button {
    background-color:#3EAB38; 
    color: white;
    width: 10rem;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #3EAB38;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
}

.button:hover {
        transform: scale(1.05);
        background-color: white; 
        color:#3EAB38; 
        }


</style>