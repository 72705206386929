import { defineStore } from 'pinia'
import { Product, ProductSearchRequest } from './Product';
import { useRequestStore } from '../System/Request';
import { useFormModalStore } from '../System/FormModal/state';

export const useProductStore = defineStore('productStore', {
    state: () => (
        { 
            foundProductItems: [] as Product[],
            focusProduct: {} as Product
        }
    ),
    actions: {
        getProducts(searchString = "") {
            const searchRequest = {
                searchString: searchString
            } as ProductSearchRequest
            const requestStore = useRequestStore();
            const promise = new Promise((resovle) => {
                requestStore.post('api/v2/product/find', searchRequest).then((data) => {
                    this.foundProductItems = data as Product[]
                    resovle(data)
                })
            })
            return promise;
        },
        addProduct(product: Product) {
            const requestStore = useRequestStore();
            const promise = new Promise((resovle) => {
                requestStore.post('api/v2/product/addProduct', product).then((data) => {
                    this.focusProduct = data as Product
                    this.foundProductItems.push(this.focusProduct)
                    resovle(data)
                })
            })
            return promise;
        },
        getProduct(productId: number) {
            const requestStore = useRequestStore();
            const promise = new Promise((resovle) => {
                requestStore.get('api/v2/product/getProduct/'+ productId).then((data) => {
                    this.focusProduct = data as Product
                    resovle(data)
                })
            })
            return promise;
        },
        updateProduct(product: Product) {
            const requestStore = useRequestStore();
            const promise = new Promise((resovle) => {
                requestStore.post('api/v2/product/updateProduct/'+ product.id, product).then((data) => {
                    this.focusProduct = data as Product
                    resovle(data)
                })
            })
            return promise;
        },
        deleteProduct(product: Product) {
            const requestStore = useRequestStore();
            const promise = new Promise((resovle) => {
                const request = requestStore.delete('api/v2/product/deleteProduct/'+product.id)
                const index = this.foundProductItems.findIndex(obj => obj.id == product.id);
                this.foundProductItems.splice(index, 1);
                request.then(() => {
                    resovle(true)
                })
            })
            return promise;
        },
        openProductEditor(product: Product) {
            const formModalHolder = useFormModalStore();
            
            const promise = new Promise((resolve) => {
                const form = formModalHolder.addForm();
                if(product.id == 0) {
                    form.header = "Produkt hinzufügen"
                } else {
                    form.header = "Produkt bearbeiten";
                }

                const name = form.addInput('Produktname', 'text', product.name, true);
                const price = form.addInput('Preis', 'number', product.price);
                const description = form.addInput('Beschreibung', 'textarea', product.description)

                if(product.id != 0) {
                    const deleteBtn = form.addButton("Löschen", "outline-danger");
                    deleteBtn.faIcon = "fa-trash"
                    deleteBtn.then(() => {
                        const deleteConfirmForm = formModalHolder.addForm();
                        deleteConfirmForm.addDescription("Bist du sicher, dass das Produkt " + product.name + " löschen?");
                        deleteConfirmForm.header = product.name + " löschen?"
                        const confirmedBtn = deleteConfirmForm.addButton("Ja, löschen", 'danger');
                        confirmedBtn.then(() => {
                            form.close();
                            deleteConfirmForm.close();
                            this.deleteProduct(product);
                        })
                    })
                }


                const saveBtn = form.addButton("Speichern", "success")
                saveBtn.faIcon = "fa-floppy-disk";
                saveBtn.then(() => {

                    product.name = name.value as string
                    product.price = price.value as number
                    product.description = description.value as string

                    if(product.id != 0) {
                        this.updateProduct(product).then(() => {
                            resolve(product);
                            form.close()
                        })
                    } else {
                        this.addProduct(product).then(() => {
                            resolve(product)
                            form.close()
                        })
                    }

                })
            })
            return promise;
        },
        setFocusProduct(product: Product) {
            this.focusProduct = product
        }
    }
})