<template>
  <div class="card no-transform w-100">
    <div class="card-header d-flex justify-content-between">
      <div class="mt-auto mb-auto"><i class="fa-regular fa-car fa-lg"></i> Dein Kofferaum</div>
    </div>
      <div v-for="position of startProducts" :key="position.id">
        <div class="d-flex justify-content-start productInfo">
          <div>
            <template v-if="position.product?.images && position.product.images.length > 0">
              <img :src="fileSystemStore.getImagePath(position.product.images[0])" :alt="position.product.name" />
            </template>
          </div>
          <div class="vertical-align m-auto"> {{ position.product.name }} </div>
          <div class="vertical-align m-auto"> {{ getCurrentStore(position.product) }} / {{ position.amount }} stk</div>
        </div>
        <hr />
      </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, onMounted} from "vue";
import {useFileSystemStore} from "@/components/System/FileSystem/state";
import {RoutePoint} from "@/components/Delivery/DeliveryRoute";
import {useDeliveryRouteStore} from "@/components/Delivery/state";
import {Product} from "@/components/Product/Product";
import {OrderPosition} from "@/components/Order/Order";

const fileSystemStore = useFileSystemStore();
const deliveryStore = useDeliveryRouteStore()

onMounted(() => {
  if(!deliveryStore.activeDeliveryRouteStart) return
  combineProducts(deliveryStore.activeDeliveryRouteStart.runningRoute)
})

function combineProducts(routePointItems: RoutePoint[]) {
  const productList = [] as OrderPosition[]
  for(const point of routePointItems) {
    for(const order of point.deliveryOrders) {
      for(const position of order.orderPositions) {
        const index = productList.findIndex(obj => obj.product.id === position.product.id)
        if(index < 0) {
         const updatedPosition = new OrderPosition()
          updatedPosition.product = position.product
          updatedPosition.amount = parseFloat(position.amount.toString())
          productList.push(updatedPosition)
        } else {
          productList[index].amount += parseFloat(position.amount.toString())
        }
      }
    }
  }
  return productList
}

const currentProducts = computed(() => {
  console.log("CURRENT")
  if(!deliveryStore.activeDeliveryRoute) return [] as OrderPosition[]
  return combineProducts(deliveryStore.activeDeliveryRoute.runningRoute)
})

const startProducts = computed(() => {
  console.log("Start")
  console.log(deliveryStore.activeDeliveryRouteStart)
  if(!deliveryStore.activeDeliveryRouteStart) return [] as OrderPosition[]
  return combineProducts(deliveryStore.activeDeliveryRouteStart.runningRoute)
})

function getCurrentStore(product: Product) {
  console.log("UPDATE")
  const foundProduct = currentProducts.value.find(obj => obj.product.id == product.id)
  if(foundProduct) {
    return foundProduct.amount
  }
  return 0
}

</script>

<style lang="scss" scoped>
.productInfo {
  height: 100px;
}

.productInfo img{
  width: 100px;  /*width of parent container*/
  height: 100px; /*height of parent container*/
  object-fit: contain;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>