<template>
    <div class="background" @click="closeModal">
        <div class="container m-auto mt-5" @click.stop>
            <div class="header text-center p-2 mb-3" v-if="possibleToClose">
                <div class="xClose"  @click="closeModal">
                    <i class="fa-regular fa-xmark"></i>
                </div>
            </div>
            <div> 
                <ProductSelectList @isProductSelect="isProductSelect" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Product } from './Product';
import ProductSelectList from './ProductSelectList.vue';
import { defineEmits, defineProps } from "vue"

const props = defineProps({
    possibleToClose: {
        type: Boolean,
        default: true
    }
})

const emits = defineEmits(['isProductSelect', 'isClose'])

function isProductSelect(product: Product) {
    emits('isProductSelect', product)
}

function closeModal() {
    if(!props.possibleToClose) {
        return
    }
    emits('isClose')
}
</script>

<style lang="scss" scoped>

.background {
    top: 0;
    left: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.3);
    z-index: 500;
    overflow: scroll;
}

.container {
    position: relative;
    margin-top: 15px;
    background-color: rgb(255,255,255);
    padding: 10px;
    border-radius: 10px;
}

.xClose {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    line-height: 0px;
}
</style>