<template>
    <h1>Lieferroute bearbeiten <span @click="openEditDeliveryRoute" class="text-primary pointer"><i class="fa-regular fa-gear"></i></span></h1>
    <div v-if="deviceWarning" class="alert alert-warning">
      {{deviceWarning}}  <span v-if="showReloadBtn"><button @click="reloadPage" class="btn btn-outline-dark">Neu laden</button></span>
    </div>
    <div v-if="deliveryRouteStore.focusRoute">
        <h3>{{ deliveryRouteStore.focusRoute.name }} </h3>
        <div class="text-center">
            <p>{{ deliveryRouteStore.focusRoute.description }}</p>
        </div>
        <div>
          <CustomerSearch />
        </div>
        <div class="row">
            <div v-if="customerStore.customerItems" class="customerList col-6">
                <div class=" mb-2" v-for="customer of customerStore.customerItems" :key="customer.id">
                    <template v-if="!isUsed(customer)">
                        <CustomerCard :minimalizationMode="true" :customer="customer" :showSelector="true" @isSelect="openRoutePointSelector" />
                    </template>
                </div>
              <div class="text-center m-2" v-if="customerStore.showFurtherCustomerBtn">
                <button @click="customerStore.renderCustomer()" class="btn btn-primary">Weitere Kunden laden</button>
              </div>
            </div>
            <div v-if="customerStore.customerItems" class="customerPointList col-6">
                <div class=" mb-2" v-for="routePoint of deliveryRouteStore.focusRoute.route" :key="routePoint.customer.id">
                  <template v-if="isInSearch(routePoint.customer)">
                    <CustomerCard :minimalizationMode="true" :customer="routePoint.customer" :showRemover="true" @isRemove="removeCustomerFromRoute" :showMover="true" @moveUp="moveUpRoutePoint" @moveDown="moveDownRoutePoint" />
                  </template>
                </div>
            </div>
        </div>
      <RoutePointChangeList v-if="isShowRoutePointSelector"
                            :info-text="'Nach welchem Kunden möchtest du den Eintrag anreihen?'"
                            :route-points="deliveryRouteStore.focusRoute.route"
                            :isCheckmark="false"
                            @close="closeRoutePointSelector"
                            @selectRoutePoint="addCustomerToRoute"
                            @to-end="addCustomerToRoute('end')"
                            @to-start="addCustomerToRoute('start')"
                            :show-base-position="true"

      />
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useCustomerStore } from "../Customer/state";
import CustomerCard from "../Customer/CustomerCard.vue";
import { useDeliveryRouteStore } from "./state";
import { useRoute } from "vue-router";
import CustomerSearch from "../Customer/CustomerSearch.vue";
import { Customer } from "../Customer/Customer";
import {RoutePoint} from "@/components/Delivery/DeliveryRoute";
import RoutePointChangeList from "@/components/Delivery/DeliveryElement/RoutePointChangeList.vue";

const deviceWarning = ref("");
const showReloadBtn = ref(false)
const customerStore = useCustomerStore();
const route = useRoute();
const deliveryRouteStore = useDeliveryRouteStore();

const isShowRoutePointSelector = ref(false)
const focusCustomer = ref<Customer|null>(null)

onMounted(() => {

    const docWidth = document.documentElement["clientWidth"];
  console.log(docWidth)
    if(docWidth < 500) {
        deviceWarning.value = "Dieses Modul ist nicht für ein Smartphone erstellt. Bitte wechsel dein Gerät.";
        return
    }
    if(docWidth < 680) {
        deviceWarning.value = "Bitte drehe dein Tablet und lade die Seite neu";
        showReloadBtn.value = true
    }

    deliveryRouteStore.getRoute(route.params.deliveryRouteId as string).then((data) => {
      console.log(data)
    })
    
})

function moveUpRoutePoint(customer: Customer) {
    deliveryRouteStore.moveRoutePoint(customer, "up")
}

function moveDownRoutePoint(customer: Customer) {
    deliveryRouteStore.moveRoutePoint(customer, "down")
}

function openRoutePointSelector(customer: Customer) {
  focusCustomer.value = customer;
  isShowRoutePointSelector.value = true;
}

/**
 *
 * @param afterCustomer nach welchem Kunden soll der neue Kunde hinzugefügt werden?
 */
function addCustomerToRoute(afterRoutePoint: RoutePoint|'end'|'start') {
    if(!focusCustomer.value) return
    if(afterRoutePoint == 'start' || afterRoutePoint == 'end') {
      deliveryRouteStore.addRoutePoint(focusCustomer.value, afterRoutePoint);
    } else {
      deliveryRouteStore.addRoutePoint(focusCustomer.value, afterRoutePoint.customer);
    }
    closeRoutePointSelector()
}

function removeCustomerFromRoute(customer: Customer) {
    deliveryRouteStore.removeRoutePoint(customer);
}

function openEditDeliveryRoute() {
    if(!deliveryRouteStore.focusRoute) return
    deliveryRouteStore.openRouteFormEditor(deliveryRouteStore.focusRoute)
}

function isUsed(customer: Customer) {
    if(!deliveryRouteStore.focusRoute) return
    return deliveryRouteStore.focusRoute.route.find(obj => obj.customer.id == customer.id)
}

function isInSearch(customer: Customer) {
  const foundCustomer = customerStore.backloadCustomers.find(obj => obj.id == customer.id)
  if(foundCustomer) {
    return true
  }
  return  false
}

function closeRoutePointSelector() {
  focusCustomer.value = null
  isShowRoutePointSelector.value = false
}

function reloadPage() {
  window.location.reload(true)
}
</script>

<style lang="scss" scoped>
.customerList {
    border-right: solid 1px;
}
</style>