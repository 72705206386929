<template>
  <tr :class="rowStyle" v-if="routePoint">
    <td>
      <template v-if="routePoint.customer.phoneNumber">
        <a :href="'tel:'+routePoint.customer.phoneNumber">
          <i class="fa-regular fa-phone fa-lg"></i>
        </a>
      </template>
      <template v-if="routePoint.customer.mobileNumber">
        <a :href="'tel:'+routePoint.customer.mobileNumber">
          <i class="fa-regular fa-mobile-button fa-lg"></i>
        </a>
      </template>
    </td>
    <td>
      <span>{{routePoint.customer.name}}</span>
      <span v-if="routePoint.customer.addressString" class="ms-1">
        <a target="_blank"
           :href="'http://maps.google.com/?q=' + routePoint.customer.addressString">
          <i class="fa-regular fa-location-dot fa-lg"></i>
        </a>
      </span>
    </td>
    <td>
      <div class="d-flex">
          <span @click="changeAmount" class="pointer mt-auto mb-auto">
           <i class="fa-regular fa-pen-to-square fa-lg"></i>
        </span>
        <span class="mt-auto ms-2 mb-auto" v-if="firstDeliveryPositions.length > 0">
          <template v-for="postion of firstDeliveryPositions" :key="postion.id">
            {{ postion.amount }} <em>x</em> {{ postion.product.name }}<br>
          </template>
          </span>
        <span @click="openOrderList" class="pointer ms-2 mt-auto mb-auto">
           <i class="fa-regular fa-circle-info fa-lg"></i>
        </span>
      </div>

    </td>
    <td>
      <FinanceSpan :value="routePoint.customerBalance.total" />
      <span @click="openTransactionList" class="pointer ms-2 mt-auto mb-auto">
           <i class="fa-regular fa-circle-info fa-lg"></i>
      </span>
    </td>
    <td>
      <span v-if="firstDeliveryOrder" class="me-2 pointer" @click="allIsDelivered">
        <i class="fa-regular fa-truck-ramp-box fa-lg"></i>
      </span>
      <span class="pointer"  @click="openCashRegister">
        <i class="fa-regular fa-cash-register fa-lg"></i>
      </span>
    </td>
  </tr>
  <ProductSelectModal v-if="isProductSelectorOpen" @isClose="closeProductSelector" @isProductSelect="tryToAddProductToOrder" />
  <EmptyModal class="normal-Size" v-if="showInModal && routePoint" @backgroundClicked="closeEmptyModal" @close-clicked="closeEmptyModal">
    <DetailedOrderList v-if="showInModal=='oldOrder'" :order-items="routePoint.closedOrders" />
    <TransactionList v-if="showInModal=='transaction'" :transaction-items="routePoint.customerTransactions" />
  </EmptyModal>
</template>

<script setup lang="ts">
import {computed, defineProps, defineEmits, PropType, ref, onMounted} from "vue"
import {RoutePoint} from "@/components/Delivery/DeliveryRoute";
import FinanceSpan from "@/components/Finance/FinanceSpan.vue";
import {Order, OrderPosition} from "@/components/Order/Order";
import {useFormModalStore} from "@/components/System/FormModal/state";
import {FormInputField, FormModal} from "@/components/System/FormModal/FormModal";
import {useOrderStore} from "@/components/Order/state";
import {Product} from "@/components/Product/Product";
import ProductSelectModal from "@/components/Product/ProductSelectModal.vue"
import {useAlertStore} from "@/components/System/Alert/state";
import moment from "moment";
import {useTransactionStore} from "@/components/Transaction/state";
import {Transaction} from "@/components/Transaction/Transaction";
import EmptyModal from "@/components/System/EmptyModal.vue"
import DetailedOrderList from "@/components/Order/DetailedOrderList.vue";
import {useDeliveryRouteStore} from "@/components/Delivery/state";
import TransactionList from "@/components/Transaction/TransactionList.vue";
import {prop} from "vue-class-component";
import {useLoadingStore} from "@/components/System/Loading/state";

const props = defineProps({
  routePoint: Object as PropType<RoutePoint>
})

const emits = defineEmits(['updatedOrder'])

const formModalStore = useFormModalStore();
const orderStore = useOrderStore()
const alertStore = useAlertStore();
const transactionStore = useTransactionStore()
const deliveryRouteStore = useDeliveryRouteStore()
const loadingStore = useLoadingStore();

const isProductSelectorOpen = ref(false)

const currentFormModal = ref<FormModal|null>(null)
const currentChangeInputs = ref<FormPosition[]|null>(null)
const newOrderElement = ref<Order|null>(null)
const showInModal = ref<null|"transaction"|"oldOrder">(null)

interface FormPosition {
  position: OrderPosition,
  input: FormInputField
}

onMounted(() => {
  if(!props.routePoint) return
  //deliveryRouteStore.updateRoutePoint(props.routePoint.customer)
})

function changeAmount() {
  const changeForm = formModalStore.addForm();
  changeForm.header = "Bestellung ändern"
  const inputFields = [] as FormPosition[]
  if(!props.routePoint) return
  if(firstDeliveryOrder.value == null) {
    addNewOrder();
  } else {
    newOrderElement.value = null
  }
  for(const position of firstDeliveryPositions.value) {
    const value = {
      position: position,
      input: changeForm.addInput(position.product.name, "number", position.amount)
    }


    const dltBtn = value.input.addButton("X", 'outline-danger');
    dltBtn.then(() => {
      dltBtn.isLoading = false
      const confirmModal = formModalStore.addForm()
      confirmModal.header = position.product.name + " entfernen?"
      confirmModal.addDescription("Bist du sicher, dass du " + position.product.name + " aus der Bestellung entfernen möchtest?")
      confirmModal.addButton('Ja, entfernen', 'outline-danger').then(() => {
        removePosition(position)
        confirmModal.close()
        changeForm.close()
      })

    })

    inputFields.push(value)
  }

  const newProductBtn = changeForm.addButton('Produkt hinzufügen', 'outline-success');
  currentChangeInputs.value = inputFields
  currentFormModal.value = changeForm

  newProductBtn.then(() => {
    newProductBtn.isLoading = false
    openProductSelector()
  })

  const saveBtn = changeForm.addButton("Speichern", "success")

  saveBtn.then(() => {
    if(newOrderElement.value) {
      orderStore.addOrder(newOrderElement.value).then((newOrder) => {
        console.log(newOrder)
        updatePositionsToBackend(newOrder)
      })
    } else {
      updatePositionsToBackend();
    }

    changeForm.close()
  })
}

function updatePositionsToBackend(order = null as Order|null) {
  if(!currentChangeInputs.value) return
  let requests = currentChangeInputs.value.length;
  let usedOrder = firstDeliveryOrder.value
  if(order) {
    usedOrder = order
  }
  for(const formPos of currentChangeInputs.value) {
    formPos.position.amount = formPos.input.value as number;
    if(formPos.position.amount == 0 && formPos.position.id > 0) {
      orderStore.removeOrderPosition(formPos.position, usedOrder).then(() => {
        requests--;
        if(requests <= 1) {
          updateParentElement()
        }
      })
    }
    if(formPos.position.amount == 0 && formPos.position.id == 0) {
      requests--;
      continue
    }
    if(formPos.position.id >= 1) {
      orderStore.updateOrderPosition(formPos.position, usedOrder).then(() => {
        requests--;
        if(requests <= 1) {
          updateParentElement()
        }
      })
    } else {
      orderStore.addPosition(formPos.position, usedOrder).then(() => {
        requests--;
        if(requests <= 1) {
          updateParentElement()
        }
      })
    }
  }
}

function removePosition(position: OrderPosition) {
  orderStore.removeOrderPosition(position, firstDeliveryOrder.value).then(() => {
    updateParentElement()
  })
}

function openProductSelector() {
  isProductSelectorOpen.value = true
}

function closeProductSelector() {
  isProductSelectorOpen.value = false
}

function tryToAddProductToOrder(product: Product) {
  closeProductSelector()
  if(!currentChangeInputs.value || !currentFormModal.value) return
  const index = currentChangeInputs.value.findIndex(obj => obj.position.product.id == product.id)
  if(index >=0) {
    alertStore.addAlert("Das Produkt ist bereits in der Bestellung", 'warning')
    return;
  }

  const newPosition = new OrderPosition()
  newPosition.product = product
  newPosition.isSerialOrder = false
  newPosition.price = product.price
  newPosition.amount = 1

  const newProductInput = {
    position: newPosition,
    input: currentFormModal.value.addInput(product.name,'number', 1)
  } as FormPosition

  currentChangeInputs.value.push(newProductInput)
}

function addNewOrder() {
  if(!props.routePoint) return
  const newOrder = new Order();
  newOrder.isSerialOrder = false
  newOrder.wishDeliveryDate = moment().format('YYYY-MM-DD');
  newOrder.isSerialChild = false
  newOrder.customer = props.routePoint.customer
  newOrderElement.value = newOrder
}

const firstDeliveryOrder = computed(() => {
  if(!props.routePoint || props.routePoint.deliveryOrders.length == 0) return null
  const deliveryOrders = props.routePoint.deliveryOrders;
  if(deliveryOrders.length > 1) {
    console.warn("Kunde hat mehrere Bestellungen offen. Vermutlich ein Mergefehler im Backend")
  }
  return deliveryOrders[0];
})

const firstDeliveryPositions = computed(() => {
    if(firstDeliveryOrder.value) {
      return firstDeliveryOrder.value.orderPositions
    } else {
      return [] as OrderPosition[]
    }

})

function allIsDelivered() {
  if(!firstDeliveryOrder.value) return
  orderStore.checkAllIsDelivered(firstDeliveryOrder.value).then(() => {
    updateParentElement()
  })
}

function updateParentElement() {
  if(!props.routePoint) return
  emits('updatedOrder', props.routePoint.customer)
}

const rowStyle = computed(() => {
  return ""
})

function openCashRegister() {
  if(!props.routePoint) return
  const cashRegisterForm = formModalStore.addForm()

  cashRegisterForm.header = "Kasse von " + props.routePoint.customer.name

  cashRegisterForm.addDescription("Trage ein wie viel dein Kunde dir gegeben hat.")

  const valueInput = cashRegisterForm.addInput("Barzahlung", "number", 0, true);

  const saveBtn = cashRegisterForm.addButton("Speichern", "success");

  saveBtn.then(() => {
    if(!props.routePoint) return;
    const paymentMethod = transactionStore.paymentMethods.find(obj => obj.id == 1)
    if(!paymentMethod) {
      return
    }
    const confirmForm = formModalStore.addForm()
    confirmForm.header = "Bezahlung übermitteln";

    confirmForm.addDescription("Bist du sicher, dass du die Bestellung in das System eintragen möchtest? Der Vorgang kann nicht storniert werden.");
    confirmForm.addDescription("Kunde: " + props.routePoint.customer.name)
    confirmForm.addDescription(paymentMethod.name + " " + valueInput.value + " €")
    cashRegisterForm.close()
    const confBtn = confirmForm.addButton('Ja, Buchung ist richtig', 'outline-success')
    confBtn.then(() => {
      if(!props.routePoint) return;
      const transaction = new Transaction()
      transaction.amount = 1
      transaction.price = valueInput.value as number;
      transaction.total = transaction.price * transaction.amount
      transaction.description = "Barzahlung während der Auslieferung"
      transaction.paymentMethod = paymentMethod
      transaction.customer = props.routePoint.customer

      transactionStore.addTransaction(transaction).then(() => {
        updateParentElement()
        cashRegisterForm.close()
      })
    })
  })
}

function openOrderList() {
  if(!props.routePoint) return
  loadingStore.addLoading('overlay')
  deliveryRouteStore.updateRoutePoint(props.routePoint.customer).then(() => {
    loadingStore.removeLoading('overlay')
    showInModal.value = "oldOrder"
  })

}


function openTransactionList() {
  if(!props.routePoint) return
  deliveryRouteStore.updateRoutePoint(props.routePoint.customer)
  showInModal.value = "transaction"
}

function closeEmptyModal() {
  showInModal.value = null
}

</script>

<style scoped lang="scss">

tr {
  border-bottom: solid 1px;
}

td {
  padding-top: 12px;
  padding-bottom: 12px;
}

.normal-Size {
  font-size: 1rem;
}
</style>