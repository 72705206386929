<template>
  <div @click="emits('backgroundClicked')" class="background p-1">
    <div @click.stop @click="emits('modalClicked')" class="container bg-white p-3 rounded">
      <div @click="emits('closeClicked')" class="text-end"><i class="fa-regular fa-x fa-2x"></i></div>
      <slot @click="emits('slotClicked')"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {defineEmits } from "vue"

const emits = defineEmits(['modalClicked', 'backgroundClicked', 'slotClicked', 'closeClicked'])

</script>

<style lang="scss" scoped>
.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.3);
  z-index: 500;
  overflow: scroll;
}

</style>