import { defineStore } from 'pinia'
import {DeviceSetting} from "@/components/System/DeviceSetting/DeviceSetting";
import {useAlertStore} from "@/components/System/Alert/state";

let blockAlert = false

export const useDeviceSettingStore = defineStore('deviceSettingStore', {
    state: () => (
        {
            setting: {} as DeviceSetting
        }
    ),
    actions: {
        init() {
            this.setting = new DeviceSetting()
            this.load();
        },
        load() {
            const jsonSetting = localStorage.getItem('deviceSetting')
            if(!jsonSetting) return;

            const loadSetting = JSON.parse(jsonSetting)
            for(const [key] of Object.entries(this.setting)) {
                if(loadSetting[key] !== undefined) {
                    this.setting[key] = loadSetting[key] as number|boolean;
                }
            }

        },
        saveSetting() {
            const fm = useAlertStore();
            if(!blockAlert) {
                fm.addAlert("Erfolgreich gespeichert");
                blockAlert = true;
            }
            setTimeout(() => {
                blockAlert = false
            }, 2000)
            localStorage.setItem('deviceSetting', JSON.stringify(this.setting))
        }
    },
    getters: {
        isDataSaveMode():boolean {
            if(!this.setting) return false;
            return this.setting.dataSaveMode
        },
        getDeliveryDelayWarning(): false|number {
            if(!this.setting) return false;
            return this.setting.deliveryDelayWarning;
        }
    }
})