<template>
    <div>
        <div class="d-flex mt-2 mb-2">
            <input class="form-control" @keydown.enter="searchForProducts" v-model="searchString" placeholder="Suche nach einem Produkt ... " />
            <button @click="searchForProducts" class="btn btn-outline-success ms-2">Suchen</button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useLoadingStore } from "../System/Loading/state"
import { useProductStore } from "./state";

const searchString = ref("")

const loadingStore = useLoadingStore();
const productStore = useProductStore();

function searchForProducts() {
    loadingStore.addLoading("overlay")
    productStore.getProducts(searchString.value).then(() => {
        loadingStore.removeLoading("overlay")
    })
}
</script>