import { defineStore } from 'pinia'
import { Alert } from './Alert'
import { StyleColorType } from "../StyleColorType"

export const useAlertStore = defineStore('alertStore', {
    state: () => (
        { 
            alertItems: [] as Alert[]
        }
    ),
    actions: {
        addAlert(text: string, type: StyleColorType = 'success', showTime = 3000) {
            const alertItem = new Alert();
            alertItem.showTime = showTime;
            alertItem.type = type;
            alertItem.text = text;
            if(text != "") {
                this.alertItems.push(alertItem);
            }


            setTimeout(() => {
                this.removeAlert(alertItem)
            }, alertItem.showTime)
        },
        removeAlert(alert: Alert) {
            const index = this.alertItems.findIndex(obj => obj.id === alert.id) 
            this.alertItems.splice(index, 1);
        },
        addRequest(request: any) {
            if(!request.response && request.message) {
                this.addAlert(request.message + " - Prüfe deine Internetverbindung", "danger", 5000)
                return
            }
            if(request.response && request.response.status == 401 && request.response.data ) {
                const data = request.response.data
                let message = "Ubekannter fehler"
                if(!data.error) return;
                if(data.error == "Invalid credentials.") {
                    message = "Emailadresse oder Passwort ist leider falsch."
                }
                this.addAlert(message, "danger", 5000)
                return
            }
            if(request.response && request.response.status == 500 ) {
                this.addAlert(request.response.data.detail, "danger", 5000)
                return
            }
            if(request.response && request.response.status == null ) {
                this.addAlert("Server kann nicht erreicht werden", "danger", 5000)
            }
        }
    }   
})