<template>
  <div v-if="orderItems">
    <template v-for="order of orderItems" :key="order.id">
      <div class="mt-2">
        <DetailedOrderCard :order="order" @orderIsUpdated="orderIsUpdated" />
      </div>
    </template>
    <div class="m-auto text-center p-5" v-if="orderItems.length == 0">
      <em>Keine weiteren Bestellungen vorhanden</em>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {Order} from "@/components/Order/Order";
import { PropType, defineProps, defineEmits } from "vue";
import DetailedOrderCard from "@/components/Order/DetailedOrderCard.vue";

defineProps({
  orderItems: Object as PropType<Order[]>
})

const emits= defineEmits(['orderIsUpdated'])

function orderIsUpdated(order: Order) {
  emits('orderIsUpdated', order)
}

</script>