import { defineStore } from 'pinia'
import settings from '../FrontendSettings';
import axios from "axios"
import { useAlertStore } from '../Alert/state';
import { StyleColorType } from '../StyleColorType';
import { useLoadingStore } from '../Loading/state';

axios.defaults.withCredentials= true;

export const useRequestStore = defineStore('requestStore', {
    state: () => (
        {
        }
    ),
    actions: {
        addLoading() {
            const loadingStore = useLoadingStore();
            loadingStore.addLoading("background")
           
        },
        removeLoading() {
            const loadingStore = useLoadingStore();
            loadingStore.removeLoading("background")
        },
        // eslint-disable-next-line
        addAlert(data: any) {
            const alertStore = useAlertStore();
            let string = ""
            let type = "success" as StyleColorType
            if(data.code === "ERR_NETWORK") {
                return
            }
            if(typeof data.data == "string") {
                const dataContent = JSON.parse(data.data);
                if(dataContent.message) {
                    string = dataContent.message.message
                    type = dataContent.message.type
                }
            }

            if(data.response) {
                if(typeof data.response.data == "string") {
                    string = JSON.parse(data.response.data).message.message
                    type = JSON.parse(data.response.data).message.type as StyleColorType
                }
            }

            if(string != "") {
                alertStore.addAlert(string, type);
            }
        },
        addRequest(response: any) {
            const alertStore = useAlertStore();
            alertStore.addRequest(response)
        },
        get(url: string) {

            this.addLoading()

            const promise = new Promise((resolve, reject) => {
                const request = axios.get(settings.getRequestUrl(url))
                request.then((response) => {
                        this.removeLoading();
                        try {
                            JSON.parse(response.data);
                        } catch (e) {
                            resolve(false);
                            return false;
                        }
                        
                        this.addAlert(response)
                        resolve(JSON.parse(response.data).content)

                    }).catch((errorResponse) => {
                    //this.addAlert(response)
                    this.addRequest(errorResponse)
                        this.removeLoading();
                    resolve(false)
                })
            })
            return promise;
        },
        // eslint-disable-next-line
        post(url: string, data: any ) {
                this.addLoading()
            const promise = new Promise((resolve, reject) => {
                const request = axios.post(settings.getRequestUrl(url), data)
                request.then((response) => {
                    this.addAlert(response)
                    this.removeLoading();
                    resolve(JSON.parse(response.data).content)
                    }).catch((response) => {
                    this.addRequest(response)
                    this.addAlert(response)
                    this.removeLoading();
                    resolve(false);
                    reject(response)
                })
            })
            return promise;
        },
        delete(url: string) {
            this.addLoading()
            const promise = new Promise((resolve, reject) => {
                const request = axios.delete(settings.getRequestUrl(url))
                request.then((response) => {
                    this.addAlert(response)
                    this.removeLoading();
                        resolve(JSON.parse(response.data).content)
                    }).catch((response) => {
                    this.addRequest(response)
                    this.addAlert(response)
                    this.removeLoading();
                    resolve(response);
                })
            })
            return promise;
        },
        upload(url: string, formdata: FormData) {
            this.addLoading()

            const promise = new Promise((resolve, reject) => {
                const request = axios.post(settings.getRequestUrl(url), formdata,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                )
                request.then((response) => {
                    this.addAlert(response)
                    this.removeLoading();
                        resolve(JSON.parse(response.data).content)

                    }).catch((response) => {

                    this.removeLoading();
                    reject(response);
                })
            })
            return promise;
        },  
        login(loginData: {email: string, password: string}) {
            const promise = new Promise((resolve, reject) => {
                const headers = {
                    'Content-Type': 'application/json'
                  }
    
                const req = axios.post(settings.getRequestUrl("login_json"),
                    loginData,
                    {headers: headers}
                )
                req.then(() => {
                    resolve(true)
                }).catch((errorData) => {
                    const alert = useAlertStore()
                    alert.addRequest(errorData)
                    resolve(false)
                })
            })
            return promise
        }
    }
})