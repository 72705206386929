<template>
    <div class="dropZoneHolder" >
        <DropZoneContainer @drop.prevent="drop" @change="selectedFile" />
       
    </div>
</template>

<script setup lang="ts">
import DropZoneContainer from "./DropZoneContainer.vue";
import { reactive, defineProps, defineEmits } from "vue"
import { useFileSystemStore } from "./state" 

const fileSysmteStore = useFileSystemStore()
const emits = defineEmits([
    'finishUpload', 'startUpload'
])

const props = defineProps({
    moduleName: String,
    entryId: Number
})

let dropzoneFile = reactive({
    files: {} as FileList
})

const drop = (e: DragEvent) => {
    if(!e.dataTransfer) {
        return
    }
   
    dropzoneFile.files = e.dataTransfer.files
    uploadFiles();
}

const selectedFile = () => {
    const dropFileBox = document.querySelector(".dropzoneFile");
    if(!dropFileBox) {
        return; 
    }
    dropzoneFile.files = dropFileBox.files as FileList;
    uploadFiles()
}

function uploadFiles() {
    if(!props.moduleName || !props.entryId) {
        console.error("Kein Modulname oder Entry ID gewählt");
        return
    }
    emits('startUpload')
    fileSysmteStore.uploadImages(dropzoneFile.files, props.moduleName, props.entryId).then((allFileIds) => {
        emits('finishUpload', allFileIds)
    })
}

</script>

<style lang="scss" scoped>
.home {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;

    h1 {
        font-size: 40px;
        margin-bottom: 32px;
    }

    .file-info {
        margin-top: 32px;
    }

    }
</style>