import { defineStore } from 'pinia'
import { useRequestStore } from '../System/Request';
import {User, UserSearchRequest} from "@/components/System/User/User";


export const useEiliseUserStore = defineStore('eiliseUserStore', {
    state: () => (
        {
            foundEiliseUser: [] as User[],
            lastSearch: {} as UserSearchRequest
        }
    ),
    actions: {
        findUsers(searchString = null as null|string) {
            if(searchString !== null) {
               this.lastSearch.searchString = searchString
            }

            const promise = new Promise((resolve) => {
                const requestStore = useRequestStore();
                console.log(this.lastSearch)
                requestStore.post('api/v2/user/findUser', this.lastSearch).then((data) => {
                    this.foundEiliseUser = data as User[]
                }).catch(() => {
                    resolve(false)
                })
            })
            return promise
        }
    }
})