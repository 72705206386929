<template>
  <ProductSelectModal v-if="isProductSelctorOpen" @isClose="closeProductSelector" @isProductSelect="addProductToOrder" />
<div v-if="order">
  <div class="mb-1 bg-light border rounded d-flex p-2">
    <div class="col d-none d-md-block">
      <button @click="checkAllIsDelivered" v-if="!order.deliveryDate&&!order.isSerialOrder" class="btn btn-outline-dark">
        <i class="fa-regular fa-truck-ramp-couch"></i>
        Alles geliefert
      </button>
    </div>
<!--    <button @click="checkAllIsDelivered(order)" v-if="!orderStore.getDeliveryDateByOrder(order)" class="btn btn-outline-secondary"><i class="fa-regular fa-truck-ramp-couch"></i> Alles geliefert</button>-->
    <div class="p-2">
      <span class="me-1" v-if="order.isSerialChild">
        <i class="fa-regular fa-sync"></i>
      </span>
      <span v-if="!order.deliveryDate&&!order.isSerialOrder">
        <i class="fa-regular fa-calendar-days"></i> <span class="d-none d-md-inline">Wunschlieferdatum:</span> {{ moment(order.wishDeliveryDate).format('LL') }} ({{ moment(order.wishDeliveryDate).fromNow() }})
      </span>
      <span v-if="order.deliveryDate&&!order.isSerialOrder">
          <i class="fa-regular fa-calendar-days"></i> Geliefert am: {{ moment(order.deliveryDate).format('LL') }} ({{ moment(order.deliveryDate).fromNow() }})
      </span>
      <span v-if="order.isSerialOrder">
        <i class="fa-regular fa-calendar-days"></i> Vom: {{ moment(order.serialStartDate).format('LL') }} bis {{ moment(order.serialEndDate).format('LL') }}
        <span v-if="order.weekDelay>1">(Alle {{ order.weekDelay }} Wochen)</span>
        <span v-if="order.weekDelay==1">(Einmal pro Woche)</span>
      </span>
    </div>
    <div class="col">
      <div v-if="!order.deliveryDate" class="float-end d-none d-md-block">
        <button class="btn btn-success me-1" @click="tryOpenProductSelector"><i class="fa-regular fa-plus"></i> Position</button>
        <button class="btn btn-primary me-1" @click="tryOpenOrderEditor"><i class="fa-regular fa-edit"></i> </button>
      </div>
      <div v-if="!order.deliveryDate" class="d-md-none float-end ">
        <button class="btn btn-outline-dark me-1" @click="tryOpenMobileMenu"><i class="fa-regular fa-ellipsis"></i></button>
      </div>
    </div>
  </div>
  <div v-if="order.customer" class="border rounded p-1">
    <div @click="openCustomer" class="pointer">
      <span><i class="fa-regular fa-user"></i> {{order.customer.name}}</span>
    </div>
  </div>
  <!--    For schleife aller Positionen der Bestellung -->
  <div class="productInfo mb-2" v-for="pos of order.orderPositions" :key="pos.id">
    <OrderPositionCard :order-position="pos" :order="order" @positionUpdated="positionUpdateEvent" @removing-position="positionUpdateEventWithDelay" />
  </div>
</div>


</template>

<script lang="ts" setup>
import {PropType, defineProps, ref, defineEmits} from "vue";
import {Order} from "@/components/Order/Order";
import moment from "moment/moment";
import OrderPositionCard from "@/components/Order/OrderPositionCard.vue";
import ProductSelectModal from "@/components/Product/ProductSelectModal.vue";
import {useOrderStore} from "@/components/Order/state";
import {OrderForm} from "@/components/Order/OrderForm";
import {Product} from "@/components/Product/Product";
import {useFormModalStore} from "@/components/System/FormModal/state";
import {useRouter} from "vue-router";

const router = useRouter()

const props = defineProps({
  order: Object as PropType<Order>
})

const emits = defineEmits(['orderIsUpdated'])

const isProductSelctorOpen = ref(false)

const orderStore = useOrderStore();
const formModalStore = useFormModalStore();

function positionUpdateEvent() {
  emits('orderIsUpdated', props.order)
}

function positionUpdateEventWithDelay() {
  setTimeout(() => {
    emits('orderIsUpdated', props.order)
  }, 2000)

}

function tryOpenProductSelector() {
  if(!props.order) return
  if(props.order.isSerialOrder) {
    orderStore.openSerialEditorWarning().then(() => {
      openProductSelector()
    })
  } else {
    openProductSelector()
  }
}

function openProductSelector() {
  isProductSelctorOpen.value = true
}

function closeProductSelector() {
  isProductSelctorOpen.value = false
}



function addProductToOrder(product: Product) {
  closeProductSelector()
  if(!props.order) return
  OrderForm.getInstance().addPosition(product, props.order).then(() => {
    positionUpdateEvent()
  })
}

function checkAllIsDelivered() {
  if(!props.order) return
  orderStore.checkAllIsDelivered(props.order).then(() => {
    positionUpdateEvent()
  })
}

function tryOpenOrderEditor() {
  if(!props.order) return
  if(props.order.isSerialOrder) {
    orderStore.openSerialEditorWarning().then(() => {
      openOrderEditor()
    })
  } else {
    openOrderEditor()
  }
}

function openOrderEditor() {
  if(!props.order) return;
  orderStore.openOrderEditor(props.order).then(() => {
    positionUpdateEvent()
  })
}

function tryOpenMobileMenu() {
  if(!props.order) return
  if(props.order.isSerialOrder) {
    orderStore.openSerialEditorWarning().then(() => {
      openMobileMenu()
    })
  } else {
    openMobileMenu()
  }
}

function openMobileMenu() {
  const mobileActionForm = formModalStore.addForm("none")
  mobileActionForm.header = "Bestellung bearbeiten"

  mobileActionForm.addDescription('Was möchtest du mit der Bestellung machen?')
  mobileActionForm.addDescription('Wunschlieferdatum: ' + moment(props.order?.wishDeliveryDate).format('LL'))

  if(!props.order?.isSerialOrder) {
    const deliveryBtn =  mobileActionForm.addButton('Bestellung vollständig geliefert', 'outline-secondary')
    deliveryBtn.faIcon = "fa-truck-ramp-couch"
    deliveryBtn.then(() => {
      mobileActionForm.close()
      checkAllIsDelivered()
    })
  }


  const positionAddBtn =  mobileActionForm.addButton('Position hinzufügen', 'outline-success')
  positionAddBtn.faIcon = "fa-plus"
  positionAddBtn.then(() => {
    mobileActionForm.close()
    openProductSelector()
  })

  const editOrderBtn =  mobileActionForm.addButton('Lieferdatum bearbeiten oder löschen', 'outline-dark')
  editOrderBtn.faIcon = "fa-edit"
  editOrderBtn.then(() => {
    mobileActionForm.close()
    openOrderEditor()
  })

  const cancelBtn = mobileActionForm.addButton("Abbrechen", 'secondary')
  cancelBtn.then(() => {
    mobileActionForm.close()
  })
}

function openCustomer() {
  if(!props.order) return;

  router.push({name: "customer", params: {
    customerId: props.order.customer.id
    }})
}

</script>