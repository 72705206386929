
class FrontendSettings {
    backendHost = "https://localhost:8000"

    getHost() {
        const domain = window.location.hostname
        if(domain == "localhost") {
            return "https://localhost:8000"
        } else if (domain == "eilise.de") {
            return "https://api.eilise.de"
        }  else {
            console.log(domain);
            
            console.warn("Möglicherweise kein passender API Server gefunden!")
            return "https://"+domain+":8000"
        }

    }

    getRequestUrl(url: string) {
        return this.getHost() + "/" + url;
    }
}

const settings = new FrontendSettings();

export default settings;