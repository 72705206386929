<template>
  <div>
    <DeliveryOrders :open-orders="orderStore.openOrderItems"
                    :delivery-orders="orderStore.deliverOrderItems"
                    :closed-orders="orderStore.closedOrderItems"
                    :show-filter="true"
                    @changeOrderElement="loadElements"

    ></DeliveryOrders>
  </div>
</template>

<script lang="ts" setup>
import {useOrderStore} from "@/components/Order/state";
import DeliveryOrders from "@/components/Delivery/DeliveryElement/DeliveryOrders.vue";
import {onMounted, ref} from "vue";
import {useLoadingStore} from "@/components/System/Loading/state";
import {OrderFilter} from "@/components/Order/Order";
import {log} from "mathjs";

const orderStore = useOrderStore();
const loadingStore = useLoadingStore();

const amountOfLoadings = ref(0) // Bestimmt wie oft die Daten neu geladen wurde. Sorgt dafür, dass nicht mehrere Anfragen im Hintergrund gleichzeitig laufen

onMounted(() => {
  if(orderStore.orderFilter === null) orderStore.orderFilter = new OrderFilter()
  loadElements("delivery")
  // orderStore.getOrders('delivery')
  // orderStore.getOrders('open')
  // orderStore.getOrders('closed')
})

function loadElements(orderStatus: "open"|"closed"|"delivery") {
  amountOfLoadings.value++
  orderStore.clearCache(orderStatus)
  loadingStore.addLoading('overlay')
  orderStore.getOrders(orderStatus, 1).then(() => {
    loadingStore.removeLoading('overlay')
    delayLoading(orderStatus, 2, amountOfLoadings.value)
  })
}

function delayLoading(orderStatus: "open"|"closed"|"delivery", page = 1, loadingAmountJob = 0) {
  if(page > 20) {
    return;
  }
  setTimeout(() => {
    if(loadingAmountJob !== amountOfLoadings.value) return
    orderStore.getOrders(orderStatus, page).then((data) => {
      if(data.length == 0) return
      delayLoading(orderStatus, page+ 1, loadingAmountJob);
    })
  }, 50)

}
</script>