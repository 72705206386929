import {useFormModalStore} from "@/components/System/FormModal/state";
import {Order, OrderPosition} from "@/components/Order/Order";
import {Product} from "@/components/Product/Product";
import {useOrderStore} from "@/components/Order/state";


export class OrderForm {
    formModalStore
    orderStore
    static instance = null as null|OrderForm
    constructor() {
        this.formModalStore = useFormModalStore()
        this.orderStore = useOrderStore();
    }

    static getInstance(): OrderForm {
        if(!OrderForm.instance) {
            OrderForm.instance = new OrderForm()
        }
        return OrderForm.instance
    }

    addPosition(product: Product, order = null as null|Order) {
        const promise = new Promise((resolve) => {
            const amountForm = this.formModalStore.addForm();
            amountForm.addDescription('Bitte trage die Menge von ' + product.name + ' die du für die Bestellung hinzufügen möchten.');
            amountForm.header = "Produkt zur Bestellung hinzufügen"

            const amount = amountForm.addInput('Menge', 'number', 1, true);
            const price = amountForm.addInput('Preis (€)', 'number', product.price, true);

            const saveBtn = amountForm.addButton("Hinzufügen", 'success')
            saveBtn.then(() => {
                const position = new OrderPosition();

                position.product = product
                position.amount = amount.value as number;
                position.price = price.value as number;

                this.orderStore.addPosition(position, order).then((updatedOrder) => {
                    order = updatedOrder
                    resolve(order)
                    amountForm.close();
                })
            })
        })
        return promise as Promise<Order>

    }
}