<template>
  <div>
    <TransactionSearchBar />
    <div class="d-flex mt-2 justify-content-start" v-if="goods">
    <div class="card">
      <div class="card-header">
        Verkaufter Warenwert
      </div>
      <div class="card-body">
        {{goods.total*-1}} €
      </div>
    </div>
      <div class="card">
        <div class="card-header">
          Gutschriften
        </div>
        <div class="card-body">
          {{gift.total}} €
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          Einnahmen
        </div>
        <div class="card-body">
          {{money.total}} €
        </div>
      </div>
    </div>
    <TransactionList :transaction-items="transactionStore.foundTransactions" />
    <div class="text-center" v-if="!transactionStore.searchRequest.allItemFound">
      <button class="btn btn-outline-dark" ref="loadMore">Weitere Buchungen laden</button>
    </div>
  </div>
</template>

<script lang="ts" setup>

import TransactionList from "@/components/Transaction/TransactionList.vue";
import {useTransactionStore} from "@/components/Transaction/state";
import {computed, onMounted, ref, watch} from "vue";
import {useElementVisibility} from "@vueuse/core";
import TransactionSearchBar from "@/components/Transaction/TransactionSearchBar.vue";
import {TransactionSearchSummary} from "@/components/Transaction/Transaction";

const loadMore = ref(null)
const isLoadMoreVisable = useElementVisibility(loadMore)

const transactionStore = useTransactionStore();

watch(isLoadMoreVisable, (newValue) => {
  if(newValue) {
    transactionStore.searchRequest.page++
    transactionStore.getTransactions()
  }
})

const goods = computed(() => {
  if(!transactionStore.transactionSearchSummary) return new TransactionSearchSummary()
  return transactionStore.transactionSearchSummary.find(obj => obj.paymentMethodId == 2)
})

const money = computed(() => {
  if(!transactionStore.transactionSearchSummary) return new TransactionSearchSummary()
  return transactionStore.transactionSearchSummary.find(obj => obj.paymentMethodId == 1)
})

const gift = computed(() => {
  if(!transactionStore.transactionSearchSummary) return new TransactionSearchSummary()
  return transactionStore.transactionSearchSummary.find(obj => obj.paymentMethodId == 3)
})

</script>
