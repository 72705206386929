<template>
    <section>
        <div class="image-container " v-if="false">
            <img :src="require('@/assets/logo/eilise-logo_willkommen.svg')" alt="Eilise Logo" />
        </div>

        <div class="login-container">
            <div class="login-header">
            </div>
            <div class="login-box">
                <div class="login-container">
                    <h3>Login</h3>
                    <div class="login-header">
                    </div>
                        <div class="form-group">
                            <label for="email">E-Mail:</label>
                            <input @keydown.enter="loginNow" type="email" v-model="loginData.email" id="email" name="email" required placeholder="E-Mail-Adresse">
                        </div>

                        <div class="form-group">
                            <label for="password">Passwort:</label>
                            <input @keydown.enter="loginNow" type="password" v-model="loginData.password" id="password" name="password" required placeholder="Passwort">
                        </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="loginData._remember_me" id="rememberMe">
                        <label class="form-check-label" for="rememberMe">
                          Angemeldet bleiben
                        </label>
                  </div>
                  <div class="mt-3">
                    <button @click="loginNow">Anmelden</button>
                  </div>
                </div>
            </div>
            <p class="pointer forgot-password" @click="resetPasswordModal">Passwort vergessen?</p>
        </div>

    </section>
</template>

<script lang="ts" setup>
import { useLoadingStore } from "@/components/System/Loading/state";
import { useUserStore } from "@/components/System/User/state";
import { reactive } from "vue"
import { useRouter } from "vue-router";
import {useFormModalStore} from "@/components/System/FormModal/state";
import {useRequestStore} from "@/components/System/Request";


const userStore = useUserStore();
const formStore = useFormModalStore();
const router = useRouter();
const loadingStore = useLoadingStore();
const requestStore = useRequestStore()

const loginData = reactive({
    email: "",
    password: "",
    _remember_me: false
})

function loginNow() {
    loadingStore.addLoading('overlay');
    userStore.login(loginData).then((isLoggedIn) => {
        loadingStore.removeLoading('overlay');
        if (isLoggedIn) {
            router.push({ name: 'home' })
        }


    })
}

function resetPasswordModal() {
  const form = formStore.addForm()
  form.header = "Passwort zurücksetzen"

  const preEmail = loginData.email

  const emailInput = form.addInput('Emailadresse', 'text', preEmail , true)
  const btn = form.addButton('Zurücksetzen', 'success')
  btn.then(() => {
    const request =  requestStore.post('reset-password/app-reset', {email: emailInput.value})
    request.then((data) => {
      form.close()
    })

  })

}

</script>

<style lang="scss" scoped>



h1 {
    text-align: center;
    font-family: 'Strawberry Muffins Demo';
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.8em;
    margin-bottom: 15px;

}

.image-container {
    margin-top: 20px; 
    text-align: center;
    margin-bottom: 20px; 
}

.image-container img {
width: 20rem; 
 
}

.login-container {
    margin-top: 20px;
    text-align: center;
}

.login-header h3 {
    color: white;
    margin: 0;
}

.login-box {
    border: 2px solid #3EAB38; /* Grüner Rahmen */
    border-radius: 10px; /* Abgerundete Ecken */
    max-width: 300px;
    margin: auto;
    padding: 20px; /* Anpassung des Paddings für den Inhalt innerhalb des Rahmens */
	
}

.form-group {
    text-align: left;
    margin-bottom: 16px;
	margin-top: 16px;
}

.form-group label {
    display: none;
}

h3 {
    color: black;
    margin: 0;
	text-align: left;
    font-size: 1.5em; /* Bestimmte Größe für den Text "Login" */
	margin-bottom: 10px;
}

.form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: none; /* Entferne die Standardrahmen der Eingabefelder */
    border-bottom: 1px solid #3EAB38; /* Grüne Linie unterhalb des Eingabefeldes */
    margin-bottom: 10px; /* Platz zwischen den Eingabefeldern */
}

.form-check {
  text-align: start;
}

button {
    background-color: #3EAB38; /* Grüner Button */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
	margin-bottom: 10px;
}

button:hover {
    background-color: #45a049; /* Dunkleres Grün beim Hover */
}

.forgot-password {
    color: black; 
	font-size: 0.8em;
	text-align: center;
    margin-top: 15px; /* Platz zwischen dem Link und dem grünen Rahmen */
	padding-right: 200px;
}
</style>