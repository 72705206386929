import { Customer } from "../Customer/Customer"
import { Farm } from "../Farm"
import { Product } from "../Product/Product"
import { User } from "../System/User/User"
import {CustomerCategory} from "@/components/CustomerCategory/CustomerCategory";
export type PossibleOrderType = "open"|"delivery"|"closed"|"serial"
export class Order {
    id = 0
    farm = {} as Farm
    customer = {} as Customer
    createor = {} as User
    orderAssignment = ""
    created = ""
    wishDeliveryDate = ""
    deliveryDate = "" as string|null
    orderPositions = [] as OrderPosition[]
    isSerialOrder = false
    isSerialChild = false
    serialStartDate = ""
    serialEndDate = ""
    weekDelay = 0
}

export class OrderPosition {
    id = 0
    creator = {} as User
    updater = {} as User
    product = {} as Product
    created = ""
    updated = ""
    deliveryDate = ""
    amount = 0.0
    price = 0.0
    isSerialOrder = false
}

export class OrderPositionSummary extends OrderPosition {
    totalPrice = 0.0
    totalAmount = 0.0
    avgPrice = 0.0
}

export class OrderFilter {
    product = null as null|Product
    customerCategory = null as null|CustomerCategory
    dateTime  = ""
}