<template>
    <div>
      <h1>Auslieferung</h1>
      <template v-if="!deliveryRouteStore.activeDeliveryRoute">
        <p>Hier kannst du den Auslieferungs Modus starten. Wähle zunächst eine Route aus, die du starten möchtest</p>
        <div class="route-cards-container">
          <DeliveryRouteCard v-for="route of deliveryRouteStore.foundRouteItems" :key="route.id" :route="route" :enable-play-btn="true" />
        </div>
      </template>
      <template v-if="deliveryRouteStore.activeDeliveryRoute">
        <div class="text-center">
          <!-- <p>Der Auslieferungsmodus ist aktiv. Viel Spaß beim Verteilen der tollen Produkte :)</p> -->
        </div>
      </template>
    </div>
  </template>
  

<script lang="ts" setup>
import { onMounted } from "vue";
import { useDeliveryRouteStore } from "./state";
import DeliveryRouteCard from "./DeliveryRouteCard.vue";


const deliveryRouteStore = useDeliveryRouteStore();


onMounted(() => {
    deliveryRouteStore.getRoutes("");
})
</script>

<style lang="scss" scoped>
 .route-cards-container {
    display: flex; 
    gap: 10px; 
    margin-top: 20px; 
    padding-bottom: 10px; 
    justify-content: flex-start;
    align-items: center; 
  }

</style>