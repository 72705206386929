import {Balance, Customer, CustomerRoutePoint} from "../Customer/Customer"
import moment from "moment"
import {Order} from "@/components/Order/Order";
import {Transaction} from "@/components/Transaction/Transaction";

export class RoutePoint {
    customer = {} as Customer
    deliveryOrders = [] as Order[]
    openOrders = [] as Order[]
    closedOrders = [] as Order[]
    customerBalance= {} as Balance
    customerTransactions = [] as Transaction[]
}

export class DeliveryRoute {
    id = 0
    name = ""
    description = ""
    currentDate = "" // Auslieferungstag
    template = null as null|"table"
    route = [] as RoutePoint[]
    runningRoute = [] as RoutePoint[]
}

export class DeliveryRouteManager {

    deliveryRoute: DeliveryRoute
    deliveryDate: string

    constructor(route: DeliveryRoute, deliveryDate = null as null | string) {
        this.deliveryRoute = route
        if (deliveryDate) {
            this.deliveryDate = moment(deliveryDate).format('YYYY-MM-DD') + " 23:59"
        } else {
            this.deliveryDate = moment().format('YYYY-MM-DD') + " 23:59"
        }
    }
}

export class DeliveryRouteSearchRequest {
    searchString = ""
}
