<template>
    <div v-if="orderStore.focusOrder">
        <div class="customerShadow headerElement">
            <h1>Bestellung</h1>
        </div>
        <div class="alert alert-warning" v-if="orderStore.focusOrder.isSerialOrder">Achtung sobald du in der Serienbestellung etwas geändert wird, wird dies auf alle der Serie zugehörigen Bestellung übernommen.</div>
        <div class="d-flex justify-content-between m-0">
            <div class="w-100 me-1">
                <div class="dashboardItem">
                    <div>Kunde</div>
                    <div><a @click="openCustomer" href="#">{{ orderStore.focusOrder.customer.name }}</a></div>
                </div>  
            </div>
            <div class="w-100 ms-1" v-if="!orderStore.focusOrder.isSerialOrder">
                <div class="dashboardItem">
                    <div>Wunschlieferdatum</div>
                    <div>{{ moment(orderStore.focusOrder.wishDeliveryDate).format('LL') }}</div>
                </div>  
            </div>
            <div class="w-100 ms-1" v-if="orderStore.focusOrder.isSerialOrder">
                <div class="dashboardItem">
                    <div>Interval</div>
                    <div>{{ getIntervalString }}</div>
                </div>  
            </div>
        </div>
        <div class="fastActionBar customerShadow mt-2">
            <div @click.stop>
                <div class="actionBar d-flex justify-content-center">
                    <a  
                        href="#"
                        @click="checkAllIsDelivered"
                        v-if="employeeState.isAllowed('Mitarbeiter') && !getDeliveryDate && !orderStore.focusOrder.isSerialOrder">
                        <div class="callIcon">
                            <i class="fa-regular fa-truck-ramp-couch"></i>
                        </div>
                    </a>
                    <a  
                        href="#"
                        @click="openOrderEditor"
                        v-if="employeeState.isAllowed('Admin')">
                        <div class="callIcon">
                            <i class="fa-regular fa-gear"></i>
                        </div>
                    </a>

                </div>
            </div>
        </div>
        <div class="mt-4"> 
            <h3>Produktliste</h3>
            <div class="mb-2"> 
                <button @click="openProductSelector" class="btn btn-outline-success btn-sm w-100">Produkt hinzufügen</button>
            </div>
            <div class="mt-2" v-for="position of orderStore.focusOrder.orderPositions" :key="position.id">
                <OrderPositionCard :orderPosition="position" />
            </div>
        </div>
    </div>
    <ProductSelectModal v-if="isProductSelctorOpen" @isClose="closeProductSelector" @isProductSelect="addProductToOrder" />
</template>

<script lang="ts" setup>
/// Ich glaube dieses Template kann bald gelöscht werden.
import moment from 'moment';
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useEmployeeStore } from '../Employee/state';
import { useFormModalStore } from '../System/FormModal/state';
import { useLoadingStore } from "../System/Loading/state";
import { useOrderStore } from "./state"
import OrderPositionCard from './OrderPositionCard.vue';
import ProductSelectModal from '../Product/ProductSelectModal.vue';
import { Product } from '../Product/Product';
import { OrderPosition } from './Order';

const route = useRoute();
const router = useRouter();

const orderStore = useOrderStore();
const loadingStore = useLoadingStore();
const employeeState = useEmployeeStore();
const formModalStore = useFormModalStore();

const isProductSelctorOpen = ref(false)

onMounted(() => {
    if(!orderStore.focusOrder) {
        loadingStore.addLoading('overlay');
        orderStore.getOrder(route.params.orderId as string).then(() => {
            loadingStore.removeLoading('overlay');
        })
    }
    

})

function openOrderEditor() {
  if(!orderStore.focusOrder) return
  orderStore.openOrderEditor(orderStore.focusOrder).then((order) => {
    if(order == null) {
      router.back()
      router.back()
    }
  })

}

function openProductSelector() {
    isProductSelctorOpen.value = true
}

function closeProductSelector() {
    isProductSelctorOpen.value = false
}

function addProductToOrder(product: Product) {
    closeProductSelector();
    const amountForm = formModalStore.addForm();
    amountForm.addDescription('Bitte trage die Menge von ' + product.name + ' die du für die Bestellung hinzufügen möchten.');
    amountForm.header = "Produkt zur Bestellung hinzufügen"

    const amount = amountForm.addInput('Menge', 'number', 1, true);
    const price = amountForm.addInput('Preis (€)', 'number', product.price, true);

    const saveBtn = amountForm.addButton("Hinzufügen", 'success')
    saveBtn.then(() => {
        const position = new OrderPosition();

        position.product = product
        position.amount = amount.value as number;
        position.price = price.value as number;

        orderStore.addPosition(position).then(() => {
            amountForm.close();
        })
    })  
}

function checkAllIsDelivered() {
  orderStore.checkAllIsDelivered()
}

const getDeliveryDate = computed(() => {
  if(!orderStore.focusOrder) return null
  return orderStore.getDeliveryDateByOrder(orderStore.focusOrder)
})

function openCustomer() {
    if(!orderStore.focusOrder) return;
    router.push({name: "customer", params: {
        customerId: orderStore.focusOrder.customer.id
    }})
}

const getIntervalString = computed(() => {
    if(orderStore.focusOrder?.weekDelay == 1) {
        return "Einmal pro Woche";
    }
    if(orderStore.focusOrder?.weekDelay == 2) {
        return "Alle zwei Wochen";
    }    
    if(orderStore.focusOrder?.weekDelay == 3) {
        return "Alle drei Wochen";
    }
    if(orderStore.focusOrder?.weekDelay == 4) {
        return "Alle vier Wochen";
    }
    if(orderStore.focusOrder?.weekDelay == 5) {
        return "Alle fünf Wochen";
    }
    if(orderStore.focusOrder?.weekDelay == 6) {
        return "Alle sechs Wochen";
    }
    return orderStore.focusOrder?.weekDelay
})

</script>

<style lang="scss" scoped>

</style>