<template>
  <h3>Deine Farmen</h3>
  <div class="row m-auto mt-2">
    <template v-for="farmMember of userStore.me.farmMembers" :key="farmMember.id">
      <div class="col mt-2">
        <div class="card" @click="setActiveFarm(farmMember.farm)">

          <div>
            <h4>{{ farmMember.farm.name }}</h4>
          </div>
          <div v-if="isFarmActive(farmMember.farm)" class="text-success">
            <p><i class="fa-regular fa-circle-check"></i> Das ist deine aktive Farm</p>
          </div>
          <div>
            <div class="actionBar d-flex justify-content-center">
              <a v-if="isFarmActive(farmMember.farm)" href="#" @click="openView('farmEdit')">
                <div class="actionIcon" v-if="farmMember.role[0] == 'Admin'">
                  <i class="fa-regular fa-gear"></i>
                </div>
              </a>
              <a v-if="!isFarmActive(farmMember.farm)" href="#">
                <div class="actionIcon" v-if="farmMember.role[0] == 'Admin'">
                  <i class="fa-regular fa-right-to-bracket"></i>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script lang="ts" setup>
import {Farm, useFarmStore} from "@/components/Farm";
import {useFormModalStore} from "@/components/System/FormModal/state";
import {useRouter} from "vue-router";
import {useUserStore} from "@/components/System/User/state";

const farmStore = useFarmStore()
const formModalStore = useFormModalStore()
const router = useRouter();
const userStore = useUserStore()

function isFarmActive(farm: Farm)  {
  if(farmStore.activeFarm.id === farm.id) {
    return true
  }
  return false
}

function setActiveFarm(farm: Farm) {
  if(isFarmActive(farm)) {
    return;
  }
  const form = formModalStore.addForm();
  form.header = "Farm wechseln";
  form.addDescription("Möchtest du zur Farm \"" + farm.name + "\" wecheseln?")
  form.addButton("Ja, wechseln", 'primary').then(() => {
    farmStore.setActiveFarm(farm);
    form.close();
  })
}

function openView(value: string) {
  router.push({name: value})

}

</script>
<style lang="scss" scoped>

.actionIcon {
  margin: 0px 15px 0px 15px;
  font-size: 2.2rem;
  transition: 0.5s;
}

.actionIcon:hover {
  font-size: 2.5rem;
  color: #3EAB38;
}

h4 {
  color: #3EAB38;;
  text-align: center;
  font-family: Short Stack;
  padding-top: 15px;
}


</style>