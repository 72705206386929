<template>
    <div>
    <template v-for="form of formModalStore.formModalItems" :key="form.id">
        <FormModalTemplate  :form-modal="form" />
    </template>
    </div>
</template>

<script setup lang="ts">
import FormModalTemplate from './FormModalTemplate.vue';

import { useFormModalStore } from './state';

const formModalStore = useFormModalStore();

</script>