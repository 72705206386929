<template>
  <div v-if="customer">
    <h5>Lieferhinweise <span class="pointer" @click="openDeliveryDescriptionEditor"><i class="fa-regular fa-pen-to-square"></i></span></h5>
    <p>
      {{ customer.deliveryDescription }}
      <span v-if="!customer.deliveryDescription"><em>Keine Lieferbeschreibung vorhanden</em></span>
    </p>
    <ImageListViewer v-if="customer.deliveryImages.length > 0" :module-name="'customerDelivery'" :entry-id="customer.id" :file-ids="customer.deliveryImages" :viewer-mode="true" />
  </div>
</template>

<script setup lang="ts">
import {PropType, defineProps} from "vue";
import {Customer} from "@/components/Customer/Customer";
import ImageListViewer from "@/components/System/FileSystem/ImageListViewer.vue";
import {useCustomerStore} from "@/components/Customer/state";

const props = defineProps({
  customer: Object as PropType<Customer>
})

const customerStore = useCustomerStore()

function  openDeliveryDescriptionEditor() {
  if(!props.customer) return
  customerStore.openDeliveryNotes(props.customer)
}
</script>

<style scoped lang="scss">

</style>