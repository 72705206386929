<template>
    <div class="card" v-if="product">
        <div class="h-100 basicCard cardShadow">
            <div> 
                <h3>{{ product.name }}</h3>
            </div>
            <div> 
                <img :src="getMainImage" />
            </div>
            <div class="actionBar mt-1">
                <button @click="isSelect" class="button">Wählen</button>
            </div>
        </div>
    </div>
</template>



<script lang="ts" setup>
import { PropType, defineProps, computed, defineEmits } from "vue";
import { useFileSystemStore } from "../System/FileSystem/state";
import { Product } from "./Product";

const fileSystem = useFileSystemStore();

const props = defineProps({
    product: Object as PropType<Product>
});

const emits = defineEmits(['isSelect'])

const getMainImage = computed(() => {
    if(!props.product) return
    if(props.product.images.length == 0) return
    return fileSystem.getImagePath(props.product.images[0])
})

function isSelect() {
    emits('isSelect', props.product);
}

</script>

<style lang="scss" scoped>
img {
    width: 100%;
    border-radius: 10px;
}

.button {
    background-color:#3EAB38; 
    color: white;
    width: 10rem;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #3EAB38;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
}

.button:hover {
        transform: scale(1.05);
        background-color: white; 
        color:#3EAB38; 
        }


h3 {
    margin-top: 1rem;
}
</style>