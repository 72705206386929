<template>
  <div v-if="activeRoute?.runningRoute">
    <h1>Auslieferung im Tabellenmodus</h1>
    <table class="w-100 font-size-big">
      <thead>
        <tr>
          <th>Tel-Nr.</th>
          <th>Kunde</th>
          <th>Produkte</th>
          <th>Guthaben</th>
          <th>Aktion</th>
        </tr>
      </thead>
      <tbody>
      <template v-for="deliveryItem of activeRoute.runningRoute" :key="deliveryItem.customer.id">
        <DeliveryTableItem :routePoint="deliveryItem" @updatedOrder="updateOrderFromActiveRoute" />
      </template>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import {useDeliveryRouteStore} from "@/components/Delivery/state";
import {computed, onMounted} from "vue";
import DeliveryTableItem from "@/components/Delivery/DeliveryTableItem.vue";
import {Order} from "@/components/Order/Order";
import {Customer} from "@/components/Customer/Customer";
import {useTransactionStore} from "@/components/Transaction/state";

const deliveryRouteStore = useDeliveryRouteStore();
const transactionStore =   useTransactionStore()

const activeRoute = computed(() => {
  return deliveryRouteStore.activeDeliveryRoute;
})

onMounted(() => {
  transactionStore.getPaymentMethods();
})

function updateOrderFromActiveRoute(customer: Customer) {
  deliveryRouteStore.updateRoutePoint(customer)
}

</script>

<style scoped lang="scss">
.font-size-big {
   font-size: 1.2rem;
}
</style>