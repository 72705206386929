<template>
    <div class="orderItem mt-2">
        <h3>Buchungen</h3>
        <div class="text-center " v-if="transactionItems === null">
            <i class="fa-regular fa-spinner fa-spin fa-3x"></i>
        </div>
        <div v-if="transactionItems !== null">
            <button v-if="customer" @click="addTransaction" class="btn btn-outline-success w-100 btn-sm mb-2">Buchung hinzufügen</button>
            <div class="mb-2" v-for="transaction of transactionItems" :key="transaction.id">
                <TransactionCard :transaction="transaction" />
            </div>
        </div>

    </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, PropType } from 'vue';
import { PaymentMethod, Transaction } from './Transaction';
import TransactionCard from './TransactionCard.vue';
import { useFormModalStore } from '../System/FormModal/state';
import { Customer } from '../Customer/Customer';
import { useTransactionStore } from './state';
import { SelectOption } from '../System/FormModal/FormModal';


const formModalStore = useFormModalStore();
const transactionStore = useTransactionStore();

const props = defineProps({
    transactionItems: Object as PropType<Transaction[]|null>,
    customer: Object as PropType<Customer>,
    descriptionPreValue: {
      type: String,
      default: ""
    }
})

const emits = defineEmits(['transactionAdded']);

function addTransaction() {
    const form = formModalStore.addForm();

    form.header = "Neue Buchung"
    
    form.addDescription("Hier kannst du manuell Buchungen erfassen, z.B. Einzahlung des Kunden auf sein Konto.")

    const paymentMethodOptions = [] as SelectOption[]

    for(const payment of transactionStore.paymentMethods) {
        const option = new SelectOption();
        option.id = payment.id
        option.name = payment.name
        paymentMethodOptions.push(option)
    }

    const paymentMethodInput = form.addInput("Buchungsart", "select", 1, true, paymentMethodOptions)
   const description = form.addInput("Beschreibung", "text", props.descriptionPreValue, true);

    const total = form.addInput("Gutschrift, Einzahlung oder Belastung", "number", 0.0, true);

    const saveBtn = form.addButton("Buchung hinzufügen", "outline-success")

    saveBtn.then(() => {
        form.close();
        if(!props.customer) return
        const transaction = new Transaction();
        transaction.customer = props.customer;
        transaction.amount = 1
        transaction.price = total.value as number;
        transaction.total = total.value as number;
        transaction.description = description.value as string;

        
        const paymentMethodId = paymentMethodInput.value as number
        const paymentMethod = new PaymentMethod();
        paymentMethod.id = paymentMethodId;
        paymentMethod.name = "Not Relevant";

        transaction.paymentMethod = paymentMethod;

        transactionStore.addTransaction(transaction).then((data) => {
            emits("transactionAdded", data)
        })
    })

}

</script>

<style lang="scss" scoped>

</style>