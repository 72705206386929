<template>
  <div v-if="customer">
    <button @click="openAddOrder" class="btn btn-outline-success"><i class="fa-regular fa-plus"></i> Bestellung hinzufügen</button>
    <button @click="openAddSerialOrder" class="btn btn-outline-success ms-1"><i class="fa-regular fa-sync"></i> Serienbestellung hinzufügen</button>
  </div>
  <div class="mt-2 d-flex flex-wrap d-md-block justify-content-between">
    <button class="btn flex-fill" :class="getButtonStyle('delivery')"  @click="changeOpenElement('delivery')">
      <FolderIcon :is-open="isElementOpen('delivery')" /> Aktuelle
    </button>
    <button class="btn ms-md-1 flex-fill" :class="getButtonStyle('open')"  @click="changeOpenElement('open')">
      <FolderIcon :is-open="isElementOpen('open')" /> Offenen</button>
    <button class="btn ms-md-1 flex-fill" :class="getButtonStyle('closed')" @click="changeOpenElement('closed')">
      <FolderIcon :is-open="isElementOpen('closed')" /> Geschlossene
    </button>
    <button v-if="serialOrders" class="btn ms-md-1 flex-fill" :class="getButtonStyle('serial')" @click="changeOpenElement('serial')">
      <span class="me-1"><i class="fa-regular fa-sync"></i></span><FolderIcon :is-open="isElementOpen('serial')" /> Serien
    </button>
    <OrderFilterButton v-if="showFilter" @filterIsChange="reloadData" />
    <button class="btn btn-outline-secondary  ms-md-1 flex-fill" @click="exportOrder">
      <span v-if="data.exporting" class="me-1"><i class="fa-regular fa-spinner fa-spin"></i></span><span class="me-1" v-if="!data.exporting"><i class="fa-regular fa-file-export"></i></span> Export
    </button>
  </div>
  <OrderFilterReset v-if="showFilter" @resetFilter="reloadData" />
  <div>
    <DetailedOrderList :orderItems="getOpenedOrders" @orderIsUpdated="orderIsUpdated"  />
  </div>
</template>

<script setup lang="ts">
import {PropType, defineProps, defineEmits, reactive, computed} from "vue";
import {Order, PossibleOrderType} from "@/components/Order/Order";
import {Customer} from "@/components/Customer/Customer";
import DetailedOrderList from "@/components/Order/DetailedOrderList.vue";
import FolderIcon from "@/components/System/FolderIcon.vue";
import {useOrderStore} from "@/components/Order/state";
import OrderFilterReset from "@/components/Order/OrderFilterReset.vue";
import OrderFilterButton from "@/components/Order/OrderFilterButton.vue";

const data = reactive({
  openElement: "delivery" as PossibleOrderType,
  exporting: false
})

const emits = defineEmits(['orderIsUpdated', 'changeOrderElement'])

const props = defineProps({
  openOrders: Object as PropType<Order[]>,
  closedOrders: Object as PropType<Order[]>,
  deliveryOrders: Object as PropType<Order[]>,
  serialOrders: Object as PropType<Order[]>,
  customer: Object as PropType<Customer>,
  showFilter: {
    type: Boolean,
    default: false
  }
})

const orderStore = useOrderStore();
function isElementOpen(value: PossibleOrderType) {
  if(data.openElement == value) {
    return true
  }
  return false
}

function getButtonStyle(value: PossibleOrderType) {
  if(isElementOpen(value)) {
    return "btn-outline-primary"
  }
  return "btn-outline-secondary"
}

function changeOpenElement(value: PossibleOrderType) {
  emits('changeOrderElement', value);
  data.openElement = value
}

const getOpenedOrders = computed(() => {
  if(data.openElement == "delivery") {
    return props.deliveryOrders
  }
  if(data.openElement == "open") {
    return props.openOrders
  }
  if(data.openElement == "closed") {
    return props.closedOrders
  }
  if(data.openElement == "serial") {
    return props.serialOrders
  }
  return []
})

function orderIsUpdated(order: Order) {
  emits('orderIsUpdated', order)
}

function openAddOrder() {
  if(!props.customer) return
  orderStore.openNewOrderEditor(props.customer).then((order) => {
    emits('orderIsUpdated', order)
  })
}

function openAddSerialOrder() {
  if(!props.customer) return
  orderStore.openNewSerialOrderEditor(props.customer).then((order) => {
    emits('orderIsUpdated', order)
    changeOpenElement('serial')
  })
}

function reloadData() {
  changeOpenElement(data.openElement)
}

async function exportOrder() {
  if(data.exporting) return
  data.exporting = true
  await orderStore.exportOrder(data.openElement)
  data.exporting = false
}


</script>

<style scoped lang="scss">

</style>