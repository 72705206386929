<template>
    <div>
        <div class="cardContainer mt-3">
            <div class="p-2">
                <div class="actionBar" v-if="!viewerMode">
                    <button v-if="!isNewImageOpen" @click="isNewImageOpen=true" class="w-100 btn btn-outline-success btn-sm"><i class="fa-regular fa-image"></i> Bild hinzufügen</button>
                    <button v-if="isNewImageOpen" @click="isNewImageOpen=false" class="w-100 btn btn-outline-secondary btn-sm"><i class="fa-regular fa-image"></i> Abbrechen</button>
                </div>
                <div v-if="isNewImageOpen"> 
                    <DropZoneHolder :moduleName="moduleName" :entryId="entryId" @startUpload="startUploadEvent" @finishUpload="finishUploadEvent" />
                </div>
                <div class="imageList row mt-1 p-2">
                    <template v-for="fileId of fileIds" :key="fileId">
                        <div class="col-4 col-lg-2 mb-2">
                            <div class="imageElement" @click="fileSystemStore.showPicture(fileId)">
                                <div v-if="employeeStore.isAllowed('Admin') && !viewerMode" class="circleEdit" @click="editImage(fileId)">
                                    <span class=""><i class="fa-regular fa-pen"></i></span>
                                </div>
                                <img  :src="getImage(fileId)" /> 
                            </div>
                        </div>
                    </template>
                    <div class="text-center text-muted" v-if="fileIds && fileIds.length==0">
                        <div><i class="fa-regular fa-image fa-4x"></i></div>
                        <em>Keine Bilder gefunden. Füge doch welche hinzu. :)</em>
                    </div>
                </div>
            </div>    
        </div>
    </div>
</template>

<script setup lang="ts">
import { useEmployeeStore } from "@/components/Employee/state";
import { defineProps, PropType, ref, defineEmits } from "vue"
import { useFormModalStore } from "../FormModal/state";
import { useFileSystemStore } from "./state"
import DropZoneHolder from "./DropZoneHolder.vue";
import { useLoadingStore } from "../Loading/state";
import  settings  from "@/components/System/FrontendSettings"

function getImage(fileId: number) {
    return settings.getRequestUrl('api/v2/file/get/'+fileId)
}

const fileSystemStore = useFileSystemStore();
const formModalStore = useFormModalStore();
const loadingStore = useLoadingStore();
const employeeStore = useEmployeeStore();

const props = defineProps({
    fileIds: Array as PropType<number[]>,
    moduleName: String,
    entryId: Number,
    viewerMode: {
      default: false,
      type: Boolean
    }
})

const emits = defineEmits(['startUpload', 'finishUpload', 'startRemove', 'finishRemove', 'sortChanged'])

const isNewImageOpen = ref(false)

const editImage = (fileId: number) => {
    const editForm = formModalStore.addForm();

    editForm.header = "Bild bearbeiten"
    editForm.addDescription("In Zukunft kann hier auch eine Beschreibung und Namen vergeben werden.")

    const setMainBtn = editForm.addButton("Als Hauptbild setzen", 'outline-primary');

    setMainBtn.then(() => {
        if(!props.fileIds) return; 
        const fileIdsSaved = props.fileIds
        const index = fileIdsSaved.findIndex(obj => obj == fileId)
        fileIdsSaved.splice(index, 1);
        fileIdsSaved.unshift(fileId)
        emits("sortChanged", fileIdsSaved)
        editForm.close();
    })

    const dltBtn = editForm.addButton("Bild entfernen", "outline-danger")

    dltBtn.then(() => {
        removeImage(fileId);
        editForm.close()
    })
}

const removeImage = (fileId: number) => {
    const dltForm = formModalStore.addForm();

    dltForm.header = "Bild löschen?"
    dltForm.addDescription("Bist du sicher, dass du das Bild unwiederruflich löschen möchtest?")

    const confBtn = dltForm.addButton("Ja, bitte löschen", "outline-danger")

    confBtn.then(() => {
        emits('startRemove')
        fileSystemStore.removeImage(fileId).then((fileIds) => {
            emits('finishRemove', fileIds)
        })
        
        dltForm.close()
    })
}

const startUploadEvent = () => {
    emits('startUpload');
    loadingStore.addLoading('overlay');
}

const finishUploadEvent = (fileIds: number[]) => {
    emits('finishUpload', fileIds as number[]);
    isNewImageOpen.value = false;
    loadingStore.removeLoading('overlay');
}

</script>

<style scoped lang="scss">
    img {
        width: 100%;
        border-radius: 10px;
    }

.cardContainer {
    background-color: rgb(243, 243, 243);
    border-radius: 10px;
    -webkit-box-shadow: 2px 1px 21px -5px rgba(0,0,0,0.2); 
    box-shadow: 2px 1px 21px -5px rgba(0,0,0,0.2);
}
.imageElement {
    position: relative;;
}

.circleEdit {
    cursor: pointer;
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
    width: 22px;
    height: 22px;
    background-color: rgba(169, 169, 169, 0.8);
    border-radius: 100%;
    text-align: center;
    line-height: 22px;
    transition: all 0.25s;
}

.circleEdit:hover {
    width: 30px;
    height: 30px;
    line-height: 30px;
}
</style>