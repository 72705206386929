<template>
<div>
    <h1>Deine Kollegen</h1>
    <div class="p-3">
        <button @click="createNewMemberForm" class="w-100 btn btn-success"><i class="fa-regular fa-user-plus"></i> Helfer hinzufügen</button>
    </div>

    <div class="row mt-2 justify-content-center">
        <template v-for="farmMember of employeeState.employeeItems" :key="farmMember.id">
            <div class="col mb-2">
                <EmployeeCard :farmMember="farmMember" />
            </div>
        </template>
    </div>

</div>

</template>

<script lang="ts" setup>
import { Employee, UserRoles } from "@/components/Employee/Employee";
import { useEmployeeStore } from "@/components/Employee/state";
import EmployeeCard from "@/components/Employee/EmployeeCard.vue";

import { useFormModalStore } from "@/components/System/FormModal/state"

const formModalStore = useFormModalStore();
const employeeState = useEmployeeStore();

function createNewMemberForm() {
    const form = formModalStore.addForm()

    form.header = "Neuer Kollege / Kollegin"

    const email = form.addInput("Email", "text", "", true);
/*     const firstName = form.addInput("Vorname")
    const lastName = form.addInput("Nachname"); */
    const firstName = form.addInput("Vorname", "text");
    const lastName = form.addInput("Nachname", "text");
    const role = form.addInput("Rolle", "select", "", true, ["Admin","Mitarbeiter","Kunde"])

    form.addButton("Einladen","success").then(() => {
        const newEmployee = new Employee();
        newEmployee.role.push(role.value as UserRoles);
        newEmployee.member.email = email.value as string;
        newEmployee.member.firstName = firstName.value as string
        newEmployee.member.lastName = lastName.value as string

        employeeState.inviteEmployee(newEmployee);
        formModalStore.closeForm(form)
    })
}

</script>