<template>
  <div>
    <template :key="user.id" v-for="user of eiliseUserState.foundEiliseUser">
      <UserCard :user="user" />
    </template>
  </div>
</template>

<script lang="ts" setup>

import {useEiliseUserStore} from "@/components/EiliseUser/EiliseUserState";
import {onMounted} from "vue";
import UserCard from "@/components/System/User/UserCard.vue";

const eiliseUserState = useEiliseUserStore();

onMounted(() => {
  eiliseUserState.findUsers("");
})

</script>