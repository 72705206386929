<template>
  <div class="bg-light p-2" v-if="showBackButton">
    <span class="pointer text-muted" v-if="showBackButton" @click="goBack">Zurück</span>
  </div>
</template>

<script setup lang="ts">
import {computed} from "vue";
import {useRoute, useRouter} from "vue-router";
import {re} from "mathjs";

const route = useRoute()
const router = useRouter()

const showBackButton = computed(() => {
  if(route.name === "home") return false;
  if(route.name === "index") return false;
  if(route.name === "login") return false;
  if(route.name === "deliveryActiveMode") return false;
  if(route.name === "deliveryTableMode") return false;
  return true
})

function goBack() {
  router.go(-1)
}

</script>

<style scoped lang="scss">

</style>