import { defineStore } from 'pinia'
import { useRequestStore } from '../Request';
import settings from '../FrontendSettings';
import {Picture} from "@/components/System/FileSystem/Picture";

export const useFileSystemStore = defineStore('fileSystemStore', {
    state: () => (
        { 
            shownPicture: null as null|Picture
        }
    ),
    actions: {
        uploadImages(files: FileList, moduleName: string, entryId: number) {
            const promise = new Promise((resolve) => {
                const requestStore = useRequestStore();
                const formData = new FormData();
    
                for(let i = 0; i < files.length; i++ ){
                    const file = files[i];
                    formData.append('files[' + i + ']', file);
                }
    
                requestStore.upload('api/v2/file/addFiles/' + moduleName + '/' + entryId , formData).then((data) => {
                    resolve(data)
                })
            })
            return promise as Promise<number[]>
        },
        removeImage(fileId: number) {
            const requestStore = useRequestStore();

            const promise = new Promise((resolve, reject) => {
                requestStore.delete('api/v2/file/removeFile/'+ fileId).then((fileIds) => {
                    console.log("REMOVED");
                    
                    resolve(fileIds)
                }).catch(() => {
                    reject(false)
                });
            })
            return promise;
        },
        getImagePath(fileId: number) {
            return settings.getRequestUrl("api/v2/file/get/"+fileId)
        },
        showPicture(pictureId: number) {
            const pic = new Picture()
            pic.id = pictureId
            pic.path = this.getImagePath(pictureId)
            this.shownPicture = pic;
        },
        closePicture() {
            this.shownPicture = null
        }
    }
})