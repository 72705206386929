import { defineStore } from 'pinia'
import { useRequestStore } from '../System/Request';
import { useFormModalStore } from '../System/FormModal/state';
import {DeliveryRoute, DeliveryRouteSearchRequest, RoutePoint} from './DeliveryRoute';
import { Customer, CustomerRoutePoint } from '../Customer/Customer';

export const useDeliveryRouteStore = defineStore('deliveryRouteStore', {
    state: () => (
        { 
            focusRoute: null as DeliveryRoute|null,
            foundRouteItems: [] as DeliveryRoute[],
            activeDeliveryRoute: null as DeliveryRoute|null,
            activeDeliveryRouteStart: null as DeliveryRoute|null
        }
    ),
    actions: {
        init() {
            this.loadFromStorage()
        },
        addRoute(route: DeliveryRoute) {
            const requestStore = useRequestStore()
            const promise = new Promise((resolve) => {
                requestStore.post('api/v2/deliveryRoute/addRoute', route).then((data) => {
                    this.focusRoute = data as DeliveryRoute;
                    this.foundRouteItems.push(this.focusRoute)
                    resolve(data)
                })
            })
            return promise
        },
        updateRoute(route: DeliveryRoute) {
            const requestStore = useRequestStore()

            /// Entferne unnötige Kundeninformationen
            for(const routePoint of route.route) {
                routePoint.customer.openOrders = []
                routePoint.customer.closedOrders = []
                routePoint.customer.deliveryOrders = []
                routePoint.customer.serialOrders = []
                routePoint.customer.addressString = ""
            }

            const promise = new Promise((resolve) => {
                requestStore.post('api/v2/deliveryRoute/updateRoute/'+ route.id, route).then((data) => {
                    resolve(data)
                })
            })
            return promise
        },
        deleteRoute(route: DeliveryRoute) {
            const requestStore = useRequestStore()
            const promise = new Promise((resolve) => {
                requestStore.delete('api/v2/deliveryRoute/deleteRoute/'+ route.id).then((data) => {
                    resolve(data)
                })
            })
            return promise
        },
        getRoute(routeId: string) {
            const requestStore = useRequestStore()
            const promise = new Promise((resolve) => {
                requestStore. get('api/v2/deliveryRoute/getRoute/'+ routeId).then((data) => {
                    this.focusRoute = data as DeliveryRoute
                    resolve(data)
                })
            })
            return promise
        },
        getRoutes(searchString = "") {
            const requestStore = useRequestStore()

            const searchRequest = {
                searchString: searchString
            } as DeliveryRouteSearchRequest

            const promise = new Promise((resolve) => {
                requestStore.post('api/v2/deliveryRoute/find', searchRequest).then((data) => {
                    this.foundRouteItems = data as DeliveryRoute[]
                    resolve(data)
                })
            })
            return promise
        },
        openRouteFormEditor(route: DeliveryRoute) {
            const formModalStore = useFormModalStore();
            
            const routeForm = formModalStore.addForm()

            if(route.id == 0) {
                routeForm.header = "Neue Route hinzufügen"
            } else {
                routeForm.header = route.name + " bearbeiten"
            }

            const name = routeForm.addInput("Routenname", "text", route.name, true);

            const description = routeForm.addInput("Beschreibung", "textarea", route.description);

            const deleteBtn = routeForm.addButton("Löschen", "outline-danger")

            deleteBtn.then(() => {
                const confirmModal = formModalStore.addForm()
                confirmModal.header = "Route löschen";
                confirmModal.addDescription("Bist du sicher, dass du die Route <b>" + route.name + "</b> löschen willst?")

                confirmModal.addDescription("Es gehen dabei " + route.route.length + " Wegpunkte inkl. der Sortierung verloren");

                const confirmBtn = confirmModal.addButton("Ja, bitte löschen", "danger")

                confirmBtn.then(() => {
                    this.deleteRoute(route).then(() => {
                        this.getRoutes("").then(() => {
                            confirmModal.close()
                            routeForm.close()
                        })
                    })
                })
                deleteBtn.isLoading = false
            })

            const saveBtn = routeForm.addButton("Speichern", "success")
            saveBtn.then(() => {

                route.name = name.value as string
                route.description = description.value as string


                if(route.id == 0) {
                    this.addRoute(route).then(() => {
                        routeForm.close()
                    })
                } else {
                    this.updateRoute(route).then(() => {
                        routeForm.close()
                    })
                }
            })
        },
        startDeliveryMode(route: DeliveryRoute, deliveryDate: string, template: "table"|null, clearCurrentRoute = true) {
            const promise = new Promise<DeliveryRoute>((resolve) => {
                this.activeDeliveryRoute = route
                this.activeDeliveryRoute.template = template
                this.activeDeliveryRoute.currentDate = deliveryDate
                const request = useRequestStore();
                request.get("api/v2/deliveryRoute/getDeliveryTrack/"+route.id +"/"+deliveryDate).then((routePoints) => {
                    if(!this.activeDeliveryRoute) return
                    this.activeDeliveryRoute.runningRoute = routePoints as RoutePoint[]
                    if(clearCurrentRoute) {
                        this.activeDeliveryRouteStart = JSON.parse(JSON.stringify(this.activeDeliveryRoute)) as DeliveryRoute
                        this.saveInStorage()
                    }
                    resolve(this.activeDeliveryRoute)
                })
            })
            return promise

        },
        updateRoutePoint(customer: Customer) {
            const request = useRequestStore();
            const promise = new Promise<RoutePoint>((resolve) => {
                if(!this.activeDeliveryRouteStart) return
                request.get("api/v2/deliveryRoute/getDeliveryTrack/"+this.activeDeliveryRouteStart.id +"/"+this.activeDeliveryRouteStart.currentDate+"/"+customer.id).then((routePoint) => {
                        if(!this.activeDeliveryRoute) return
                        if(!this.activeDeliveryRoute.runningRoute) return
                        const updatedRoutePoint = routePoint as RoutePoint
                        const index = this.activeDeliveryRoute.runningRoute.findIndex(obj => obj.customer.id == updatedRoutePoint.customer.id)
                        if(index < 0) return;
                        this.activeDeliveryRoute.runningRoute[index] = updatedRoutePoint;
                        resolve(updatedRoutePoint)
                    })

                })
            return promise
        },
        stopDeliveryMode() {
            this.activeDeliveryRoute = null
            this.activeDeliveryRouteStart = null
            this.saveInStorage();
        },
        saveInStorage() {
            //const storeJson = JSON.stringify(this.activeDeliveryRouteStart)
            //window.localStorage.setItem("deliveryMode", storeJson)
        },
        loadFromStorage() {

            const storeJson = window.localStorage.getItem("deliveryMode")
            if(!storeJson) return

/*            this.activeDeliveryRouteStart = JSON.parse(storeJson) as DeliveryRoute
            if(this.activeDeliveryRouteStart === null) return;
            this.activeDeliveryRoute = JSON.parse(JSON.stringify( this.activeDeliveryRouteStart )) as DeliveryRoute
            this.startDeliveryMode(this.activeDeliveryRoute, this.activeDeliveryRouteStart.currentDate, this.activeDeliveryRouteStart.template, false)*/

        },
        addRoutePoint(customer: Customer, afterCustomer: Customer|null|'start'|'end') {
            if(!this.focusRoute) return
            if(!this.focusRoute.route) {
                this.focusRoute.route = [] as RoutePoint[]
            }
            const routePoint = new RoutePoint();
            routePoint.customer = customer
            if(afterCustomer == 'start') {
                this.focusRoute.route.splice(0, 0, routePoint as RoutePoint);
            } else if(afterCustomer == 'end' || afterCustomer == null) {
                this.focusRoute.route.push(routePoint);
            } else {
                const afterCustomerIndex = this.focusRoute.route.findIndex(obj => obj.customer.id == afterCustomer.id)
                this.focusRoute.route.splice(afterCustomerIndex+1, 0, routePoint as RoutePoint);
            }

            this.updateRoute(this.focusRoute);
        },
        removeRoutePoint(customer: Customer) {
            if(!this.focusRoute) return
            const index = this.focusRoute.route.findIndex(obj => obj.customer.id == customer.id)
            this.focusRoute.route.splice(index, 1);
            this.updateRoute(this.focusRoute)
        },
        moveRoutePoint(customer: Customer, direction: "up"|"down") {
            if(!this.focusRoute) return
            let currentIndex = this.focusRoute.route.findIndex(obj =>  obj.customer.id == customer.id);
            this.focusRoute.route.splice(currentIndex,1);
            if(direction == "down") {
                currentIndex++
            } else {
                currentIndex--
            }
            const route = new RoutePoint()
            route.customer = customer
            this.focusRoute.route.splice(currentIndex, 0, route as RoutePoint);
            this.updateRoute(this.focusRoute)
        }
    }

})