<template>
  <div class="card">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="icon">
          <span @click.stop @click="editFarm" class="text-primary">
            <i class="fa-regular fa-pen-to-square fa-xl"></i>
          </span>
      </div>
    </div>
    <div class="nameHeader m-auto">
      <h5>{{ farm.name }}</h5>
    </div>
    <div class="pb-3">
      <p class="p-0 m-0">Mitglied seit: {{  moment(farm.created).format('LL') }}</p>
      <p class="p-0 m-0">Kunden: {{  farm.customerAmount }} / {{ farm.maxCustomers }}</p>
      <p class="p-0 m-0">Mitarbeiter: {{  farm.farmMemberAmount }} / {{ farm.maxFarmMembers }}</p>
      <p class="p-0 m-0">Produkte: {{  farm.productAmount }} / {{ farm.maxProducts }}</p>
      <p class="p-0 m-0">Bestellungen: {{  farm.orderAmount }}</p>
      <p class="p-0 m-0">Gebühren: {{  farm.monthlyPayment }} € / Monat</p>
      <p class="p-0 m-0">Bezahlt bis: {{  moment(farm.paidBy).format('LL') }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {PropType, defineProps} from "vue";
import {Farm} from "@/components/Farm";
import moment from "moment";
import {useFormModalStore} from "@/components/System/FormModal/state";
import {useEiliseFarmStore} from "@/components/EiliseFarms/EiliseFarmState";

const formModalStore = useFormModalStore();
const eiliseFarmStore = useEiliseFarmStore()

const props = defineProps({
  farm: Object as PropType<Farm>
})

function editFarm() {
  const form =formModalStore.addForm()
  if(!props.farm) return
  form.header = props.farm.name

  const maxCustomer = form.addInput('Max. Kunden', 'number', props.farm?.maxCustomers, true);
  const maxFarmMembers = form.addInput('Max. Mitarbeiter', 'number', props.farm?.maxFarmMembers, true);
  const maxProducts = form.addInput('Max. Produkte', 'number', props.farm?.maxProducts, true);
  const monthlyPayment = form.addInput('Gebühren pro Monat', 'number', props.farm?.monthlyPayment, false);
  const paidBy = form.addInput('Bezahlt bis', 'date', moment(props.farm?.paidBy).format('YYYY-MM-DD'), true);

  const removeFarmBtn = form.addButton("Entfernen", "outline-danger", true)

  removeFarmBtn.then(() => {
    const confirmForm = formModalStore.addForm();
    confirmForm.header = "Farm entfernen?"
    confirmForm.addDescription("Bist du sicher, dass du die Farm entfernen möchtest?");
    confirmForm.addDescription("Du kannst die Farm nicht mehr aus dem Frontend aktivieren.");

    form.close();

    const cfmBtn = confirmForm.addButton("Ja, ich bin sicher!", 'outline-danger')
    cfmBtn.then(() => {
      if(!props.farm) return
      eiliseFarmStore.removeFarm(props.farm).then(() => {
        confirmForm.close()
      })
    })


  })

  const confirmBtn = form.addButton('Speichern', 'success')

  confirmBtn.then(() => {
    const updatedFarm = props.farm;
    if(!updatedFarm) return
    updatedFarm.maxCustomers = maxCustomer.value as number
    updatedFarm.maxFarmMembers = maxFarmMembers.value as number
    updatedFarm.maxProducts = maxProducts.value as number
    updatedFarm.monthlyPayment = monthlyPayment.value as number
    updatedFarm.paidBy = paidBy.value as string

    eiliseFarmStore.updateFarm(updatedFarm).then(() => {
      form.close()
    })
  })

}

</script>