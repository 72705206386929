<template>
  <span v-if="(index/preLoadCount) % 1 === 0" ref="preLoadSpan"></span>
  <div v-if="customer">
    <div class="border card text-center rounded w-100 " :class="getCardStyle" ref="lazyLoad">
      <div class="row card-header p-2">
        <div class="col-3 text-start">
          <span v-for="category of customer.categories" :key="category.id">
            <span v-if="category.images.length > 0">
              <img :alt="category.name" :src="fileStore.getImagePath(category.images[0])" />
            </span>
            <span v-else>
              {{ category.name}}
            </span>
          </span>
        </div>
        <div class="col-6 pointer" @click="emits('isClicked', customer)">
          <h4 class="mb-0">{{ customer.name }}</h4>
        </div>
        <div class="col-12 col-md-3 action-icons text-md-end mt-3 mt-md-1">
          <span class="pointer mx-1" v-if="customer.phoneNumber" @click="setFollowMe">
            <a :href="'tel:'+customer.phoneNumber">
              <i class="fa-regular fa-phone"></i>
            </a>
          </span>
          <span class="pointer mx-1" v-if="customer.mobileNumber" @click="setFollowMe">
            <a :href="'tel:'+customer.mobileNumber">
              <i class="fa-regular fa-mobile-button"></i>
            </a>
          </span>
          <span class="pointer mx-1" v-if="customer.email">
            <a :href="'mailto:'+customer.email">
              <i class="fa-regular fa-envelope"></i>
            </a>
          </span>
          <span class="pointer mx-1" v-if="customer.addressString">
            <a :href="'http://maps.google.com/?q=' + customer.addressString">
              <i class="fa-regular fa-location-dot"></i>
            </a>
          </span>
          <span @click="customerStore.openCustomerDescription(customer)" class="pointer mx-1">
            <span v-if="customer.salesDescription">
              <i class="fa-regular fa-comment-lines"></i>
            </span>
            <span v-else>
               <i class="fa-regular fa-comment"></i>
            </span>
          </span>
          <span @click="customerStore.openCustomerEditor(customer)" class="pointer mx-1">
            <i class="fa-regular fa-pen-to-square"></i>
          </span>
        </div>
      </div>
      <div class="card-body ">
        <span v-if="customer.isLoading"><i class="fa-regular fa-spinner fa-spin text-primary fa-2x"></i></span>
        <div class="row text-start" v-if="!customer.isLoading">
          <div v-if="!customer.balance && !customer.isLoading" class="text-center">
            <button @click="getCustomerDetails(true)" class="btn btn-outline-success">Kunden details laden</button>
          </div>
          <div  class="col-4">
            <span v-if="customer.balance && customer.balance.total != 0">
            Guthaben:
            <FinanceSpan :value="customer.balance.total"></FinanceSpan>
            </span>
            <span v-else>
              Guthaben ausgeglichen
            </span>
          </div>
          <div v-if="customer.openOrders" class="col-4">
            <span v-if="customer.openOrders.length == 0">
              Alle aufträge geliefert
            </span>
            <span v-else>
            {{ customer.deliveryOrders.length}} aktuelle Aufträge
            </span>
          </div>
          <div v-if="customer.openOrders" class="col-4">
            <span v-if="customer.openOrders.length == 0">
                Keine offenen Aufträge
            </span>
            <span v-else>
             {{ customer.openOrders.length}} offene Aufträge
            </span>
          </div>
        </div>
        <hr class="mt-3">
        <div class="row">
          <div class="col-6">
            <template v-if="nextOrder">
            <h5>Nächste Lieferung</h5>
              {{ moment(nextOrder.wishDeliveryDate).format('LL') }}
            <div v-for="pos of nextOrder.orderPositions" :key="pos.id">
              {{ parseFloat(pos.amount as string).toFixed(0) }} x {{ pos.product.name }}
            </div>
            </template>
            <div class="mt-2">
            <button class="btn btn-outline-dark btn-sm" @click="emits('isClicked', customer)">Aufträge bearbeiten</button>
            </div>
          </div>
          <div class="col-6">
            <h5 @click="emits('isSelected', customer)">Markieren / Auswählen</h5>
            <div class="fa-2x pointer"  @click="emits('isSelected', customer)">
              <span v-if="customer.isSelected" class="text-primary">
                <i class="fa-regular fa-toggle-on "></i>
              </span>
              <span v-else class="text-muted">
                <i class="fa-regular fa-toggle-off"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script lang="ts" setup>
import {useCustomerStore} from "@/components/Customer/state";
import {PropType, defineProps, ref, watch, defineEmits, computed, popScopeId} from "vue";
import {Customer} from "@/components/Customer/Customer";
import {useFileSystemStore} from "@/components/System/FileSystem/state";
import {useDeviceSettingStore} from "@/components/System/DeviceSetting/deviceSettingStore";
import FinanceSpan from "@/components/Finance/FinanceSpan.vue";

const customerStore = useCustomerStore()
import { useElementVisibility } from '@vueuse/core'
import {useOrderStore} from "@/components/Order/state";
import OrderCard from "@/components/Order/OrderCard.vue";
import DetailedOrderCard from "@/components/Order/DetailedOrderCard.vue";
import moment from "moment";
import {useRouter} from "vue-router";
import {useUserStore} from "@/components/System/User/state";
const fileStore = useFileSystemStore()
const deviceSettingStore = useDeviceSettingStore()
const orderStore = useOrderStore()

const props = defineProps({
  customer: Object as PropType<Customer>,
  index: Number
})

const emits = defineEmits(['isClicked', 'isSelected'])

const lazyLoad = ref(null)
const preLoadSpan = ref(null)
const preLoadSpanIsVisable = useElementVisibility(preLoadSpan)
const targetIsVisible = useElementVisibility(lazyLoad)

const preLoadCount = 30


async function getCustomerDetails(force = false) {
  if(!props.customer) return
  if(!force && props.customer.isDetailLoaded) return
  await customerStore.getCustomer(props.customer.id, false)
}

async function getPreLoadCustomers() {
  if(props.index == undefined) return
  if(props.index >= 0) customerStore.getPreLoadCustomer(props.index, preLoadCount)
}

watch(targetIsVisible, () => {
  if(deviceSettingStore.setting.dataSaveMode) return
  //getCustomerDetails()
})

watch(preLoadSpanIsVisable, () => {
  if(deviceSettingStore.setting.dataSaveMode) return
  getPreLoadCustomers()
})

const nextOrder = computed(() => {
  if(!props.customer || !props.customer.openOrders || props.customer.openOrders.length ==0) return null
  return props.customer.openOrders[0]
})

function isClicked() {
  emits('isClicked', props.customer)
}

const getCardStyle = computed(() => {
  if(props.customer?.isSelected) return "bg-light"
  return ""
})

function setFollowMe() {
  if(!props.customer) return
  useUserStore().setFollowMe('customer/'+props.customer.id)
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/breakpoints";

  img {
    height: 20px;
  }
.action-icons {
  color: #2c3e50;
  font-size: 1.2rem;
  
@media only screen and (max-width: $bp-md) {
  font-size: 2rem;
}

}
</style>