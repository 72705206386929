import { defineStore } from 'pinia'
import { useRequestStore } from '../System/Request';
import { useFormModalStore } from '../System/FormModal/state';
import {Farm} from "@/components/Farm";

export const useEiliseFarmStore = defineStore('eiliseFarmStore', {
    state: () => (
        {
            eiliseFarmItems: [] as Farm[]
        }
    ),
    actions: {
            getAllFarms() {
                const promise = new Promise<Farm[]>((resolve) => {
                    const request = useRequestStore()
                    request.get('api/v2/farm/getAll').then((data) => {
                        this.eiliseFarmItems = data as Farm[]
                        resolve(data as Farm[])
                    })
                })
                return promise
        },
        updateFarm(farm: Farm) {
            const requestStore =  useRequestStore();
            const promise = new Promise<Farm>((resolve) => {
                const request = requestStore.post('api/v2/updateFarm/'+farm.id, farm )
                request.then((data) => {
                    resolve(data as Farm)
                })
            })
            return promise;
        },
        removeFarm(farm: Farm) {
            const requestStore =  useRequestStore();
            const promise = new Promise<Farm>((resolve) => {
                const request = requestStore.post('api/v2/removeFarm/'+farm.id, farm )
                request.then((data) => {
                    resolve(data as Farm)
                })
            })
            return promise;
        },
    }
})