<template>
    <div class="dashboardItem" v-if="transaction">
        <div class="d-flex justify-content-between">
            <div>
              {{ moment(transaction.created).format('LL') }}
              <span @click="goToCustomer" v-if="transaction.customer.id" class="text-primary pointer"> - {{ transaction.customer.name }}</span>
            </div>
            <div>#{{ transaction.id }}</div>
        </div>
        <div class="d-flex justify-content-between"> 
            <div><span><i class="fa-regular" :class="'fa-'+transaction.paymentMethod.faIcon"></i></span> {{ transaction.description }} </div>
            <div :class="getTotalStyle"> {{ transaction.total }} € </div>
        </div>

    </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, PropType } from 'vue';
import { Transaction } from './Transaction';
import moment from 'moment';
import {useRouter} from "vue-router";

const props = defineProps({
    transaction: Object as PropType<Transaction>
})

const router = useRouter()

const getTotalStyle = computed(() => {
    if(!props.transaction) return ""
    if(props.transaction.total < 0) {
        return "text-danger"
    }
    return ""
})

function goToCustomer() {
  router.push({name: 'customer', params: {customerId: props.transaction?.customer.id}})
}
</script>

<style lang="scss" scoped>

</style>