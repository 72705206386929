<template>
 <div v-if="customerCategory">
        <div class="card pb-3" @click="isClicked">
            <div class="actionBar d-flex justify-content-center" @click.stop>
                <div v-if="employeeState.isAllowed('Admin')">
                    <div class="icon-group d-flex justify-content-center align-items-center">
                        <div class="icon" style="color: #3EAB38;" @click="openEditCategory">
                            <i class="fa-regular fa-pen-to-square fa-xl"></i>
                        </div>
                    </div>
                    </div>
                        <template v-if="customerCategory.images && customerCategory.images.length > 0">
                        <div class="icon" style="color: #3EAB38;" @click="openCategory">
                            <i class="fa-regular fa-folder-open fa-xl"></i>
                        </div>
                        </template>
                    </div>
            <div class="nameHeader m-auto">
                <h4 class="">{{ customerCategory.name }}</h4>
            </div>
            <template v-if="customerCategory.images && customerCategory.images.length > 0">
                <div class="m-auto">
                    <img :src="fileStore.getImagePath(customerCategory.images[0])" :alt="customerCategory.name" />
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { PropType, defineProps, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { useEmployeeStore } from "../Employee/state";

import { useFileSystemStore } from "../System/FileSystem/state";
import { CustomerCategory } from "./CustomerCategory";
import { useCustomerCategoryStore } from "./store";

const employeeState = useEmployeeStore();
const customerCategoryStore = useCustomerCategoryStore();
const router = useRouter();
const fileStore = useFileSystemStore()

const emits = defineEmits(['isClicked']);

function openEditCategory() {
    customerCategoryStore.openEditCategoryForm(props.customerCategory);
}

function openCategory() {
    if(!props.customerCategory) return
    router.push({name: "customerCategory", params: { customerCategoryId:  props.customerCategory.id.toString()} })
}

function isClicked() {
    if(!props.customerCategory) return
    emits("isClicked", props.customerCategory)
}

const props = defineProps({
    customerCategory: Object as PropType<CustomerCategory>
})

</script>

<style lang="scss" scoped>
@import "./../../assets/style/variables.scss";
@import "./../../assets/style/customer-category-card.scss";

h4 {
    color: #3EAB38;
}

.icon-group {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }

.icon:hover {
  transform: scale(1.02);
}


</style>