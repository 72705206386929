<template>
  <div class="background" @click="close" >
    <div @click.stop class="overlayCard m-auto mt-5">
      <div class=" text-center p-2">
        <h3>Routenliste</h3>
        <div class="xClose" @click="close">
          <i class="fa-regular fa-xmark"></i>
        </div>
      </div>
      <div v-if="props.routePoints">
        <div class="pb-4" v-if="infoText">{{ infoText }}</div>
        <div class="form-group d-flex mb-3 p-1">
          <input class="form-control " v-model="searchValue" placeholder="Filter nach Kunden" />
          <button v-if="searchValue != ''" @click="clearSeach" class="btn btn-outline-secondary"><i class="fa-regular fa-x"></i></button>
        </div>
        <div v-if="showBasePosition">
          <button class="btn btn-outline-secondary me-1" @click="emits('toStart')">An den Anfang</button>
          <button class="btn btn-outline-secondary ms-1" @click="emits('toEnd')">An das Ende</button>
          <hr>
        </div>
        <template v-for="routePoint of foundRoutePoints" :key="routePoint.id">
          <div class="p-2 border-bottom pointer">
            <span class="greenHover" @click="openRoutePoint(routePoint)">
              {{routePoint.customer.name}}
            </span>
            <span v-if="isDone(routePoint) && isCheckmark" class="ps-2"><i class="fa-regular fa-check"></i> </span>
          </div>
        </template>

      </div>
    </div>
    </div>
</template>

<script lang="ts" setup>
import {computed, defineEmits, PropType, defineProps, ref} from "vue"
import {RoutePoint} from "@/components/Delivery/DeliveryRoute";

const emits = defineEmits(['close', 'selectRoutePoint', 'toStart','toEnd'])

const props = defineProps({
  routePoints: Object as PropType<RoutePoint[]>,
  infoText: {
    type: String,
    default: ""
  },
  isCheckmark: {
    type: Boolean,
    default: true
  },
  showBasePosition: {
    type: Boolean,
    default: false
  }
})

const searchValue = ref("")

const foundRoutePoints = computed(() => {
    if(!props.routePoints) return []
    return props.routePoints.filter((el) => el.customer.name.toLowerCase().includes(searchValue.value.toLowerCase()));
})

function close() {
  emits('close')
}

function isDone(routePoint: RoutePoint ) {
  if(routePoint.deliveryOrders.length == 0) return true
  return false
}

function openRoutePoint(routePoint: RoutePoint ) {
  emits('selectRoutePoint', routePoint)
}

function clearSeach() {
  searchValue.value = ""
}

</script>

<style lang="scss" scoped>
@import "@/assets/style/variables";
.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.3);
  z-index: 500;
  overflow: scroll;
}

.card {
  width: 100%;
  max-width: 600px;
}

.greenHover:hover {
  color: $eiliseGreen;
}

.overlayCard {
  position: relative;
}

.xClose {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  line-height: 0px;
}
</style>