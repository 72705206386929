<template>
    <div v-if="orderPosition">
        <div class="positionCard d-flex justify-content-arround">
            <div class="productImage" >
                <template v-if="orderPosition.product.images.length > 0">
                    <img :src="fileStroe.getImagePath(orderPosition.product.images[0])" />
                </template>
            </div>
            <div class="productInfo">
                <div class="product">
                    <h6>
                        {{ orderPosition.product.name }}
                    </h6>
                    <div class="positionAction">
                        <span class="editBtn" v-if="!props.orderPosition.deliveryDate" >
                          <span @click="tryOpenEditor" class="me-2"><i class="fa-regular fa-pen-to-square fa-lg"></i></span>
                           <span v-if="!order.isSerialOrder" @click="openIsDeliveryWindow()" class="me-2"><i class="fa-regular fa-truck-ramp-couch"></i></span>
                        </span>
                        
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="d-flex">
                        <div class="me-2">
                            <span> {{ orderPosition.amount }} </span>
                            <span> Stk.</span>
                        </div>
                        <div class="me-2">
                            <span></span>
                            <span> {{ orderPosition.price }} €</span>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="ms-2">
                            <b>
                                <span class="me-1"><i class="fa-regular fa-sigma"></i></span>
                                <span> {{ (orderPosition.price*orderPosition.amount).toFixed(2) }} €</span>
                            </b>
                        </div>
                    </div>
                </div>
                <div class="deliveryInfo mt-2">
                    <div class="alert alert-info" v-if="orderPosition.deliveryDate">Wurde geliefert am {{  moment(orderPosition.deliveryDate).format('LL') }}</div>
                </div>
            </div>
        </div>
    </div>    
</template>

<script lang="ts" setup>
import {PropType, defineProps, defineEmits} from "vue";
import { useFileSystemStore } from "../System/FileSystem/state";
import { useFormModalStore } from "../System/FormModal/state";
import {Order, OrderPosition} from "./Order";
import { useOrderStore } from "./state";
import moment from "moment";
import {FormModal} from "@/components/System/FormModal/FormModal";

const formModalStore = useFormModalStore();
const orderStore = useOrderStore();
const fileStroe = useFileSystemStore();

const props = defineProps({
    orderPosition: Object as PropType<OrderPosition>,
  order: {
      type: Object as PropType<Order>,
      default: null
  }
});

const emits = defineEmits(['positionUpdated', 'removingPosition'])

function confirmDelete() {
   
    const form = formModalStore.addForm();
    form.header = "Löschen der Position"
    form.addDescription("Bist du sicher, dass du die Bestellung unwiderruflich löschen möchtest?")
    const deleteBtn = form.addButton("Ja, löschen", "outline-danger");

    deleteBtn.then(function () {

          if(!props.orderPosition) return
          removingPosition()
          orderStore.removeOrderPosition(props.orderPosition, props.order).then(function () {
            /// UPDATE FUNKTIONIERT NICHT
            updatedPosition()
          }).catch(() => {
            updatedPosition()
          })

      form.close()
/*        if(props.orderPosition) {
          orderStore.removeOrderPosition(props.orderPosition, props.order).then(() => {
            console.log("DELETED")
            console.log(props.orderPosition);
            emits("updated", props.orderPosition)
          })
        }*/
        //emits("updated", props.orderPosition)
    })
}

function tryOpenEditor() {
  if(!props.orderPosition || !props.order) return
  if(props.order.isSerialOrder) {
    orderStore.openSerialEditorWarning().then(() => {
      openEditor()
    })
  } else {
    openEditor()
  }
}

function openEditor() {
    if(!props.orderPosition || !props.order) return
    const editForm = formModalStore.addForm();

    editForm.header = "Position bearbeiten"

    const amount = editForm.addInput('Menge', 'number', props.orderPosition.amount, true)
    const price = editForm.addInput('Preis (€)', 'number', props.orderPosition.price);    
    
    if(!props.orderPosition.deliveryDate) {
            const deleteBtn = editForm.addButton('Löschen', 'outline-danger')
            if(!props.orderPosition.isSerialOrder) {
              const deliveryBtn = editForm.addButton('Wurde geliefert', 'outline-secondary')

              deliveryBtn.then(() => {
                openIsDeliveryWindow(editForm)
              })
            }
        deleteBtn.then(() => {
                editForm.close()
                confirmDelete()
            }) 
    }

    const saveBtn = editForm.addButton('Speichern','success');

    saveBtn.then(() => {
        if(!props.orderPosition) return
        const orderPos = props.orderPosition
        orderPos.amount = amount.value as number
        orderPos.price = price.value as number

        orderStore.updateOrderPosition(orderPos, props.order).then(() => {
          updatedPosition()
        })
        editForm.close();
    })

}

function openIsDeliveryWindow(editForm = null as null|FormModal) {

    const deliveryForm = formModalStore.addForm();

    deliveryForm.header = "Position wurde geliefert"

    deliveryForm.addDescription("Wurde die Position <b>" + props.orderPosition?.product.name + "</b> tatsächlich geliefert?")

    const today = new Date().toLocaleDateString('en-CA');
    const deliveryDate = deliveryForm.addInput('Lieferdatum', 'date', today);

    const saveDeliveryDate = deliveryForm.addButton('Lieferung bestätigen', "success");

    saveDeliveryDate.then(() => {
      if(!props.orderPosition) return
      const orderPos = props.orderPosition
      orderPos.deliveryDate = deliveryDate.value as string

      orderStore.updateOrderPosition(orderPos, props.order).then(() => {
       updatedPosition()
      })
      if(editForm) editForm.close();
      deliveryForm.close()
    })
}

function updatedPosition() {
  if(!props.orderPosition) return
  emits("positionUpdated", props.orderPosition);
}

function removingPosition() {
  if(!props.orderPosition) return
  emits("removingPosition", props.orderPosition);
}

</script>

<style lang="scss" scoped>
.positionCard {
    position: relative;
    padding: 10px;
    border-radius: 10px;
}

.positionAction {
    position: absolute;
    top: 10px;
    right: 10px;
}

.editBtn {
    cursor: pointer;
}

.productImage {
    width: 40px;
    margin-right: 4px;
}

img {
    width: 100%;
    border-radius: 10px;
}

.productInfo {
    width: 100%;
}

h5 {
    text-align: center;
}
</style>