import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import * as bootstrap from 'bootstrap';

bootstrap



const pinia = createPinia()

require("./assets/vendor/fontawesome/fontawesome.js")
require("./assets/vendor/fontawesome/regular.js")

createApp(App)
    .use(router)
    .use(pinia)
    .mount('#app')
