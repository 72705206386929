<template>
  <div class="card" v-if="customerCategory">
    <div class="h-100 basicCard cardShadow">
      <div>
        <h3>{{ customerCategory.name }}</h3>
      </div>
      <div>
        <img :src="getMainImage" />
      </div>
      <div class="actionBar mt-1">
        <button @click="isSelect" class="button">Wählen</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {PropType, defineProps, defineEmits, computed} from "vue";
import { useRouter } from "vue-router";
import { useEmployeeStore } from "../Employee/state";

import { useFileSystemStore } from "../System/FileSystem/state";
import { CustomerCategory } from "./CustomerCategory";
import { useCustomerCategoryStore } from "./store";

const employeeState = useEmployeeStore();
const router = useRouter();
const fileSystem = useFileSystemStore()

const emits = defineEmits(['isSelect']);


function isSelect() {
  if(!props.customerCategory) return
  emits("isSelect", props.customerCategory)
}

const getMainImage = computed(() => {
  if(!props.customerCategory) return
  if(props.customerCategory.images.length == 0) return
  return fileSystem.getImagePath(props.customerCategory.images[0])
})


const props = defineProps({
  customerCategory: Object as PropType<CustomerCategory>
})

</script>

<style lang="scss" scoped>
@import "./../../assets/style/variables.scss";
@import "./../../assets/style/customer-category-card.scss";

img {
  width: 100%;
  border-radius: 10px;
}

.button {
  background-color:#3EAB38;
  color: white;
  width: 10rem;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #3EAB38;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

.button:hover {
  transform: scale(1.05);
  background-color: white;
  color:#3EAB38;
}


h3 {
  margin-top: 1rem;
}

</style>