<template>
  <div class="mt-2 alert alert-danger" v-if="data.isActive">
    Achtung! Du hast noch offene Bestellungen, die seit dem {{ data.alertDeliverDate.format('LL') }} nicht geliefert wurden.
    <button @click="goToOrders" class="btn btn-outline-dark btn-sm">Jetzt anzeigen</button>
  </div>
</template>

<script lang="ts" setup>
import {useDeviceSettingStore} from "@/components/System/DeviceSetting/deviceSettingStore";
import {onMounted, reactive} from "vue";
import moment from "moment";
import {useOrderStore} from "@/components/Order/state";
import {useRouter} from "vue-router";

const deviceSettingStore = useDeviceSettingStore()
const orderStore = useOrderStore();
const router = useRouter()

const data = reactive({
  alertDeliverDate: moment(),
  isActive: false,
  totalProducts: 0
})

onMounted(() => {
  if(deviceSettingStore.getDeliveryDelayWarning === false) return;
  data.alertDeliverDate = moment().add(deviceSettingStore.getDeliveryDelayWarning *-1, 'days')
  orderStore.getCombinedOrders('delivery', data.alertDeliverDate.format('YYYY-MM-DD'), false).then((summary) => {
    let totalProducts = 0;
    for(const order of summary) {
      totalProducts += parseFloat(order.totalAmount.toString())
    }
    if(totalProducts > 0) {
      data.isActive = true
    }
    data.totalProducts = totalProducts;
  })
})

function goToOrders() {
  router.push({name: "orderList"})
}

</script>