<template>
    <div><h1>Farmprodukte</h1></div>
    <div>
        <button @click="addProduct" class="w-100 btn btn-success" v-if="employeeStore.isAllowed('Admin')"><i class="fa-regular fa-user-plus"></i> Produkt hinzufügen</button>
        <ProductFinder />
                
    </div>
    <div>
        <div class="row">
            <template v-for="product of productStore.foundProductItems" :key="product.id">
                <div class="col mt-2">
                    <ProductCard :product="product" @is-clicked="openProduct" />
                </div>
            </template>
            <div class="text-center mt-5" v-if="productStore.foundProductItems.length == 0 && loadingStore.backgroundLoading.requestCounter == 0">
               <p><em>Leider keine Produkte gefunden, füge doch weitere Produkte hinzu. :)</em></p> 
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Product } from "@/components/Product/Product";
import {useProductStore} from "@/components/Product/state"
import { useLoadingStore } from "@/components/System/Loading/state";
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import ProductCard from "@/components/Product/ProductCard.vue";
import { useEmployeeStore } from "@/components/Employee/state";
import ProductFinder from "@/components/Product/ProductFinder.vue";


const productStore = useProductStore();
const employeeStore = useEmployeeStore();
const loadingStore = useLoadingStore();
const router = useRouter();

onMounted(() => {
    productStore.getProducts()
})

function addProduct() {
    const product = new Product();
    productStore.openProductEditor(product)
}

function openProduct(product: Product) {
    productStore.setFocusProduct(product)
    router.push({
        name: "product",
        params: {
            productId: product.id.toString()
        }
    })
}
</script>